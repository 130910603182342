import { Button, Col, Empty, Modal, Row } from "antd";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import React, { useEffect, useRef, useState } from "react";

import { Category } from "../../models";
import { ManageCategoriesTableRow } from "./ManageCategoriesTableRow";
import { ManageCategoryDetails } from "./ManageCategoryDetails";
import { ResponsiveModal } from "../../components/ResponsiveModal";
import { ampli } from "../../ampli";

// import { ampli } from "../../ampli";

interface Props {
  categories: Category[];
}

export function ManageCategoriesTable({ categories }: Props) {
  const selectedCategory = useRef<Category | null>(null);
  const [showCategoryDetailsModal, setShowCategoryDetailsModal] =
    useState(false);

  async function handleCreateItem() {
    const newItem = await DataStore.save(new Category({}));
    selectedCategory.current = newItem;
    setShowCategoryDetailsModal(true);
    ampli.newCategoryCreated();
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Category, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Category.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Category, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div>
      {categories.length > 0 && (
        <Row>
          <Col flex="auto">
            {categories.map((eachCategory) => (
              <ManageCategoriesTableRow
                key={eachCategory.id}
                category={eachCategory}
                updateItemHandler={async (updatedProperties: {
                  [key: string]: any;
                }) => {
                  await handleUpdateItem(updatedProperties);
                }}
                onClick={async (category: Category) => {
                  selectedCategory.current = category;
                  setShowCategoryDetailsModal(true);
                }}
              />
            ))}
          </Col>
        </Row>
      )}

      {(!categories || categories.length === 0) && (
        <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Col flex="auto">
            <Empty description="">
              <Button type="primary" shape="round" onClick={handleCreateItem}>
                Create Your First Category
              </Button>
            </Empty>
          </Col>
        </Row>
      )}

      <br />

      {categories.length > 0 && (
        <Row
          align="middle"
          justify="center"
          className="tb-table-row"
          onClick={handleCreateItem}
        >
          <span style={{ margin: 0 }}>New Category</span>
        </Row>
      )}

      <ResponsiveModal
        title={"Category Details"}
        open={showCategoryDetailsModal}
        initialHelpContentPath={
          "/category-details/categoryDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowCategoryDetailsModal(false)}
        deleteItemHandler={async () => {
          await handleDeleteItem(selectedCategory.current!.id);
          setShowCategoryDetailsModal(false);
        }}
      >
        <ManageCategoryDetails
          category={selectedCategory.current!}
          onDone={() => setShowCategoryDetailsModal(false)}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteItem(itemId);
            setShowCategoryDetailsModal(false);
          }}
        />
      </ResponsiveModal>
    </div>
  );
}
