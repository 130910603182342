import { Button, Col, Row, Modal, Empty } from "antd";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import React, { useEffect, useState, useRef } from "react";

import { Hypothesis, HypothesisStatusEnum, Project } from "../../models";
import { IdeasTableRow } from "./IdeasTableRow";
import { IdeaDetails } from "./IdeaDetails";

import { ampli } from "../../ampli";
import { AddNewItemCard } from "../../components/AddNewItemCard";
import { ResponsiveModal } from "../../components/ResponsiveModal";

interface Props {
  project: Project;
}

export function IdeasTable({ project }: Props) {
  const [hypotheses, setHypotheses] = useState(Array<Hypothesis>);
  const selectedHypothesis = useRef<Hypothesis | null>(null);
  const [showHypothesisDetailsModal, setShowHypothesisDetailsModal] =
    useState(false);

  useEffect(() => {
    const loadData = async () => {
      const allHypotheses = await listHypotheses(project.id);
      setHypotheses(allHypotheses);
    };
    loadData();
  }, [project]);

  useEffect(() => {
    const subscription = DataStore.observe(Hypothesis).subscribe(async () => {
      const allHypotheses = await listHypotheses(project.id);
      setHypotheses(allHypotheses);
    });
    return () => subscription.unsubscribe();
  }, [project]);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const allHypotheses = await listHypotheses(project.id);
        setHypotheses(allHypotheses);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [project]);

  async function listHypotheses(projectId: string) {
    const hypotheses = (await DataStore.query(Hypothesis))
      .filter(
        (h) =>
          h.project?.id === projectId && h.status === HypothesisStatusEnum.IDEA
      )
      .sort((a, b) => b.createdAt?.localeCompare(a.createdAt || "") || 0);

    return hypotheses;
  }

  async function handleCreateItem() {
    const newItem = await DataStore.save(
      new Hypothesis({
        status: HypothesisStatusEnum.IDEA,
        project: project,
      })
    );
    selectedHypothesis.current = newItem;
    setShowHypothesisDetailsModal(true);
    // ampli.newHypothesisCreated();
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Hypothesis, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Hypothesis.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Hypothesis, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div>
      {(!hypotheses || hypotheses.length === 0) && (
        <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Col flex="auto">
            <Empty description="">
              <Button type="primary" shape="round" onClick={handleCreateItem}>
                Add Your First Idea
              </Button>
            </Empty>
          </Col>
        </Row>
      )}

      {hypotheses.length > 0 && (
        <Row>
          <AddNewItemCard onClick={handleCreateItem} />

          {hypotheses.map((eachHypothesis) => (
            <IdeasTableRow
              key={eachHypothesis.id}
              hypothesis={eachHypothesis}
              updateItemHandler={async (updatedProperties: {
                [key: string]: any;
              }) => {
                await handleUpdateItem(updatedProperties);
              }}
              onClick={async (hypothesis: Hypothesis) => {
                selectedHypothesis.current = hypothesis;
                setShowHypothesisDetailsModal(true);
              }}
            />
          ))}
        </Row>
      )}

      <br />

      <ResponsiveModal
        title={"Hypothesis Details"}
        open={showHypothesisDetailsModal}
        initialHelpContentPath={
          "/hypothesis-details/hypothesisDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowHypothesisDetailsModal(false)}
        deleteItemHandler={async () => {
          await handleDeleteItem(selectedHypothesis.current!.id);
          setShowHypothesisDetailsModal(false);
        }}
      >
        <IdeaDetails
          hypothesis={selectedHypothesis.current!}
          onDone={() => setShowHypothesisDetailsModal(false)}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteItem(itemId);
            setShowHypothesisDetailsModal(false);
          }}
        />
      </ResponsiveModal>
    </div>
  );
}
