import { Button, Col, Dropdown, Menu, Modal, Row } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

import { HelpModalSidebar } from "./HelpModalSidebar";

interface Props {
  title?: string;
  children: JSX.Element;
  open: boolean;
  stackingIndex?: number;
  initialHelpContentPath?: string;
  closeModalHandler?: Function;
  deleteItemHandler?: Function;
}

export function ResponsiveModal({
  title,
  children,
  open,
  stackingIndex,
  initialHelpContentPath,
  closeModalHandler,
  deleteItemHandler,
}: Props) {
  const [helpOpen, setHelpOpen] = useState(false);
  const [helpContentPath, setHelpContentPath] = useState(
    initialHelpContentPath
  );

  function moreMenu() {
    return (
      <Menu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={() => deleteItemHandler?.()}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Modal
      open={open}
      title={null}
      footer={null}
      closable={false}
      destroyOnClose={true}
      width={"calc((100vw - 40px))"}
      style={{
        top: 20 + 50 * (stackingIndex || 0),
        bottom: 50,
        marginBottom: 85,
        borderRadius: "25px",
        backgroundColor: "transparent",
      }}
      bodyStyle={{ padding: 0, backgroundColor: "transparent" }}
      onCancel={(e) => {
        setHelpOpen(false);
        closeModalHandler?.();
      }}
      onOk={() => {
        setHelpOpen(false);
      }}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{
          height: "50px",
          borderBottom: "1px solid #eeeeee",
        }}
      >
        <Col flex="auto">
          <Row
            align="middle"
            justify="space-between"
            style={{ width: "100%", paddingLeft: 24, paddingRight: 10 }}
          >
            <h4 style={{ margin: 0 }}>{title?.toUpperCase()}</h4>

            <Col>
              <Row align="middle" justify="end" wrap={false}>
                {deleteItemHandler && (
                  <Dropdown
                    overlay={moreMenu}
                    placement="bottomRight"
                    arrow
                    trigger={["click"]}
                  >
                    <Button
                      style={{ borderWidth: 0, height: "44px", width: "44px" }}
                      icon={<DeleteOutlined />}
                    />
                  </Dropdown>
                )}

                {setHelpOpen && (
                  <Button
                    style={{ borderWidth: 0, height: "44px", width: "44px" }}
                    icon={
                      helpOpen ? (
                        <QuestionCircleFilled />
                      ) : (
                        <QuestionCircleOutlined />
                      )
                    }
                    onClick={async (e) => {
                      if (setHelpOpen) setHelpOpen(!helpOpen);
                    }}
                  />
                )}

                <Button
                  style={{ borderWidth: 0, height: "44px", width: "44px" }}
                  icon={<CloseOutlined />}
                  onClick={async () => {
                    closeModalHandler?.();
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        justify="start"
        wrap={false}
        // style={{ height: "calc((100vh - 95px))", overflow: "auto" }}
      >
        <Col
          flex="1 1 auto"
          style={{
            paddingTop: 0,
            overflow: "auto",
          }}
        >
          {React.cloneElement(children, {
            setHelpContentPath,
            deleteItemHandler,
            closeModalHandler,
          })}
        </Col>

        <HelpModalSidebar helpOpen={helpOpen} contentPath={helpContentPath} />
      </Row>
    </Modal>
  );
}
