// import './styles.scss';
// import './RichTextEditor.module.css';

import { Button, Divider, Row } from "antd";
import React, { useCallback } from "react";
import {
  faBold,
  faHeading,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faMinus,
  faParagraph,
  faRedo,
  faStrikethrough,
  faUnderline,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import { Editor } from "@tiptap/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const inactiveIconColor = "#9c9c9c";
const activeIconColor = "#363636";

interface Props {
  editor: Editor | null;
}

export default function RichTextEditorMenuBar({ editor }: Props) {
  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <Row align="middle">
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <FontAwesomeIcon
          icon={faBold}
          color={editor.isActive("bold") ? activeIconColor : inactiveIconColor}
        />
      </Button>
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <FontAwesomeIcon
          icon={faItalic}
          color={
            editor.isActive("italic") ? activeIconColor : inactiveIconColor
          }
        />
      </Button>
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <FontAwesomeIcon
          icon={faUnderline}
          color={
            editor.isActive("underline") ? activeIconColor : inactiveIconColor
          }
        />
      </Button>

      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <FontAwesomeIcon
          icon={faStrikethrough}
          color={
            editor.isActive("strike") ? activeIconColor : inactiveIconColor
          }
        />
      </Button>

      <Divider type="vertical" />

      <Button
        type="text"
        onClick={() => editor.chain().focus().setParagraph().run()}
      >
        <FontAwesomeIcon
          icon={faParagraph}
          color={
            editor.isActive("paragraph") ? activeIconColor : inactiveIconColor
          }
        />
      </Button>
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      >
        <FontAwesomeIcon
          icon={faHeading}
          color={
            editor.isActive("heading", { level: 2 })
              ? activeIconColor
              : inactiveIconColor
          }
        />
        <span
          style={{
            color: editor.isActive("heading", { level: 2 })
              ? activeIconColor
              : inactiveIconColor,
            fontWeight: 700,
          }}
        >
          1
        </span>
      </Button>
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
      >
        <FontAwesomeIcon
          icon={faHeading}
          color={
            editor.isActive("heading", { level: 3 })
              ? activeIconColor
              : inactiveIconColor
          }
        />
        <span
          style={{
            color: editor.isActive("heading", { level: 3 })
              ? activeIconColor
              : inactiveIconColor,
            fontWeight: 700,
          }}
        >
          2
        </span>
      </Button>
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
      >
        <FontAwesomeIcon
          icon={faHeading}
          color={
            editor.isActive("heading", { level: 4 })
              ? activeIconColor
              : inactiveIconColor
          }
        />
        <span
          style={{
            color: editor.isActive("heading", { level: 4 })
              ? activeIconColor
              : inactiveIconColor,
            fontWeight: 700,
          }}
        >
          3
        </span>
      </Button>

      <Divider type="vertical" />

      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FontAwesomeIcon
          icon={faListUl}
          color={
            editor.isActive("bulletList") ? activeIconColor : inactiveIconColor
          }
        />
      </Button>
      <Button
        type="text"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <FontAwesomeIcon
          icon={faListOl}
          color={
            editor.isActive("orderedList") ? activeIconColor : inactiveIconColor
          }
        />
      </Button>

      <Button type="text" onClick={setLink}>
        <FontAwesomeIcon
          icon={faLink}
          color={editor.isActive("link") ? "blue" : inactiveIconColor}
        />
      </Button>

      <Button
        type="text"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <FontAwesomeIcon icon={faMinus} color={inactiveIconColor} />
      </Button>

      <Divider type="vertical" />

      <Button type="text" onClick={() => editor.chain().focus().undo().run()}>
        <FontAwesomeIcon icon={faUndo} color={inactiveIconColor} />
      </Button>
      <Button type="text" onClick={() => editor.chain().focus().redo().run()}>
        <FontAwesomeIcon icon={faRedo} color={inactiveIconColor} />
      </Button>
      <Divider style={{ paddingTop: 0, marginTop: 0, marginBottom: 16 }} />
    </Row>
  );
}
