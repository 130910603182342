import { Button, Col, Empty, Row } from "antd";
import { Canvas, CanvasBlock } from "../../models";
import React, { useRef } from "react";

import { AddNewItemCard } from "../../components/AddNewItemCard";
import { DataStore } from "@aws-amplify/datastore";
import { ManageCanvasesGridCell } from "./ManageCanvasesGridCell";
import { ampli } from "../../ampli";
import { useNavigate } from "react-router-dom";

interface Props {
  canvases: Canvas[];
  allCanvasBlocks: CanvasBlock[];
}

export function ManageCanvasesGrid({ canvases, allCanvasBlocks }: Props) {
  const selectedCanvas = useRef<Canvas | null>(null);
  const navigate = useNavigate();

  async function handleCreateItem() {
    const newItem = await DataStore.save(new Canvas({}));
    selectedCanvas.current = newItem;
    // ampli.newCanvasCreated();
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Canvas, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Canvas.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  return (
    <div>
      {(!canvases || canvases.length === 0) && (
        <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Col flex="auto">
            <Empty description="">
              <Button type="primary" shape="round" onClick={handleCreateItem}>
                Add Your First Canvas
              </Button>
            </Empty>
          </Col>
        </Row>
      )}

      {canvases.length > 0 && (
        <Row>
          <AddNewItemCard onClick={handleCreateItem} />

          {canvases.map((eachCanvas) => (
            <ManageCanvasesGridCell
              key={eachCanvas.id}
              canvas={eachCanvas}
              updateItemHandler={async (updatedProperties: {
                [key: string]: any;
              }) => {
                await handleUpdateItem(updatedProperties);
              }}
              onClick={async (canvas: Canvas) => {
                navigate(`/manage/canvases/${canvas.id}`);
                // selectedCanvas.current = canvas;
                // setShowCanvasDetailsModal(true);
              }}
            />
          ))}
        </Row>
      )}

      <br />
    </div>
  );
}
