import { Button, Col, Divider, Row } from "antd";
import React, { useRef } from "react";

import { Card } from "../../components/Card";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsRichTextEditorRow } from "../../components/DetailsRichTextEditorRow";
import { DetailsTitleHeader } from "../../components/DetailsTitleHeader";
import { Learning } from "../../models";

interface Props {
  learning: Learning;
  deleteItemHandler: Function;
  onDone: Function;
}

export function LearningDetails({
  learning,
  deleteItemHandler,
  onDone,
}: Props) {
  const theLearning = useRef<Learning | null>(learning);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Learning, learning.id);

    if (original) {
      theLearning.current = await DataStore.save(
        Learning.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  return (
    <Card>
      <DetailsTitleHeader
        title={theLearning.current?.name || ""}
        placeholder={"What did you learn?"}
        updateTitleHandler={async (content: string) => {
          await handleUpdateItem({
            name: content,
          });
        }}
      />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Content */}
        <DetailsRichTextEditorRow
          sectionTitle=""
          placeholder=""
          value={theLearning.current?.content || ""}
          description={""}
          updateItemHandler={async (content: string) => {
            await handleUpdateItem({
              content: content,
            });
          }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
