import * as React from "react";

import { Col, Input, Row } from "antd";

import { useDebouncedCallback } from "use-debounce";

const { TextArea } = Input;

interface Props {
  placeholder: string;
  defaultValue: string;
  description: string;
  style?: React.CSSProperties | undefined;
  updateItemHandler: Function;
}

export function DetailsTitleInput({
  placeholder,
  defaultValue,
  description,
  updateItemHandler,
  style,
  ...restProps
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateItemHandler(updatedProperties);
    },
    1000
  );

  return (
    <div style={style} {...restProps}>
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: 0,
          }}
        >
          <Row className="tb-title-input">
            <TextArea
              autoSize={true}
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: "2em",
                fontWeight: 700,
              }}
              placeholder={placeholder}
              defaultValue={defaultValue}
              onChange={(e) => debouncedPropertiesUpdate(e.target.value)}
              onBlur={async (e) => {
                await updateItemHandler(e.target.value);
              }}
            />
          </Row>
          {description && (
            <Row>
              <p style={{ paddingLeft: 8, marginTop: 8, fontSize: 14 }}>
                {description}
              </p>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
}
