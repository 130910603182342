import { Button, Col, Divider, Row } from "antd";
import React, { useRef } from "react";

import { Card } from "./Card";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsRichTextEditorRow } from "./DetailsRichTextEditorRow";
import { DetailsTitleHeader } from "./DetailsTitleHeader";
import { Project } from "../models";

export interface ProjectDetailsProps {
  project: Project;
  deleteItemHandler: Function;
  onDone: Function;
}

export function ProjectDetails({
  project,
  deleteItemHandler,
  onDone,
}: ProjectDetailsProps) {
  const theProject = useRef<Project | null>(project);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Project, project.id);
    if (original) {
      theProject.current = await DataStore.save(
        Project.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  return (
    <Card>
      <DetailsTitleHeader
        title={theProject.current?.name || ""}
        placeholder={"What's your project's name?"}
        updateTitleHandler={async (content: string) => {
          await handleUpdateItem({
            name: content,
          });
        }}
      />

      <Col style={{ width: "100%" }}>
        {/* Description */}
        <DetailsRichTextEditorRow
          sectionTitle="Description"
          placeholder=""
          value={theProject.current?.description || ""}
          description={""}
          updateItemHandler={async (content: string) => {
            await handleUpdateItem({
              description: content,
            });
          }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
