import { Button, Col, Divider, Row } from "antd";
import {
  Experiment,
  ExperimentStatusEnum,
  HypothesisDecisionEnum,
} from "../../models";
import React, { useRef } from "react";

export interface Props {
  experiment: Experiment;
  setModalVisible: Function;
  updateExperimentDecisions: Function;
  updateExperimentStatus: Function;
}

export function ExperimentDetailsFooterBar({
  experiment,
  setModalVisible,
  updateExperimentDecisions,
  updateExperimentStatus,
}: Props) {
  return (
    <>
      <Divider />

      <Row align="middle" justify="end">
        <Col>
          <Row>
            {experiment.status === ExperimentStatusEnum.WAITING && (
              <Button
                type="primary"
                shape="round"
                onClick={async () => {
                  await updateExperimentStatus(ExperimentStatusEnum.RUNNING);
                }}
              >
                Start Experiment
              </Button>
            )}

            {experiment.status === ExperimentStatusEnum.RUNNING && (
              <>
                <Button
                  type="primary"
                  shape="round"
                  onClick={async () => {
                    await updateExperimentStatus(
                      ExperimentStatusEnum.VALIDATED
                    );
                  }}
                >
                  Validate Experiment
                </Button>

                <Button
                  type="primary"
                  shape="round"
                  danger
                  style={{ marginLeft: 20 }}
                  onClick={async () => {
                    await updateExperimentStatus(
                      ExperimentStatusEnum.INVALIDATED
                    );
                  }}
                >
                  Invalidate Experiment
                </Button>

                <Button
                  type="default"
                  shape="round"
                  style={{ marginLeft: 20 }}
                  onClick={async () => {
                    await updateExperimentStatus(
                      ExperimentStatusEnum.INCONCLUSIVE
                    );
                  }}
                >
                  Inconclusive Experiment
                </Button>
              </>
            )}

            {(experiment.status === ExperimentStatusEnum.VALIDATED ||
              experiment.status === ExperimentStatusEnum.INVALIDATED ||
              experiment.status === ExperimentStatusEnum.INCONCLUSIVE) &&
              !experiment.decision && (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      backgroundColor: "#B64E4E",
                      borderColor: "transparent",
                    }}
                    onClick={async () => {
                      await updateExperimentDecisions(
                        HypothesisDecisionEnum.ABORT
                      );
                    }}
                  >
                    Abort
                  </Button>

                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      marginLeft: 20,
                      backgroundColor: "#DF972B",
                      borderColor: "transparent",
                    }}
                    onClick={async () => {
                      await updateExperimentDecisions(
                        HypothesisDecisionEnum.PIVOT
                      );
                    }}
                  >
                    Pivot
                  </Button>

                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      marginLeft: 20,
                      backgroundColor: "#4EB665",
                      borderColor: "transparent",
                    }}
                    onClick={async () => {
                      await updateExperimentDecisions(
                        HypothesisDecisionEnum.PERSEVERE
                      );
                    }}
                  >
                    Persevere
                  </Button>
                </>
              )}

            {(experiment.status === ExperimentStatusEnum.VALIDATED ||
              experiment.status === ExperimentStatusEnum.INVALIDATED ||
              experiment.status === ExperimentStatusEnum.INCONCLUSIVE) &&
              experiment.decision && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setModalVisible(false);
                  }}
                >
                  Close
                </Button>
              )}
          </Row>
        </Col>
      </Row>
    </>
  );
}
