import { AppstoreOutlined, DesktopOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, Row } from "antd";
import {
  CanvasBlock,
  Category,
  Hypothesis,
  HypothesisStatusEnum,
  Objective,
  Project,
} from "../../models";
import React, { useEffect, useRef, useState } from "react";

import { Canvas } from "../../models";
import { CategoryPicker } from "../../components/CategoryPicker";
import { DataStore } from "@aws-amplify/datastore";
import { HypothesesCanvas } from "./HypothesesCanvas";
import { HypothesesGrid } from "./HypothesesGrid";
import { HypothesisDetails } from "./HypothesisDetails";
import { ResponsiveModal } from "../../components/ResponsiveModal";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  project: Project;
  hypotheses: Hypothesis[];
  objectives: Objective[];
  categories: Category[];
  canvases: Canvas[];
  allCanvasBlocks: CanvasBlock[];
}

enum DisplayLayout {
  GRID = "GRID",
  LEAN_CANVAS = "LEAN_CANVAS",
}

export function Hypotheses({
  setPageTitle,
  project,
  hypotheses,
  objectives,
  categories,
  canvases,
  allCanvasBlocks,
}: Props) {
  const [displayLayout, setDisplayLayout] = useState<DisplayLayout>(
    DisplayLayout.LEAN_CANVAS
  );

  const [selectedCanvas, setSelectedCanvas] = useState<Canvas | null>(
    canvases[0] || null
  );

  const [showCategoryPickerModal, setShowCategoryPickerModal] = useState(false);

  const selectedHypothesis = useRef<Hypothesis | null>(null);
  const [showHypothesisDetailsModal, setShowHypothesisDetailsModal] =
    useState(false);

  useEffect(() => {
    setPageTitle("Hypotheses");
    ampli.viewedHypothesesScreen();
  }, []);

  useEffect(() => {
    setSelectedCanvas(canvases[0] || null);
  }, [canvases]);

  async function handleOpenItem(hypothesis: Hypothesis) {
    selectedHypothesis.current = hypothesis;
    setShowHypothesisDetailsModal(true);
  }

  async function handleCreateItem(category: Category) {
    const newItem = await DataStore.save(
      new Hypothesis({
        status: HypothesisStatusEnum.IDEA,
        project: project,
        category: category,
      })
    );
    selectedHypothesis.current = newItem;
    setShowHypothesisDetailsModal(true);
    ampli.newHypothesisCreated();
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Hypothesis, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Hypothesis.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Hypothesis, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  const canvasesMenu = (
    <Menu
      items={canvases?.map((c) => ({ label: c.name || "", key: c.id }))}
      onClick={({ key }) => {
        const canvas = canvases.find(({ id }) => id === key);
        if (canvas) {
          setSelectedCanvas(canvas);
          setDisplayLayout(DisplayLayout.LEAN_CANVAS);
        }
      }}
    />
  );

  return (
    <>
      <Row align="middle" justify="space-between" gutter={16}>
        <Col>
          <h2
            style={{
              paddingLeft: displayLayout === DisplayLayout.GRID ? 20 : 0,
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            {displayLayout === DisplayLayout.GRID
              ? "Grid"
              : selectedCanvas?.name || "Canvas"}
          </h2>
        </Col>
        <Col>
          <Row align="middle" justify="end" gutter={16}>
            {canvases.length > 0 && (
              <Col>
                <Dropdown overlay={canvasesMenu} trigger={["click"]}>
                  <Button
                    style={{ borderWidth: 0, height: "54px", width: "54px" }}
                    icon={<DesktopOutlined />}
                    onClick={async (e) => {
                      e.preventDefault();
                    }}
                    data-intercom-target="hypotheses-canvas-dropdown"
                  />
                </Dropdown>
              </Col>
            )}
            <Col>
              <Button
                style={{ borderWidth: 0, height: "54px", width: "54px" }}
                icon={<AppstoreOutlined />}
                onClick={async () => {
                  setDisplayLayout(DisplayLayout.GRID);
                }}
                data-intercom-target="hypotheses-grid-button"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col flex="auto">
          {displayLayout === DisplayLayout.GRID && (
            <HypothesesGrid
              project={project}
              hypotheses={hypotheses}
              objectives={objectives}
              categories={categories}
              openHypothesisHandler={handleOpenItem}
              addNewHypothesisHandler={handleCreateItem}
              updateHypothesisHandler={handleUpdateItem}
              deleteHypothesisHandler={handleDeleteItem}
            />
          )}

          {displayLayout === DisplayLayout.LEAN_CANVAS && selectedCanvas && (
            <HypothesesCanvas
              key={selectedCanvas?.id}
              canvasId={selectedCanvas?.id}
              project={project}
              hypotheses={hypotheses}
              objectives={objectives}
              categories={categories}
              openHypothesisHandler={handleOpenItem}
              addNewHypothesisHandler={handleCreateItem}
              updateHypothesisHandler={handleUpdateItem}
              deleteHypothesisHandler={handleDeleteItem}
            />
          )}
        </Col>
      </Row>

      <ResponsiveModal
        title={"Category Picker"}
        open={showCategoryPickerModal}
        initialHelpContentPath={
          "/hypothesis-details/hypothesisDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowCategoryPickerModal(false)}
      >
        <CategoryPicker
          categories={categories}
          selectedCategoryHandler={(category: Category) => {
            setShowCategoryPickerModal(false);
            handleCreateItem(category);
          }}
          setModalVisible={setShowCategoryPickerModal}
        />
      </ResponsiveModal>

      <ResponsiveModal
        title={"Hypothesis Details"}
        open={showHypothesisDetailsModal}
        initialHelpContentPath={
          "/hypothesis-details/hypothesisDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowHypothesisDetailsModal(false)}
        deleteItemHandler={async () => {
          await handleDeleteItem(selectedHypothesis.current!.id);
          setShowHypothesisDetailsModal(false);
        }}
      >
        <HypothesisDetails
          project={project}
          hypothesis={selectedHypothesis.current!}
          objectives={objectives}
          categories={categories}
          onDone={() => setShowHypothesisDetailsModal(false)}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteItem(itemId);
            setShowHypothesisDetailsModal(false);
          }}
        />
      </ResponsiveModal>
    </>
  );
}
