import { API, graphqlOperation } from "aws-amplify";
import { Hub, Logger } from "aws-amplify";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SubscriptionPlanEnum, SubscriptionStatusEnum, User } from "../models";

import { Auth } from "aws-amplify";
import { CheckUserForActivePlanQuery } from "../API";
import { CognitoUser } from "amazon-cognito-identity-js";
import { DataStore } from "@aws-amplify/datastore";
import { ampli } from "../ampli";
import { checkUserForActivePlan } from "../graphql/queries";

const logger = new Logger("My-Logger");
interface Props {
  children: React.ReactNode;
}

export interface AuthExportProps {
  cognitoUser: any;
  userId: string | undefined;
  userProfile: User | undefined;
  isAdmin: boolean;
  isAuthenticating: any;
  userHasAtLeastOneActiveOrganization: any;
  isOnboarding: any;
  subscriptionStatus: SubscriptionStatusEnum;
  subscriptionPlan: SubscriptionPlanEnum;
  daysLeftInTrial: number;
  setIsOnboarding: any;
  signIn: any;
  signUp: any;
  verifyOtpCode: any;
  signOut: any;
}

const AuthContext = createContext<AuthExportProps | null>(null);

export function ProvideAuth({ children }: Props) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userProfile, setUserProfile] = useState<User | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [
    userHasAtLeastOneActiveOrganization,
    setUserHasAtLeastOneActiveOrganization,
  ] = useState(false);

  const [subscriptionStatus, setSubscriptionStatus] = useState(
    SubscriptionStatusEnum.NOT_ACTIVE
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    SubscriptionPlanEnum.FREE
  );
  const [daysLeftInTrial, setDaysLeftInTrialn] = useState(0);

  //   // Hub.listen("auth", signInListener);
  // }, []);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        setCognitoUser(cognitoUser);
        if (cognitoUser) {
          ampli.identify(cognitoUser.attributes.sub, {
            email: cognitoUser.attributes.email,
          });
        }
      } catch (error) {
        setCognitoUser(null);
      }

      try {
        await currentSession();
      } catch (error) {
        if (error !== "No current user") {
          alert(error);
        }
      }

      await checkUserHasAtLeastOneActiveOrganization();

      setIsAuthenticating(false);
    };
    authenticateUser();
  }, []);

  useEffect(() => {
    checkIfUserIsAdmin(cognitoUser);
    const id = cognitoUser?.getSignInUserSession()?.getIdToken().payload.sub;
    setUserId(id);
    const loadData = async () => {
      await loadUserProfile(id);
    };
    loadData();
  }, [cognitoUser]);

  useEffect(() => {
    const userProfileSubscription = DataStore.observe(User).subscribe(
      async () => {
        await loadUserProfile(userId);
      }
    );

    return () => {
      userProfileSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        await loadUserProfile(userId);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  async function loadUserProfile(userId: string | undefined) {
    if (!userId) {
      return undefined;
    }

    const user = await DataStore.query(User, userId);
    if (user) {
      setUserProfile(user);
    }

    return user;
  }

  function currentSession() {
    return Auth.currentSession();
  }

  async function checkUserHasAtLeastOneActiveOrganization() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(checkUserForActivePlan)
      )) as { data: CheckUserForActivePlanQuery };

      const {
        hasAccess,
        subscriptionStatus,
        subscriptionPlan,
        daysLeftInTrial,
      } = JSON.parse(data?.checkUserForActivePlan || "");

      setSubscriptionStatus(subscriptionStatus);
      setSubscriptionPlan(subscriptionPlan);
      setDaysLeftInTrialn(daysLeftInTrial);

      setUserHasAtLeastOneActiveOrganization(hasAccess);
      return hasAccess;
    } catch (error) {
      console.error("Error while checking for active organization: ", error);
      return false;
    }
  }

  function checkIfUserIsAdmin(userObject: CognitoUser | null) {
    if (!userObject) {
      setIsAdmin(false);
      return false;
    }

    let cognitoGroups = userObject.getSignInUserSession()?.getAccessToken()
      .payload["cognito:groups"];

    let isAdmin = cognitoGroups?.includes("admins");

    setIsAdmin(isAdmin);
    return isAdmin;
  }

  async function signIn(email: string) {
    const lowercaseEmail = email.toLowerCase();
    setIsOnboarding(true);
    try {
      const signInResult = await Auth.signIn(lowercaseEmail);
      setCognitoUser(signInResult);
    } catch (error: any) {
      if (error.code === "UserNotFoundException") {
        await signUp(lowercaseEmail);
      } else if (error.code === "UsernameExistsException") {
        await signIn(email);
      } else {
        console.error("Error signing user in:", error);
      }
    }
  }

  async function signUp(email: string) {
    const lowercaseEmail = email.toLowerCase();
    const params = {
      username: lowercaseEmail,
      password: getRandomString(30),
    };

    try {
      await Auth.signUp(params);
      await signIn(lowercaseEmail);
    } catch (error) {
      console.error("Error signing user up:", error);
    }
  }

  async function verifyOtpCode(code: string) {
    try {
      const result = await Auth.sendCustomChallengeAnswer(cognitoUser, code);
      if (result.signInUserSession) {
        ampli.identify(result.attributes.sub, {
          email: result.attributes.email,
        });
        setCognitoUser(result);

        try {
          await currentSession();
        } catch (error) {
          if (error !== "No current user") {
            alert(error);
          }
        }

        await checkUserHasAtLeastOneActiveOrganization();
      } else {
        throw "Incorrect OTP code";
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      throw error;
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      await DataStore.clear();
      setCognitoUser(null);
      Intercom("shutdown");
    } catch (error) {
      logger.error("Error signing user out:", error);
    }
  }

  // async function signInListener(data: any) {
  //   console.log("data.payload.event", data.payload.event);
  //   switch (data.payload.event) {
  //     case "signIn":
  //       logger.info("user signed in");
  //       break;
  //     case "signUp":
  //       logger.info("user signed up");
  //       break;
  //     case "signOut":
  //       logger.info("user signed out");
  //       await DataStore.clear();
  //       setUser(null);
  //       break;
  //     case "signIn_failure":
  //       logger.error("user sign in failed");
  //       await DataStore.clear();
  //       setUser(null);
  //       break;
  //     case "tokenRefresh":
  //       logger.info("token refresh succeeded");
  //       break;
  //     case "tokenRefresh_failure":
  //       logger.error("token refresh failed");
  //       break;
  //     case "configured":
  //       logger.info("the Auth module is configured");
  //       break;
  //     default:
  //       logger.info("unknown auth event");
  //       break;
  //   }
  // }

  return {
    cognitoUser,
    userId,
    userProfile,
    isAdmin,
    isAuthenticating,
    userHasAtLeastOneActiveOrganization,
    isOnboarding,
    subscriptionStatus,
    subscriptionPlan,
    daysLeftInTrial,
    setIsOnboarding,
    signIn,
    signUp,
    verifyOtpCode,
    signOut,
  };
}

function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}

function intToHex(number: number) {
  return number.toString(16).padStart(2, "0");
}
