import { Category, Experiment, Project } from "../../models";
import { Col, Row } from "antd";
import React, { useEffect } from "react";

import { ProjectCard } from "../../components/ProjectCard";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  project: Project | null;
  categories: Category[];
  experiments: Experiment[];
}

export function Dashboard({
  setPageTitle,
  project,
  categories,
  experiments,
}: Props) {
  useEffect(() => {
    setPageTitle("Dashboard");
    ampli.viewedDashboardScreen();
  }, []);

  return (
    <div>
      <Row justify="center">
        <Col flex="auto" style={{ maxWidth: 768 }}>
          {project && (
            <ProjectCard
              project={project}
              categories={categories}
              experiments={experiments}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
