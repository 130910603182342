import { Col, Row } from "antd";

import React from "react";

export default function Loading() {
  return (
    <div>
      <Row justify="center">
        <Col>
          <Row
            justify="center"
            style={{ paddingTop: "100px", paddingBottom: "80px" }}
          >
            <Col>
              <h1>Loading...</h1>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
