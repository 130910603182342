import { Col, Modal, Row } from "antd";
import {
  Experiment,
  ExperimentStatusEnum,
  Hypothesis,
  HypothesisStatusEnum,
  Project,
} from "../../models";
import React, { useEffect, useRef, useState } from "react";

import { DataStore } from "@aws-amplify/datastore";
import { ExperimentDetails } from "../ExperimentDetails/ExperimentDetails";
import { ExperimentsTableRow } from "./ExperimentsTableRow";
import { ResponsiveModal } from "../../components/ResponsiveModal";
import { ampli } from "../../ampli";

interface Props {
  project: Project;
  experiments: Experiment[];
}

export function ExperimentsTable({ project, experiments }: Props) {
  const selectedExperiment = useRef<Experiment | null>(null);
  const [showExperimentDetailsModal, setShowExperimentDetailsModal] =
    useState(false);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Experiment, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Experiment.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Experiment, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  async function createParentHypothesisIfNeeded(experiment: Experiment) {
    const original = await DataStore.query(Experiment, experiment.id);
    if (!original) {
      return null;
    }

    if (!original.parentHypothesis?.id) {
      const hypothesis = await DataStore.save(
        new Hypothesis({
          name: original.name || "",
          status: HypothesisStatusEnum.TESTING,
          project: project,
        })
      );
      return await DataStore.save(
        Experiment.copyOf(original, (updated) => {
          updated.parentHypothesis = hypothesis;
        })
      );
    }

    return original;
  }

  return (
    <div>
      {experiments.length > 0 && (
        <Row>
          <Col flex="auto">
            {experiments.map((eachExperiment) => (
              <ExperimentsTableRow
                key={eachExperiment.id}
                experiment={eachExperiment}
                updateItemHandler={async (updatedProperties: {
                  [key: string]: any;
                }) => {
                  await handleUpdateItem(updatedProperties);
                }}
                onClick={async (experiment: Experiment) => {
                  const updatedExperiment =
                    await createParentHypothesisIfNeeded(experiment);
                  selectedExperiment.current = updatedExperiment;
                  setShowExperimentDetailsModal(true);
                }}
              />
            ))}
          </Col>
        </Row>
      )}

      <ResponsiveModal
        title={"Experiment Details"}
        open={showExperimentDetailsModal}
        initialHelpContentPath={
          "/experiment-details/experimentDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowExperimentDetailsModal(false)}
        deleteItemHandler={async (itemId: string) => {
          await handleDeleteItem(selectedExperiment.current!.id);
          setShowExperimentDetailsModal(false);
        }}
      >
        <ExperimentDetails
          experiment={selectedExperiment.current!}
          setModalVisible={setShowExperimentDetailsModal}
        />
      </ResponsiveModal>
    </div>
  );
}
