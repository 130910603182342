import { Col, Row } from "antd";
import React, { useEffect } from "react";

import { PricingCard } from "./PricingCard";
import { SubscriptionPlanEnum } from "../../models";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle?: Function;
  currentPlan: SubscriptionPlanEnum;
  organizationId: string;
}

export default function Pricing({
  setPageTitle,
  currentPlan,
  organizationId,
}: Props) {
  useEffect(() => {
    setPageTitle?.("Pricing");
    ampli.viewedPricingScreen();
  }, []);

  return (
    <div>
      <Row justify="center">
        <Col>
          <Row
            justify="center"
            style={{ paddingTop: "60px", paddingBottom: "40px" }}
          >
            <h1 style={{ fontSize: "36px", textAlign: "center" }}>
              Run unlimited experiments. Learn and grow faster.
            </h1>
          </Row>
          <Row gutter={[32, 32]} justify="center">
            <Col>
              <PricingCard
                organizationId={organizationId}
                planName="Free"
                planType="free"
                localizedPrice="$0"
                period="free"
                benefits={[
                  "1 User",
                  "1 Concurrent Experiment",
                  "1 Project",
                  "1 Collection",
                  "Customized Experiment Types",
                ]}
                buttonEnabled={currentPlan !== SubscriptionPlanEnum.FREE}
                buttonTitle={
                  currentPlan === SubscriptionPlanEnum.FREE
                    ? "Your Current Plan"
                    : "Pick The Free Plan"
                }
              />
            </Col>
            <Col>
              <PricingCard
                organizationId={organizationId}
                planName="Monthly"
                planType="monthly"
                localizedPrice="$29"
                period="month"
                benefits={[
                  "Team Collaboration",
                  "Unlimited Experiments",
                  "Unlimited Projects",
                  "Unlimited Collections",
                  "Customized Experiment Types",
                ]}
                buttonEnabled={currentPlan !== SubscriptionPlanEnum.MONTHLY}
                buttonTitle={
                  currentPlan === SubscriptionPlanEnum.MONTHLY
                    ? "Your Current Plan"
                    : "Upgrade Now"
                }
              />
            </Col>
            <Col>
              <PricingCard
                organizationId={organizationId}
                planName="Yearly"
                planType="yearly"
                localizedPrice="$228"
                period="year"
                benefits={[
                  "34% Off (4 Months Free)",
                  "Team Collaboration",
                  "Unlimited Experiments",
                  "Unlimited Projects",
                  "Unlimited Collections",
                  "Customized Experiment Types",
                ]}
                buttonEnabled={currentPlan !== SubscriptionPlanEnum.ANNUAL}
                buttonTitle={
                  currentPlan === SubscriptionPlanEnum.ANNUAL
                    ? "Your Current Plan"
                    : "Upgrade Now"
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
