import { Col, Modal, Row } from "antd";
import { Collection, Project } from "../../models";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import React, { useEffect, useRef, useState } from "react";

import { ManageProjectsCollectionDetails } from "./ManageProjectsCollectionDetails";
import ManageProjectsCollectionTableRow from "./ManageProjectsCollectionTableRow";
import ManageProjectsProjectTableRow from "./ManageProjectsProjectTableRow";
import { ProjectDetails } from "../../components/ProjectDetails";
import { ResponsiveModal } from "../../components/ResponsiveModal";

interface Props {
  collections: Collection[];
  projectsByCollection: Map<string, Array<Project>>;
}

export default function ManageProjectsTable({
  collections,
  projectsByCollection,
}: Props) {
  // const [collections, setCollections] = useState(Array<Collection>);
  const selectedCollection = useRef<Collection | null>(null);
  const [showCollectionDetailsModal, setShowCollectionDetailsModal] =
    useState(false);

  // const [projectsByCollection, setProjectsByCollection] = useState<
  //   Map<string, Array<Project>>
  // >(new Map());
  const selectedProject = useRef<Project | null>(null);
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);

  // useEffect(() => {
  //   const loadData = async () => {
  //     const allCollections = await listCollections();
  //     setCollections(allCollections);

  //     const allProjects = await listProjects();
  //     setProjectsByCollection(allProjects);
  //   };
  //   loadData();
  // }, []);

  // useEffect(() => {
  //   const subscription = DataStore.observe(Collection).subscribe(async () => {
  //     const allCollections = await listCollections();
  //     setCollections(allCollections);
  //   });
  //   return () => subscription.unsubscribe();
  // }, []);

  // useEffect(() => {
  //   const subscription = DataStore.observe(Project).subscribe(async () => {
  //     const allCollections = await listProjects();
  //     setProjectsByCollection(allCollections);
  //   });
  //   return () => subscription.unsubscribe();
  // }, []);

  // useEffect(() => {
  //   const handleConnectionChange = async () => {
  //     const condition = navigator.onLine ? "online" : "offline";
  //     if (condition === "online") {
  //       const allCollections = await listCollections();
  //       setCollections(allCollections);
  //     }
  //   };

  //   window.addEventListener("online", handleConnectionChange);
  //   window.addEventListener("offline", handleConnectionChange);

  //   return () => {
  //     window.removeEventListener("online", handleConnectionChange);
  //     window.removeEventListener("offline", handleConnectionChange);
  //   };
  // }, []);

  // async function listCollections() {
  //   const collections = await DataStore.query(Collection, Predicates.ALL, {
  //     sort: (s) => s.name(SortDirection.ASCENDING),
  //   });

  //   return collections;
  // }

  async function handleCreateCollection() {
    const newItem = await DataStore.save(new Collection({}));
    selectedCollection.current = newItem;
    setShowCollectionDetailsModal(true);
  }

  async function handleUpdateCollection(updatedProperties: {
    [key: string]: any;
  }) {
    const original = await DataStore.query(Collection, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Collection.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  // async function listProjects() {
  //   const projects = await DataStore.query(Project, Predicates.ALL, {
  //     sort: (s) => s.name(SortDirection.ASCENDING),
  //   });

  //   let projectsByCollection = new Map<string, Array<Project>>();
  //   for (const project of projects) {
  //     if (!project.collection) {
  //       continue;
  //     }

  //     if (!projectsByCollection.has(project.collection.id)) {
  //       projectsByCollection.set(project.collection.id, []);
  //     }
  //     projectsByCollection.get(project.collection.id)?.push(project);
  //   }
  //   return projectsByCollection;
  // }

  async function handleCreateProject(forCollection: Collection) {
    const newItem = await DataStore.save(
      new Project({
        collection: forCollection,
      })
    );

    selectedProject.current = newItem;
    setShowProjectDetailsModal(true);
  }

  async function handleUpdateProject(updatedProperties: {
    [key: string]: any;
  }) {
    const original = await DataStore.query(Project, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Project.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteCollection(id: string) {
    const toDelete = await DataStore.query(Collection, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  async function handleDeleteProject(id: string) {
    const toDelete = await DataStore.query(Project, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div>
      <Row justify="center">
        <Col style={{ width: "100%", maxWidth: "768px" }}>
          {collections.map((eachCollection) => (
            <div style={{ marginTop: "30px" }}>
              <ManageProjectsCollectionTableRow
                key={eachCollection.id}
                collection={eachCollection}
                updateItemHandler={async (updatedProperties: {
                  [key: string]: any;
                }) => {
                  await handleUpdateCollection(updatedProperties);
                }}
                deleteItemHandler={async (itemId: string) => {
                  await handleDeleteCollection(itemId);
                }}
                onClick={async (collection: Collection) => {
                  selectedCollection.current = collection;
                  setShowCollectionDetailsModal(true);
                }}
                dragListeners={undefined}
                dragAttributes={undefined}
              />

              {projectsByCollection
                .get(eachCollection.id)
                ?.map((eachProject) => (
                  <ManageProjectsProjectTableRow
                    key={eachProject.id}
                    project={eachProject!}
                    collections={collections}
                    updateItemHandler={async (updatedProperties: {
                      [key: string]: any;
                    }) => {
                      await handleUpdateProject(updatedProperties);
                    }}
                    deleteItemHandler={async (itemId: string) => {
                      await handleDeleteProject(itemId);
                    }}
                    onClick={async (project: Project) => {
                      selectedProject.current = project;
                      setShowProjectDetailsModal(true);
                    }}
                    dragListeners={undefined}
                    dragAttributes={undefined}
                  />
                ))}

              <Row
                key={`${eachCollection.id}-new-item`}
                align="middle"
                justify="center"
                className="tb-table-row"
                style={{ marginTop: "10px" }}
                onClick={async () => {
                  await handleCreateProject(eachCollection);
                }}
              >
                <span style={{ margin: 0 }}>New Project</span>
              </Row>
            </div>
          ))}

          <Row
            align="middle"
            justify="center"
            className="tb-table-row"
            style={{ marginTop: "30px" }}
            onClick={handleCreateCollection}
          >
            <span style={{ margin: 0 }}>New Collection</span>
          </Row>
        </Col>
      </Row>

      <ResponsiveModal
        title={"Collection Details"}
        open={showCollectionDetailsModal}
        initialHelpContentPath={
          "/collection-details/collectionDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowCollectionDetailsModal(false)}
        deleteItemHandler={async () => {
          await handleDeleteCollection(selectedCollection.current!.id);
          setShowCollectionDetailsModal(false);
        }}
      >
        <ManageProjectsCollectionDetails
          collection={selectedCollection.current!}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteCollection(itemId);
            setShowCollectionDetailsModal(false);
          }}
          onDone={() => setShowCollectionDetailsModal(false)}
        />
      </ResponsiveModal>

      <ResponsiveModal
        title={"Project Details"}
        open={showProjectDetailsModal}
        initialHelpContentPath={"/project-details/projectDetailsScreenHelp.md"}
        closeModalHandler={() => setShowProjectDetailsModal(false)}
        deleteItemHandler={async () => {
          await handleDeleteCollection(selectedCollection.current!.id);
          setShowProjectDetailsModal(false);
        }}
      >
        <ProjectDetails
          project={selectedProject.current!}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteCollection(itemId);
            setShowProjectDetailsModal(false);
          }}
          onDone={() => setShowProjectDetailsModal(false)}
        />
      </ResponsiveModal>
    </div>
  );
}
