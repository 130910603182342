import { Canvas, CanvasBlock, Category, Hypothesis, Project } from "../models";
import { GridStack, GridStackNode } from "gridstack";
import React, { MouseEventHandler, createRef, useEffect, useRef } from "react";

import { CanvasEditableBlockCell } from "./CanvasEditableBlockCell";
import { CanvasStaticBlockCell } from "./CanvasStaticBlockCell";
import { DataStore } from "@aws-amplify/datastore";
import { HypothesisDetails } from "../screens/Hypotheses/HypothesisDetails";
import { Modal } from "antd";

interface Props {
  project: Project;
  canvas: Canvas;
  blocks: CanvasBlock[];
  categories: Category[];
  hypotheses: Hypothesis[] | undefined;
  columns: number;
  staticGrid: boolean;
  openHypothesisHandler: Function | null | undefined;
  addNewHypothesisHandler: Function | null | undefined;
  updateHypothesisHandler: Function | null | undefined;
  deleteHypothesisHandler: Function | null | undefined;
}

export function CanvasEditor({
  project,
  canvas,
  blocks,
  categories,
  hypotheses,
  columns,
  staticGrid,
  openHypothesisHandler,
  addNewHypothesisHandler,
  updateHypothesisHandler,
  deleteHypothesisHandler,
}: Props) {
  const refs = useRef<{ [key: string]: any }>({});
  const gridRef = useRef<GridStack>();

  if (Object.keys(refs.current).length !== blocks.length) {
    blocks.forEach((eachBlock: CanvasBlock) => {
      refs.current[eachBlock.id] = refs.current[eachBlock.id] || createRef();
    });
  }

  useEffect(() => {
    gridRef.current = GridStack.init(
      {
        cellHeight: "250px",
        column: columns,
        staticGrid: staticGrid,
        handleClass: "gs-drag-handler",
      },
      ".controlled"
    );
    const grid = gridRef.current;
    grid.batchUpdate();
    grid.removeAll(false);
    blocks.forEach(({ id }: CanvasBlock) => {
      grid.makeWidget(refs.current[id].current);
    });
    grid.batchUpdate(false);

    grid.on("dragstop resizestop", (event: Event, el: any) => {
      const node: GridStackNode = el.gridstackNode;
      const { id, x, y, h, w } = node;
      handleUpdateCanvasBlock({
        id: id,
        x: x,
        y: y,
        height: h,
        width: w,
      });
    });
  }, [blocks, columns]);

  async function handleUpdateCanvasBlock(updatedProperties: {
    [key: string]: any;
  }) {
    if (!canvas) {
      return;
    }

    const original = await DataStore.query(CanvasBlock, updatedProperties.id);

    if (original) {
      await DataStore.save(
        CanvasBlock.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteCanvasBlock(id: string) {
    const toDelete = await DataStore.query(CanvasBlock, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div style={{ minHeight: 400 }}>
      <div key={"gs-columns-" + columns} className={`grid-stack controlled`}>
        {blocks.map((eachBlock: CanvasBlock, i: number) => (
          <div
            ref={refs.current[eachBlock.id]}
            key={"gs-item-" + eachBlock.id}
            gs-id={eachBlock.id}
            gs-x={eachBlock.x}
            gs-y={eachBlock.y}
            gs-h={eachBlock.height}
            gs-w={eachBlock.width}
            className={"grid-stack-item"}
          >
            <div
              key={"gs-content-" + eachBlock.id}
              className="grid-stack-item-content"
              style={{ top: 5, right: 5, bottom: 5, left: 5 }}
            >
              {staticGrid && (
                <CanvasStaticBlockCell
                  key={"block-" + eachBlock.id}
                  project={project}
                  canvasBlock={eachBlock}
                  hypotheses={
                    hypotheses?.filter(
                      (h) => h.category?.id === eachBlock.category?.id
                    ) || []
                  }
                  categories={categories}
                  openItemHandler={openHypothesisHandler}
                  addNewItemHandler={addNewHypothesisHandler}
                />
              )}
              {!staticGrid && (
                <CanvasEditableBlockCell
                  key={"block-" + eachBlock.id}
                  canvasBlock={eachBlock}
                  categories={categories}
                  updateItemHandler={handleUpdateCanvasBlock}
                  deleteItemHandler={handleDeleteCanvasBlock}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
