import { Button } from "antd";
import React from "react";

interface Props {
  title: string;
  type: "article" | "tour" | "survey";
  id: number;
  buttonType?:
    | "link"
    | "text"
    | "dashed"
    | "ghost"
    | "primary"
    | "default"
    | undefined;
  buttonShape?: "default" | "circle" | "round" | undefined;
}

export function IntercomButton({
  title,
  type,
  id,
  buttonType,
  buttonShape,
}: Props) {
  return (
    <Button
      type={buttonType || "default"}
      shape={buttonShape}
      style={{ border: "none" }}
      onClick={() => {
        switch (type) {
          case "article":
            window.Intercom("showArticle", id);
            break;
          case "tour":
            window.Intercom("startTour", id);
            break;
          case "survey":
            window.Intercom("startSurvey", id);
            break;
        }
      }}
    >
      {title}
    </Button>
  );
}
