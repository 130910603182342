/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkUserForActivePlan = /* GraphQL */ `
  query CheckUserForActivePlan {
    checkUserForActivePlan
  }
`;
export const getStripeCheckoutUrl = /* GraphQL */ `
  query GetStripeCheckoutUrl($organizationId: String, $planType: String) {
    getStripeCheckoutUrl(organizationId: $organizationId, planType: $planType)
  }
`;
export const getStripeCustomerPortalUrl = /* GraphQL */ `
  query GetStripeCustomerPortalUrl($organizationId: String) {
    getStripeCustomerPortalUrl(organizationId: $organizationId)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      firstName
      lastName
      email
      tawkToHMAC
      intercomHMAC
      organizations {
        items {
          id
          owner
          role
          user {
            id
            owner
            firstName
            lastName
            email
            tawkToHMAC
            intercomHMAC
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          organization {
            id
            owner
            name
            stripeCustomerId
            stripeSubscriptionId
            subscriptionStatus
            subscriptionPlan
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          organizationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        firstName
        lastName
        email
        tawkToHMAC
        intercomHMAC
        organizations {
          items {
            id
            owner
            role
            userId
            organizationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        tawkToHMAC
        intercomHMAC
        organizations {
          items {
            id
            owner
            role
            userId
            organizationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserOrganization = /* GraphQL */ `
  query GetUserOrganization($id: ID!) {
    getUserOrganization(id: $id) {
      id
      owner
      role
      user {
        id
        owner
        firstName
        lastName
        email
        tawkToHMAC
        intercomHMAC
        organizations {
          items {
            id
            owner
            role
            userId
            organizationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userId
      organization {
        id
        owner
        name
        stripeCustomerId
        stripeSubscriptionId
        subscriptionStatus
        subscriptionPlan
        users {
          items {
            id
            owner
            role
            userId
            organizationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      organizationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserOrganizations = /* GraphQL */ `
  query ListUserOrganizations(
    $filter: ModelUserOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        role
        user {
          id
          owner
          firstName
          lastName
          email
          tawkToHMAC
          intercomHMAC
          organizations {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        userId
        organization {
          id
          owner
          name
          stripeCustomerId
          stripeSubscriptionId
          subscriptionStatus
          subscriptionPlan
          users {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        organizationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserOrganizations = /* GraphQL */ `
  query SyncUserOrganizations(
    $filter: ModelUserOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        role
        user {
          id
          owner
          firstName
          lastName
          email
          tawkToHMAC
          intercomHMAC
          organizations {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        userId
        organization {
          id
          owner
          name
          stripeCustomerId
          stripeSubscriptionId
          subscriptionStatus
          subscriptionPlan
          users {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        organizationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      owner
      name
      stripeCustomerId
      stripeSubscriptionId
      subscriptionStatus
      subscriptionPlan
      users {
        items {
          id
          owner
          role
          user {
            id
            owner
            firstName
            lastName
            email
            tawkToHMAC
            intercomHMAC
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          userId
          organization {
            id
            owner
            name
            stripeCustomerId
            stripeSubscriptionId
            subscriptionStatus
            subscriptionPlan
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          organizationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        stripeCustomerId
        stripeSubscriptionId
        subscriptionStatus
        subscriptionPlan
        users {
          items {
            id
            owner
            role
            userId
            organizationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        stripeCustomerId
        stripeSubscriptionId
        subscriptionStatus
        subscriptionPlan
        users {
          items {
            id
            owner
            role
            userId
            organizationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      owner
      name
      description
      hypotheses {
        items {
          id
          owner
          name
          description
          hypothesis
          status
          decision
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          objective {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          objectiveId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      experiments {
        items {
          id
          owner
          name
          description
          parentHypothesis {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentHypothesisId
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          type {
            id
            owner
            name
            description
            hypothesisDescription
            riskiesAssumptionDescription
            methodDescription
            predictionDescription
            outcomeDescription
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          typeId
          notes
          hypothesis
          hypothesisString
          method
          prediction
          outcome
          decision
          status
          cost
          startDate
          endDate
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      learnings {
        items {
          id
          owner
          name
          content
          timestamp
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          experiment {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          experimentId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      canvasBlocks {
        items {
          id
          owner
          name
          description
          x
          y
          height
          width
          canvas {
            id
            owner
            name
            description
            columns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          canvasId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        canvasBlocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        canvasBlocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCanvas = /* GraphQL */ `
  query GetCanvas($id: ID!) {
    getCanvas(id: $id) {
      id
      owner
      name
      description
      columns
      blocks {
        items {
          id
          owner
          name
          description
          x
          y
          height
          width
          canvas {
            id
            owner
            name
            description
            columns
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          canvasId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCanvas = /* GraphQL */ `
  query ListCanvas(
    $filter: ModelCanvasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanvas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        columns
        blocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCanvas = /* GraphQL */ `
  query SyncCanvas(
    $filter: ModelCanvasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCanvas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        columns
        blocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCanvasBlock = /* GraphQL */ `
  query GetCanvasBlock($id: ID!) {
    getCanvasBlock(id: $id) {
      id
      owner
      name
      description
      x
      y
      height
      width
      canvas {
        id
        owner
        name
        description
        columns
        blocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      canvasId
      category {
        id
        owner
        name
        description
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        canvasBlocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCanvasBlocks = /* GraphQL */ `
  query ListCanvasBlocks(
    $filter: ModelCanvasBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanvasBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        x
        y
        height
        width
        canvas {
          id
          owner
          name
          description
          columns
          blocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        canvasId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCanvasBlocks = /* GraphQL */ `
  query SyncCanvasBlocks(
    $filter: ModelCanvasBlockFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCanvasBlocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        x
        y
        height
        width
        canvas {
          id
          owner
          name
          description
          columns
          blocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        canvasId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      owner
      name
      description
      projects {
        items {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      children {
        items {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      parentCollection {
        id
        owner
        name
        description
        projects {
          items {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        children {
          items {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        parentCollection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentCollectionId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      parentCollectionId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        projects {
          items {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        children {
          items {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        parentCollection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentCollectionId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCollections = /* GraphQL */ `
  query SyncCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        projects {
          items {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        children {
          items {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        parentCollection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentCollectionId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      owner
      name
      description
      collection {
        id
        owner
        name
        description
        projects {
          items {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        children {
          items {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        parentCollection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentCollectionId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      collectionId
      objectives {
        items {
          id
          owner
          name
          description
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          hypotheses {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      hypotheses {
        items {
          id
          owner
          name
          description
          hypothesis
          status
          decision
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          objective {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          objectiveId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      experiments {
        items {
          id
          owner
          name
          description
          parentHypothesis {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentHypothesisId
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          type {
            id
            owner
            name
            description
            hypothesisDescription
            riskiesAssumptionDescription
            methodDescription
            predictionDescription
            outcomeDescription
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          typeId
          notes
          hypothesis
          hypothesisString
          method
          prediction
          outcome
          decision
          status
          cost
          startDate
          endDate
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      learnings {
        items {
          id
          owner
          name
          content
          timestamp
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          experiment {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          experimentId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        collection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        collectionId
        objectives {
          items {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        collection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        collectionId
        objectives {
          items {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getObjective = /* GraphQL */ `
  query GetObjective($id: ID!) {
    getObjective(id: $id) {
      id
      owner
      name
      description
      project {
        id
        owner
        name
        description
        collection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        collectionId
        objectives {
          items {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      hypotheses {
        items {
          id
          owner
          name
          description
          hypothesis
          status
          decision
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          objective {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          objectiveId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listObjectives = /* GraphQL */ `
  query ListObjectives(
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjectives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncObjectives = /* GraphQL */ `
  query SyncObjectives(
    $filter: ModelObjectiveFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncObjectives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHypothesis = /* GraphQL */ `
  query GetHypothesis($id: ID!) {
    getHypothesis(id: $id) {
      id
      owner
      name
      description
      hypothesis
      status
      decision
      project {
        id
        owner
        name
        description
        collection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        collectionId
        objectives {
          items {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      objective {
        id
        owner
        name
        description
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      objectiveId
      category {
        id
        owner
        name
        description
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        canvasBlocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryId
      experiments {
        items {
          id
          owner
          name
          description
          parentHypothesis {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentHypothesisId
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          type {
            id
            owner
            name
            description
            hypothesisDescription
            riskiesAssumptionDescription
            methodDescription
            predictionDescription
            outcomeDescription
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          typeId
          notes
          hypothesis
          hypothesisString
          method
          prediction
          outcome
          decision
          status
          cost
          startDate
          endDate
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHypotheses = /* GraphQL */ `
  query ListHypotheses(
    $filter: ModelHypothesisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHypotheses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        hypothesis
        status
        decision
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        objective {
          id
          owner
          name
          description
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          hypotheses {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        objectiveId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHypotheses = /* GraphQL */ `
  query SyncHypotheses(
    $filter: ModelHypothesisFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHypotheses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        hypothesis
        status
        decision
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        objective {
          id
          owner
          name
          description
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          hypotheses {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        objectiveId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExperiment = /* GraphQL */ `
  query GetExperiment($id: ID!) {
    getExperiment(id: $id) {
      id
      owner
      name
      description
      parentHypothesis {
        id
        owner
        name
        description
        hypothesis
        status
        decision
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        objective {
          id
          owner
          name
          description
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          hypotheses {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        objectiveId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      parentHypothesisId
      project {
        id
        owner
        name
        description
        collection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        collectionId
        objectives {
          items {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      category {
        id
        owner
        name
        description
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        canvasBlocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryId
      type {
        id
        owner
        name
        description
        hypothesisDescription
        riskiesAssumptionDescription
        methodDescription
        predictionDescription
        outcomeDescription
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeId
      notes
      hypothesis
      hypothesisString
      method
      prediction
      outcome
      decision
      status
      cost
      startDate
      endDate
      learnings {
        items {
          id
          owner
          name
          content
          timestamp
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          experiment {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          experimentId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listExperiments = /* GraphQL */ `
  query ListExperiments(
    $filter: ModelExperimentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperiments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        parentHypothesis {
          id
          owner
          name
          description
          hypothesis
          status
          decision
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          objective {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          objectiveId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentHypothesisId
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        type {
          id
          owner
          name
          description
          hypothesisDescription
          riskiesAssumptionDescription
          methodDescription
          predictionDescription
          outcomeDescription
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        typeId
        notes
        hypothesis
        hypothesisString
        method
        prediction
        outcome
        decision
        status
        cost
        startDate
        endDate
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExperiments = /* GraphQL */ `
  query SyncExperiments(
    $filter: ModelExperimentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExperiments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        parentHypothesis {
          id
          owner
          name
          description
          hypothesis
          status
          decision
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          objective {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          objectiveId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentHypothesisId
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        type {
          id
          owner
          name
          description
          hypothesisDescription
          riskiesAssumptionDescription
          methodDescription
          predictionDescription
          outcomeDescription
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        typeId
        notes
        hypothesis
        hypothesisString
        method
        prediction
        outcome
        decision
        status
        cost
        startDate
        endDate
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExperimentType = /* GraphQL */ `
  query GetExperimentType($id: ID!) {
    getExperimentType(id: $id) {
      id
      owner
      name
      description
      hypothesisDescription
      riskiesAssumptionDescription
      methodDescription
      predictionDescription
      outcomeDescription
      experiments {
        items {
          id
          owner
          name
          description
          parentHypothesis {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentHypothesisId
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          type {
            id
            owner
            name
            description
            hypothesisDescription
            riskiesAssumptionDescription
            methodDescription
            predictionDescription
            outcomeDescription
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          typeId
          notes
          hypothesis
          hypothesisString
          method
          prediction
          outcome
          decision
          status
          cost
          startDate
          endDate
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listExperimentTypes = /* GraphQL */ `
  query ListExperimentTypes(
    $filter: ModelExperimentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperimentTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        hypothesisDescription
        riskiesAssumptionDescription
        methodDescription
        predictionDescription
        outcomeDescription
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExperimentTypes = /* GraphQL */ `
  query SyncExperimentTypes(
    $filter: ModelExperimentTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExperimentTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        hypothesisDescription
        riskiesAssumptionDescription
        methodDescription
        predictionDescription
        outcomeDescription
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLearning = /* GraphQL */ `
  query GetLearning($id: ID!) {
    getLearning(id: $id) {
      id
      owner
      name
      content
      timestamp
      project {
        id
        owner
        name
        description
        collection {
          id
          owner
          name
          description
          projects {
            nextToken
            startedAt
          }
          children {
            nextToken
            startedAt
          }
          parentCollection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentCollectionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        collectionId
        objectives {
          items {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      projectId
      experiment {
        id
        owner
        name
        description
        parentHypothesis {
          id
          owner
          name
          description
          hypothesis
          status
          decision
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          objective {
            id
            owner
            name
            description
            projectId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          objectiveId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        parentHypothesisId
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        type {
          id
          owner
          name
          description
          hypothesisDescription
          riskiesAssumptionDescription
          methodDescription
          predictionDescription
          outcomeDescription
          experiments {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        typeId
        notes
        hypothesis
        hypothesisString
        method
        prediction
        outcome
        decision
        status
        cost
        startDate
        endDate
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      experimentId
      category {
        id
        owner
        name
        description
        hypotheses {
          items {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        experiments {
          items {
            id
            owner
            name
            description
            parentHypothesisId
            projectId
            categoryId
            typeId
            notes
            hypothesis
            hypothesisString
            method
            prediction
            outcome
            decision
            status
            cost
            startDate
            endDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        learnings {
          items {
            id
            owner
            name
            content
            timestamp
            projectId
            experimentId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        canvasBlocks {
          items {
            id
            owner
            name
            description
            x
            y
            height
            width
            canvasId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLearnings = /* GraphQL */ `
  query ListLearnings(
    $filter: ModelLearningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearnings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        content
        timestamp
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        experiment {
          id
          owner
          name
          description
          parentHypothesis {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentHypothesisId
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          type {
            id
            owner
            name
            description
            hypothesisDescription
            riskiesAssumptionDescription
            methodDescription
            predictionDescription
            outcomeDescription
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          typeId
          notes
          hypothesis
          hypothesisString
          method
          prediction
          outcome
          decision
          status
          cost
          startDate
          endDate
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        experimentId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLearnings = /* GraphQL */ `
  query SyncLearnings(
    $filter: ModelLearningFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearnings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        content
        timestamp
        project {
          id
          owner
          name
          description
          collection {
            id
            owner
            name
            description
            parentCollectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          collectionId
          objectives {
            nextToken
            startedAt
          }
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        projectId
        experiment {
          id
          owner
          name
          description
          parentHypothesis {
            id
            owner
            name
            description
            hypothesis
            status
            decision
            projectId
            objectiveId
            categoryId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          parentHypothesisId
          project {
            id
            owner
            name
            description
            collectionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          projectId
          category {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          categoryId
          type {
            id
            owner
            name
            description
            hypothesisDescription
            riskiesAssumptionDescription
            methodDescription
            predictionDescription
            outcomeDescription
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          typeId
          notes
          hypothesis
          hypothesisString
          method
          prediction
          outcome
          decision
          status
          cost
          startDate
          endDate
          learnings {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        experimentId
        category {
          id
          owner
          name
          description
          hypotheses {
            nextToken
            startedAt
          }
          experiments {
            nextToken
            startedAt
          }
          learnings {
            nextToken
            startedAt
          }
          canvasBlocks {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChapter = /* GraphQL */ `
  query GetChapter($id: ID!) {
    getChapter(id: $id) {
      id
      title
      path
      rank
      articles {
        items {
          id
          title
          shortTitle
          text
          path
          rank
          chapter {
            id
            title
            path
            rank
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          chapterId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listChapters = /* GraphQL */ `
  query ListChapters(
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChapters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        path
        rank
        articles {
          items {
            id
            title
            shortTitle
            text
            path
            rank
            chapterId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChapters = /* GraphQL */ `
  query SyncChapters(
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChapters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        path
        rank
        articles {
          items {
            id
            title
            shortTitle
            text
            path
            rank
            chapterId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      shortTitle
      text
      path
      rank
      chapter {
        id
        title
        path
        rank
        articles {
          items {
            id
            title
            shortTitle
            text
            path
            rank
            chapterId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      chapterId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shortTitle
        text
        path
        rank
        chapter {
          id
          title
          path
          rank
          articles {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        chapterId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncArticles = /* GraphQL */ `
  query SyncArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        shortTitle
        text
        path
        rank
        chapter {
          id
          title
          path
          rank
          articles {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        chapterId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
