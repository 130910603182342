import { Button, Col, Dropdown, Menu, Row, Tooltip } from "antd";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";

import { Collection } from "../../models";
import React from "react";

interface Props {
  collection: Collection;
  updateItemHandler: Function;
  deleteItemHandler: Function;
  onClick: Function;
  dragListeners: any;
  dragAttributes: any;
}

export default function ManageProjectsCollectionTableRow({
  collection,
  updateItemHandler,
  deleteItemHandler,
  onClick,
  dragListeners,
  dragAttributes,
  ...restProps
}: Props) {
  function moreMenu() {
    return (
      <Menu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(collection.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div {...restProps} className="tb-table-row" style={{ marginTop: 10 }}>
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "3px 25px",
          }}
          onClick={() => onClick(collection)}
        >
          <h3 style={{ margin: 0 }}>{collection.name || "Collection"}</h3>
        </Col>
        <Col flex="50px">
          <Tooltip title="More Options">
            <Dropdown
              overlay={moreMenu}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
            </Dropdown>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
}
