import * as DOMPurify from "dompurify";

import { Col, Divider } from "antd";
import React, { useMemo } from "react";

import { Card } from "./../../components/Card";
import { Hypothesis } from "../../models";
import { SafeHtmlRenderer } from "../../components/RichTextEditor/SafeHtmlRenderer";

interface Props {
  hypothesis: Hypothesis;
  updateItemHandler: Function;
  onClick: Function;
}

export function IdeasTableRow({
  hypothesis,
  updateItemHandler,
  onClick,
  ...restProps
}: Props) {
  const sanitizedHtml = useMemo(() => {
    return DOMPurify.sanitize(hypothesis.name || "");
  }, [hypothesis.name]);

  return (
    <div
      style={{ padding: 20 }}
      onClick={() => onClick(hypothesis)}
      {...restProps}
    >
      <div style={{ cursor: "pointer" }}>
        <Card>
          <Col style={{ width: 200, height: 200, overflow: "hidden" }}>
            <h2>{sanitizedHtml}</h2>
            <Divider style={{ marginTop: 0, marginBottom: 16 }} />
            <SafeHtmlRenderer htmlString={hypothesis.description || ""} />
          </Col>
        </Card>
      </div>
    </div>
  );
}
