import { Button, Col, Form, Input, Row, Steps } from "antd";
import React, { useEffect, useState } from "react";

import { DataStore } from "aws-amplify";
import { LikeOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import OtpField from "../../components/OtpField";
import { ampli } from "../../ampli";
import { useAuth } from "../../components/UseAuth";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;
const STEP_1_ENTER_EMAIL = "enterEmail";
const STEP_2_REGISTERING_EMAIL = "registeringEmail";
const STEP_3_ENTER_CODE = "enterCode";
const STEP_4_VALIDATING_CODE = "validatingCode";
const STEP_5_ERROR_VALIDATING_CODE = "errorValidatingCode";
const STEP_6_DONE = "done";

export default function SignIn() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const auth = useAuth();
  const [currentStep, setCurrentStep] = useState(STEP_1_ENTER_EMAIL);
  const [otpValue, setOtpValue] = useState("");
  const [codeInputDisabled, setCodeInputDisabled] = useState(false);
  const [completeOnboarding, setCompleteOnboarding] = useState(false);

  const handleFinish = (values) => {
    setCurrentStep(STEP_2_REGISTERING_EMAIL);
    signIn(values.value);
  };

  const handleFinishFailed = () => {
    switch (currentStep) {
      case STEP_2_REGISTERING_EMAIL:
        setCurrentStep(STEP_1_ENTER_EMAIL);
        break;
      case STEP_4_VALIDATING_CODE:
        setCurrentStep(STEP_3_ENTER_CODE);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
        ampli.viewedSignInEnterEmailScreen();
        break;
      case STEP_3_ENTER_CODE:
        ampli.viewedSignInEnterOtpScreen();
        break;
      case STEP_6_DONE:
        ampli.viewedSignInCompleteScreen();
        break;
      default:
        break;
    }
  }, [currentStep]);

  function getCurrentTitle() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
      case STEP_2_REGISTERING_EMAIL:
        return "Hello 👋";
      case STEP_3_ENTER_CODE:
      case STEP_4_VALIDATING_CODE:
        return "Great! 🙌";
      case STEP_5_ERROR_VALIDATING_CODE:
        return "Uh oh, the code is wrong 😕";
      case STEP_6_DONE:
        return "All done! 🎉";
      default:
        return "Think this is an error?";
    }
  }

  function getCurrentSubtitle() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
      case STEP_2_REGISTERING_EMAIL:
        return "Let’s start by entering your email address.";
      case STEP_3_ENTER_CODE:
      case STEP_4_VALIDATING_CODE:
        return "We've sent you a code to verify your email, please enter it below.";
      case STEP_5_ERROR_VALIDATING_CODE:
        return "We’ve emailed you another one, please try again.";
      case STEP_6_DONE:
        return "Opening the app for you now.";
      default:
        return "Think this is an error?";
    }
  }

  function getCurrentButtonTitle() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
        return "Send My Code";
      case STEP_2_REGISTERING_EMAIL:
        return "Working...";
      case STEP_3_ENTER_CODE:
        return "Validate";
      case STEP_4_VALIDATING_CODE:
        return "Validating...";
      case STEP_6_DONE:
        return "";
      default:
        return "Think this is an error?";
    }
  }

  function getCurrentStepIndex() {
    switch (currentStep) {
      case STEP_1_ENTER_EMAIL:
      case STEP_2_REGISTERING_EMAIL:
        return 0;
      case STEP_3_ENTER_CODE:
      case STEP_4_VALIDATING_CODE:
      case STEP_5_ERROR_VALIDATING_CODE:
        return 1;
      case STEP_6_DONE:
        return 2;
      default:
        return 0;
    }
  }

  async function updateOtpValue(value) {
    setOtpValue(value);
    if (value.length === 6) {
      setCodeInputDisabled(true);
      await verifyCode(value);
    }
  }

  async function signIn(email) {
    try {
      await auth.signIn(email);
      form.resetFields();
      setCurrentStep(STEP_3_ENTER_CODE);
    } catch (error) {
      // console.log(error.code);
      // console.error(error);
    }
  }

  async function verifyCode(code) {
    try {
      await auth.verifyOtpCode(code);
      setCurrentStep(STEP_6_DONE);
      await DataStore.clear();
      await DataStore.start();
      setTimeout(() => {
        navigate(0);
      }, 2000);
    } catch (error) {
      setCurrentStep(STEP_5_ERROR_VALIDATING_CODE);
      setCodeInputDisabled(false);
      setOtpValue("");
      console.error("Error:", error);
    }
  }

  return (
    <div>
      <Row justify="center">
        <Col style={{ paddingTop: 100, width: "500px" }}>
          <Row>
            <Steps current={getCurrentStepIndex()}>
              <Step title="Email" />
              <Step title="Verification" />
              <Step title="Done" icon={<LikeOutlined />} />
            </Steps>
          </Row>
          <Row
            justify="center"
            style={{ paddingTop: "100px", paddingBottom: "80px" }}
          >
            <Col style={{ width: "100%" }}>
              <h1>{getCurrentTitle()}</h1>
              <h3>{getCurrentSubtitle()}</h3>
            </Col>
          </Row>
          <Row justify="center">
            <Col flex="auto" style={{ textAlign: "center" }}>
              {(currentStep === STEP_1_ENTER_EMAIL ||
                currentStep === STEP_2_REGISTERING_EMAIL) && (
                <Form
                  form={form}
                  name="emailAddress"
                  layout="inline"
                  initialValues={{ remember: true }}
                  validateTrigger="onFinish"
                  onFinish={handleFinish}
                  onFinishFailed={handleFinishFailed}
                >
                  <Form.Item
                    label=""
                    name="value"
                    style={{ flex: "auto" }}
                    rules={[
                      {
                        type: "email",
                        message: "It doesn’t look like a valid email",
                      },
                      {
                        required: true,
                        message: "Please write your email address",
                      },
                    ]}
                  >
                    <Input placeholder="Your email" />
                  </Form.Item>

                  <Form.Item style={{ marginRight: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ borderRadius: 6 }}
                      loading={
                        currentStep === STEP_2_REGISTERING_EMAIL ||
                        currentStep === STEP_4_VALIDATING_CODE
                          ? true
                          : false
                      }
                    >
                      {getCurrentButtonTitle()}
                    </Button>
                  </Form.Item>
                </Form>
              )}

              {(currentStep === STEP_3_ENTER_CODE ||
                currentStep === STEP_4_VALIDATING_CODE ||
                currentStep === STEP_5_ERROR_VALIDATING_CODE) && (
                <OtpField
                  style={{ flex: "auto" }}
                  value={otpValue}
                  onChange={updateOtpValue}
                  numInputs={6}
                  onChangeRegex={/^([0-9]{0,})$/}
                  autoFocus
                  separator={<span>-</span>}
                  isTypeNumber={false}
                  hasErrored={currentStep === STEP_5_ERROR_VALIDATING_CODE}
                  inputProps={{
                    className: "ant-input",
                    disabled: codeInputDisabled,
                  }}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {completeOnboarding && (
        <>
          <Navigate to="/" />
        </>
      )}
    </div>
  );
}
