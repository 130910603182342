import { Col, Row } from "antd";
import React, { useEffect } from "react";

import { Category } from "../../models";
import { ManageCategoriesTable } from "./ManageCategoriesTable";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  categories: Category[];
}

export function ManageCategories({ setPageTitle, categories }: Props) {
  useEffect(() => {
    setPageTitle("Manage My Categories");
    ampli.viewedManageCategoriesScreen();
  }, []);

  return (
    <Row justify="center">
      <Col flex="auto" style={{ maxWidth: 768 }}>
        <ManageCategoriesTable categories={categories} />
      </Col>
    </Row>
  );
}
