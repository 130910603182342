import {
  Experiment,
  ExperimentStatusEnum,
  Hypothesis,
  HypothesisDecisionEnum,
} from "../../models";
import React, { useEffect, useRef, useState } from "react";

import { Card } from "./../../components/Card";
import { Col } from "antd";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsInputRow } from "../../components/DetailsInputRow";
import { DetailsRichTextEditorRow } from "../../components/DetailsRichTextEditorRow";
import { DetailsTitleHeader } from "../../components/DetailsTitleHeader";
import { ExperimentDetailsFooterBar } from "./ExperimentDetailsFooterBar";
import { ExperimentLearningsTable } from "./ExperimentLearningsTable";

interface Props {
  experiment: Experiment;
  setModalVisible: Function;
  setHelpContentPath?: Function;
}

export function ExperimentDetails({
  experiment,
  setModalVisible,
  setHelpContentPath,
}: Props) {
  const theExperiment = useRef<Experiment | null>(experiment);
  const theHypothesis = useRef<Hypothesis | null>(
    experiment.parentHypothesis || null
  );

  const [hypothesis, setHypothesis] = useState<Hypothesis | null>(
    experiment.parentHypothesis || null
  );

  useEffect(() => {
    const loadData = async () => {
      theExperiment.current = await loadExperiment(experiment.id);
      theHypothesis.current = await loadHypothesis(
        experiment.parentHypothesis!.id
      );
      setHypothesis(theHypothesis.current);
    };
    loadData();
  }, [
    experiment.id,
    experiment.parentHypothesis,
    experiment.parentHypothesis?.id,
  ]);

  useEffect(() => {
    const experimentSubscription = DataStore.observe(Experiment).subscribe(
      async () => {
        theExperiment.current = await loadExperiment(experiment.id);
      }
    );
    const hypothesisSubscription = DataStore.observe(Hypothesis).subscribe(
      async () => {
        theHypothesis.current = await loadHypothesis(
          experiment.parentHypothesis!.id
        );
        setHypothesis(theHypothesis.current);
      }
    );
    return () => {
      experimentSubscription.unsubscribe();
      hypothesisSubscription.unsubscribe();
    };
  }, [
    experiment.id,
    experiment.parentHypothesis,
    experiment.parentHypothesis?.id,
  ]);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        theExperiment.current = await loadExperiment(experiment.id);
        theHypothesis.current = await loadHypothesis(
          experiment.parentHypothesis!.id
        );
        setHypothesis(theHypothesis.current);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [
    experiment.id,
    experiment.parentHypothesis,
    experiment.parentHypothesis?.id,
  ]);

  async function loadExperiment(experimentId: string) {
    return (await DataStore.query(Experiment, experimentId)) || null;
  }

  async function loadHypothesis(hypothesisId: string) {
    return (await DataStore.query(Hypothesis, hypothesisId)) || null;
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Experiment, experiment.id);
    if (original) {
      theExperiment.current = await DataStore.save(
        Experiment.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleUpdateParentHypothesis(updatedProperties: {
    [key: string]: any;
  }) {
    if (!theHypothesis.current?.id) {
      return;
    }

    const original = await DataStore.query(
      Hypothesis,
      theHypothesis.current.id
    );
    if (original) {
      theHypothesis.current = await DataStore.save(
        Hypothesis.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function updateExperimentStatus(expStatus: ExperimentStatusEnum) {
    const original = await DataStore.query(Experiment, experiment.id);
    if (original) {
      theExperiment.current = await DataStore.save(
        Experiment.copyOf(original, (updated) => {
          updated.status = expStatus;
        })
      );
    }
  }

  async function updateExperimentDecisions(
    expDecision: HypothesisDecisionEnum
  ) {
    const original = await DataStore.query(Experiment, experiment.id);
    if (original) {
      theExperiment.current = await DataStore.save(
        Experiment.copyOf(original, (updated) => {
          updated.decision = expDecision;
        })
      );
    }
  }

  // async function handleDeleteExperiment(id: string) {
  //   const toDelete = await DataStore.query(Experiment, id);
  //   if (toDelete) {
  //     await DataStore.delete(toDelete);
  //   }
  // }

  return (
    <Card>
      <DetailsTitleHeader
        title={theExperiment.current?.name || ""}
        placeholder={"What's your experiment's name?"}
        updateTitleHandler={async (content: string) => {
          await handleUpdateItem({
            name: content,
          });
        }}
      />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Hypothesis */}
        <DetailsInputRow
          sectionTitle="Hypothesis"
          placeholder=""
          value={hypothesis?.name || ""}
          description={""}
          updateItemHandler={async (content: string) => {
            await handleUpdateParentHypothesis({
              name: content,
            });
          }}
        />

        {/* Method */}
        <DetailsRichTextEditorRow
          sectionTitle="Method"
          placeholder=""
          value={experiment.method || ""}
          description={
            "What is the LEAST expensive way you can can test your hypothesis?"
          }
          updateItemHandler={async (content: string) => {
            await handleUpdateItem({
              method: content,
            });
          }}
        />

        {/* Prediction */}
        <DetailsRichTextEditorRow
          sectionTitle="Prediction"
          placeholder=""
          value={experiment.prediction || ""}
          description={
            "What needs to be true to move forward? Suggestion: X% user growth, more than Y signups, etc."
          }
          updateItemHandler={async (content: string) => {
            await handleUpdateItem({
              prediction: content,
            });
          }}
        />

        {/* Learnings */}
        {experiment.status !== ExperimentStatusEnum.WAITING && (
          <div style={{ marginTop: 32, marginBottom: 8 }}>
            <ExperimentLearningsTable experiment={experiment} />
          </div>
        )}

        {/* Outcome */}
        {experiment.status !== ExperimentStatusEnum.WAITING && (
          <DetailsRichTextEditorRow
            sectionTitle="Outcome"
            placeholder=""
            value={experiment.outcome || ""}
            description={"What were the results?"}
            updateItemHandler={async (content: string) => {
              await handleUpdateItem({
                outcome: content,
              });
            }}
          />
        )}
      </Col>

      <ExperimentDetailsFooterBar
        experiment={theExperiment.current!}
        setModalVisible={setModalVisible}
        updateExperimentDecisions={updateExperimentDecisions}
        updateExperimentStatus={updateExperimentStatus}
      />
    </Card>
  );
}
