import { API, graphqlOperation } from "aws-amplify";
import { Button, Col, Divider, Row } from "antd";
import {
  GetStripeCheckoutUrlQuery,
  GetStripeCustomerPortalUrlQuery,
} from "../../API";
import React, { useState } from "react";
import {
  getStripeCheckoutUrl,
  getStripeCustomerPortalUrl,
} from "../../graphql/queries";

import PricingCardBenefit from "./PricingCardBenefit";

interface Props {
  organizationId: string;
  planName: string;
  planType: string;
  localizedPrice: string;
  period: string;
  benefits: string[];
  buttonEnabled: boolean;
  buttonTitle: string;
}

export function PricingCard({
  organizationId,
  planName,
  planType,
  localizedPrice,
  period,
  benefits,
  buttonEnabled,
  buttonTitle,
}: Props) {
  const [purchasingPlan, setPurchasingPlan] = useState(false);

  async function startPurchasingPlan(planType: string) {
    setPurchasingPlan(true);
    const { data } = (await API.graphql(
      graphqlOperation(getStripeCheckoutUrl, {
        organizationId: organizationId,
        planType: planType,
      })
    )) as { data: GetStripeCheckoutUrlQuery };

    const { sessionId } = JSON.parse(data.getStripeCheckoutUrl || "");

    if (sessionId) {
      window.location.href = sessionId;
    }

    setPurchasingPlan(false);
  }

  async function openStripeCustomerPortal(organizationId: string) {
    setPurchasingPlan(true);
    const { data } = (await API.graphql(
      graphqlOperation(getStripeCustomerPortalUrl, {
        organizationId: organizationId,
      })
    )) as { data: GetStripeCustomerPortalUrlQuery };

    setPurchasingPlan(false);

    const { sessionUrl } = JSON.parse(data.getStripeCustomerPortalUrl || "");

    if (sessionUrl) {
      window.location.href = sessionUrl;
    }
  }

  return (
    <div className="tb-pricing-card shadow-spread">
      <Row align="middle">
        <h4 style={{ fontSize: "30px", fontWeight: 700 }}>{planName}</h4>
      </Row>
      <Row align="middle">
        <p>
          <span style={{ fontSize: "32px", fontWeight: 500 }}>
            {localizedPrice}
          </span>
          {planType !== "free" && <span>{` / ${period} / seat`}</span>}
        </p>
      </Row>

      <Divider />

      {benefits.map((eachBenefit) => (
        <Row>
          <PricingCardBenefit key={eachBenefit} benefit={eachBenefit} />
        </Row>
      ))}

      <Row justify="center" style={{ marginTop: "auto" }}>
        <Col>
          <Button
            type="primary"
            shape="round"
            disabled={!buttonEnabled}
            loading={purchasingPlan}
            style={{ color: "black" }}
            onClick={async (e) => {
              e.preventDefault();
              if (planType === "free") {
                await openStripeCustomerPortal(organizationId);
              } else {
                await startPurchasingPlan(planType);
              }
            }}
          >
            <p
              style={{
                color: buttonEnabled ? "white" : "darkGrey",
                fontWeight: 700,
              }}
            >
              {buttonTitle}
            </p>
          </Button>
        </Col>
      </Row>
    </div>
  );
}
