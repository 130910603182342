import { Col, Row } from "antd";

import { Card } from "./Card";
import { Category } from "../models";
import { CategoryPickerCard } from "./CategoryPickerCard";
import React from "react";

export interface Props {
  categories: Category[];
  selectedCategoryHandler: Function;
  setModalVisible: Function;
}

export function CategoryPicker({
  categories,
  selectedCategoryHandler,
  setModalVisible,
}: Props) {
  return (
    <Card>
      <Row align="middle" justify="start" gutter={16}>
        <Col>
          <h1 style={{ marginBottom: 0 }}>Select Category</h1>
        </Col>
      </Row>
      {/* <br /> */}

      <Row gutter={0} wrap={true}>
        {categories.map((eachCategory) => (
          <CategoryPickerCard
            key={eachCategory.id}
            category={eachCategory}
            onClick={async (category: Category) => {
              selectedCategoryHandler(category);
            }}
          />
        ))}
      </Row>
    </Card>
  );
}
