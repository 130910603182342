import { Col, Row } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import React from "react";

export interface PricingCardBenefitProps {
  benefit: string;
}

export default function PricingCardBenefit({
  benefit,
}: PricingCardBenefitProps) {
  return (
    <div>
      <Row gutter={8}>
        <Col flex="20px">
          <CheckOutlined style={{ color: "#0a0" }} />
        </Col>
        <Col flex="calc(100% - 22px)">
          <p>{benefit}</p>
        </Col>
      </Row>
    </div>
  );
}
