// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserOrganizationRoleEnum = {
  "OWNER": "OWNER",
  "ADMIN": "ADMIN",
  "EDITOR": "EDITOR",
  "VIEWER": "VIEWER"
};

const SubscriptionStatusEnum = {
  "TRIAL": "TRIAL",
  "ACTIVE": "ACTIVE",
  "NOT_ACTIVE": "NOT_ACTIVE"
};

const SubscriptionPlanEnum = {
  "FREE": "FREE",
  "TRIAL": "TRIAL",
  "MONTHLY": "MONTHLY",
  "ANNUAL": "ANNUAL",
  "ENTERPRISE": "ENTERPRISE"
};

const HypothesisStatusEnum = {
  "IDEA": "IDEA",
  "SELECTED": "SELECTED",
  "TESTING": "TESTING",
  "VALIDATED": "VALIDATED",
  "INVALIDATED": "INVALIDATED",
  "INCONCLUSIVE": "INCONCLUSIVE"
};

const HypothesisDecisionEnum = {
  "PERSEVERE": "PERSEVERE",
  "PIVOT": "PIVOT",
  "ABORT": "ABORT"
};

const ExperimentStatusEnum = {
  "WAITING": "WAITING",
  "RUNNING": "RUNNING",
  "VALIDATED": "VALIDATED",
  "INVALIDATED": "INVALIDATED",
  "INCONCLUSIVE": "INCONCLUSIVE",
  "ON_HOLD": "ON_HOLD"
};

const { User, UserOrganization, Organization, Category, Hypothesis, Project, Collection, Objective, Experiment, ExperimentType, Learning, CanvasBlock, Canvas, Chapter, Article } = initSchema(schema);

export {
  User,
  UserOrganization,
  Organization,
  Category,
  Hypothesis,
  Project,
  Collection,
  Objective,
  Experiment,
  ExperimentType,
  Learning,
  CanvasBlock,
  Canvas,
  Chapter,
  Article,
  UserOrganizationRoleEnum,
  SubscriptionStatusEnum,
  SubscriptionPlanEnum,
  HypothesisStatusEnum,
  HypothesisDecisionEnum,
  ExperimentStatusEnum
};