import { Button, Col } from "antd";
import React, { useEffect, useState } from "react";

import { SubscriptionPlanEnum } from "../models";
import { useNavigate } from "react-router-dom";

interface Props {
  subscriptionPlan: SubscriptionPlanEnum;
  daysLeftInTrial: number;
}

export function UpgradePlanCard({ subscriptionPlan, daysLeftInTrial }: Props) {
  const [planUpgradeMessage, setPlanUpgradeMessage] = useState<{
    message: string;
    // alertType: "success" | "error" | "warning" | "info" | undefined;
    backgroundColor: "#f6ffed" | "#e6f7ff" | "#fff2f0" | "#fffbe6";
    borderColor: "#b7eb8f" | "#91d5ff" | "#ffccc7" | "#ffe58f";
  } | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // update the upgrade message based on the user's current subscription plan
    let message = "Get unlimited access.";
    let alertType: "success" | "error" | "warning" | "info" = "success";
    switch (subscriptionPlan) {
      case SubscriptionPlanEnum.FREE:
        message = "You're on the free plan. Get unlimited access.";
        alertType = "info";
        break;
      case SubscriptionPlanEnum.TRIAL:
        alertType = "info";

        if (daysLeftInTrial < 0) {
          message = "Your Free Trial expired. Get unlimited access.";
          alertType = "error";
        } else if (daysLeftInTrial === 0) {
          message = "Your Free Trial expires today. Get unlimited access.";
          alertType = "error";
        } else {
          let daysLeftString =
            daysLeftInTrial === 1
              ? `${daysLeftInTrial} day`
              : `${daysLeftInTrial} days`;
          message = `Your Free Trial expires in ${daysLeftString}. Get unlimited access.`;

          if (daysLeftInTrial <= 5) {
            alertType = "error";
          } else if (daysLeftInTrial <= 10) {
            alertType = "warning";
          }
        }
        break;
    }

    let backgroundColor: "#f6ffed" | "#e6f7ff" | "#fff2f0" | "#fffbe6";
    let borderColor: "#b7eb8f" | "#91d5ff" | "#ffccc7" | "#ffe58f";
    switch (alertType) {
      case "success":
        backgroundColor = "#f6ffed";
        borderColor = "#b7eb8f";
        break;
      case "info":
        backgroundColor = "#e6f7ff";
        borderColor = "#91d5ff";
        break;
      case "error":
        backgroundColor = "#fff2f0";
        borderColor = "#ffccc7";
        break;
      case "warning":
        backgroundColor = "#fffbe6";
        borderColor = "#ffe58f";
        break;
    }
    setPlanUpgradeMessage({
      message,
      backgroundColor,
      borderColor,
    });
  }, [daysLeftInTrial, subscriptionPlan]);

  return (
    <>
      {(subscriptionPlan === SubscriptionPlanEnum.FREE ||
        subscriptionPlan === SubscriptionPlanEnum.TRIAL) &&
        planUpgradeMessage && (
          <Col
            style={{
              borderRadius: 9,
              border: "1px solid #E8E8E8",
              padding: 20,
              backgroundColor: planUpgradeMessage?.backgroundColor,
              borderColor: planUpgradeMessage?.borderColor,
            }}
          >
            {planUpgradeMessage?.message}
            <Button
              type="primary"
              shape="round"
              style={{
                border: "none",
                marginTop: 16,
              }}
              onClick={() => {
                navigate("/profile/pricing");
              }}
            >
              <b>Upgrade Now</b>
            </Button>
          </Col>
        )}
    </>
  );
}
