import { Col, Row } from "antd";
import { Objective, Project } from "../../models";
import React, { useEffect } from "react";

import { ObjectivesTable } from "./ObjectivesTable";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  project: Project;
  objectives: Objective[];
}

export function Objectives({ setPageTitle, project, objectives }: Props) {
  useEffect(() => {
    setPageTitle("Objectives");
    ampli.viewedObjectivesScreen();
  }, []);

  return (
    <Row justify="center">
      <Col flex="auto" style={{ maxWidth: 768 }}>
        <ObjectivesTable project={project} objectives={objectives} />
      </Col>
    </Row>
  );
}
