import { Button, Col, Divider, Dropdown, Menu, Row } from "antd";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import React, { useRef } from "react";

import { Card } from "./../../../components/Card";
import { Chapter } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsInputRow } from "../../../components/DetailsInputRow";

interface Props {
  chapter: Chapter;
  deleteItemHandler: Function;
  onDone: Function;
}

export function AdminManageFrameworkChapterDetails({
  chapter,
  deleteItemHandler,
  onDone,
}: Props) {
  const theChapter = useRef<Chapter | null>(chapter);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Chapter, theChapter.current!.id);
    if (original) {
      theChapter.current = await DataStore.save(
        Chapter.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  function moreMenu() {
    return (
      <Menu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(chapter.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Card>
      <Row align="middle" justify="space-between" gutter={16}>
        <Col>
          <h1 style={{ marginBottom: 0 }}>Manage Chapter</h1>
        </Col>
        <Col
          flex="50px"
          onClick={async (event) => {
            event.stopPropagation();
          }}
        >
          <Dropdown
            overlay={moreMenu}
            placement="bottomRight"
            arrow
            trigger={["click"]}
          >
            <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
          </Dropdown>
        </Col>
      </Row>
      <br />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Title */}
        <DetailsInputRow
          sectionTitle="Name"
          placeholder=""
          value={theChapter.current?.title || ""}
          description={""}
          updateItemHandler={async (title: string) => {
            await handleUpdateItem({
              title: title,
            });
          }}
        />

        {/* Path */}
        <DetailsInputRow
          sectionTitle="Path"
          placeholder=""
          value={theChapter.current?.path || ""}
          description={""}
          updateItemHandler={async (path: string) => {
            await handleUpdateItem({
              path: path,
            });
          }}
        />

        {/* Rank */}
        <DetailsInputRow
          sectionTitle="Rank"
          placeholder=""
          value={theChapter.current?.rank || ""}
          description={""}
          updateItemHandler={async (rank: string) => {
            await handleUpdateItem({
              rank: rank,
            });
          }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
