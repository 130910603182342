import { Button, Col, Divider, Row } from "antd";
import React, { useRef } from "react";

import { Card } from "../../components/Card";
import { Collection } from "../../models";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsTextAreaRow } from "../../components/DetailsTextAreaRow";
import { DetailsTitleHeader } from "../../components/DetailsTitleHeader";

interface Props {
  collection: Collection;
  deleteItemHandler: Function;
  onDone: Function;
}

export function ManageProjectsCollectionDetails({
  collection,
  deleteItemHandler,
  onDone,
}: Props) {
  const theCollection = useRef<Collection | null>(collection);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Collection, collection.id);

    if (original) {
      theCollection.current = await DataStore.save(
        Collection.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  return (
    <Card>
      <DetailsTitleHeader
        title={theCollection.current?.name || ""}
        placeholder={"What's your collection's name?"}
        updateTitleHandler={async (content: string) => {
          await handleUpdateItem({
            name: content,
          });
        }}
      />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Description */}
        <DetailsTextAreaRow
          sectionTitle="Description"
          placeholder=""
          value={theCollection.current?.description || ""}
          description={""}
          updateItemHandler={async (description: string) => {
            await handleUpdateItem({
              description: description,
            });
          }}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
