import { Button, Col, Divider, Row } from "antd";
import React, { useRef } from "react";

import { Card } from "../../components/Card";
import { Category } from "../../models";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsTextAreaRow } from "../../components/DetailsTextAreaRow";
import { DetailsTitleHeader } from "../../components/DetailsTitleHeader";

export interface ManageCategoryDetailsProps {
  category: Category;
  deleteItemHandler: Function;
  onDone: Function;
}

export function ManageCategoryDetails({
  category,
  deleteItemHandler,
  onDone,
}: ManageCategoryDetailsProps) {
  const theCategory = useRef<Category | null>(category);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Category, category.id);

    if (original) {
      theCategory.current = await DataStore.save(
        Category.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  return (
    <Card>
      <DetailsTitleHeader
        title={theCategory.current?.name || ""}
        placeholder={"What's your category's name?"}
        updateTitleHandler={async (content: string) => {
          await handleUpdateItem({
            name: content,
          });
        }}
      />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Description */}
        <DetailsTextAreaRow
          sectionTitle="Description"
          placeholder=""
          value={theCategory.current?.description || ""}
          description={""}
          updateItemHandler={async (description: string) => {
            await handleUpdateItem({
              description: description,
            });
          }}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Col>
      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
