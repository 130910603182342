import * as React from "react";

import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { Col, Row, Select } from "antd";

interface Props {
  title: string;
  defaultValue: string;
  options: (BaseOptionType | DefaultOptionType)[] | undefined;
  valueChangedHandler: Function;
}

export function DetailsSelect({
  title,
  defaultValue,
  options,
  valueChangedHandler,
  ...restProps
}: Props) {
  return (
    <div {...restProps}>
      <Row align="middle" gutter={16}>
        <Col style={{ width: 100 }}>
          <Row>
            <h4 style={{ padding: 0, margin: 0 }}>{title}</h4>
          </Row>
        </Col>
        <Col
          flex="auto"
          style={{
            padding: "5px 0",
          }}
        >
          <Row>
            <Select
              showSearch
              style={{ minWidth: 200, width: "100%" }}
              bordered={false}
              defaultValue={defaultValue}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={options}
              onChange={async (value) => await valueChangedHandler(value)}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
