import { Col, Divider } from "antd";

import { Canvas } from "../../models";
import { Card } from "../../components/Card";

interface Props {
  canvas: Canvas;
  updateItemHandler: Function;
  onClick: Function;
}

export function ManageCanvasesGridCell({
  canvas,
  updateItemHandler,
  onClick,
  ...restProps
}: Props) {
  return (
    <div style={{ padding: 20 }} onClick={() => onClick(canvas)} {...restProps}>
      <div style={{ cursor: "pointer" }}>
        <Card>
          <Col style={{ width: 300, height: 200, overflow: "hidden" }}>
            <h2>{canvas.name}</h2>
            <Divider style={{ marginTop: 0, marginBottom: 16 }} />
          </Col>
        </Card>
      </div>
    </div>
  );
}
