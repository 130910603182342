import { Col, Row } from "antd";

import AdminManageFrameworkTable from "./AdminManageFrameworkTable";
import React from "react";

interface Props {
  setPageTitle: Function;
}

export default function AdminManageFramework({ setPageTitle }: Props) {
  setPageTitle("Admin: Manage The Framework");

  return (
    <Row justify="center">
      <Col style={{ width: "100%", maxWidth: "768px" }}>
        <AdminManageFrameworkTable />
      </Col>
    </Row>
  );
}
