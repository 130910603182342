import * as React from "react";

import { Card } from "./Card";
import { Category } from "../models";
import { Col } from "antd";

interface Props {
  category: Category;
  onClick: Function;
}

export function CategoryPickerCard({ category, onClick }: Props) {
  return (
    <div style={{ padding: 20 }}>
      <div style={{ cursor: "pointer" }}>
        <Card>
          <Col
            style={{ width: 200, minHeight: 200 }}
            onClick={() => onClick(category)}
          >
            <h2>{category.name}</h2>
            <p>{category.description}</p>
          </Col>
        </Card>
      </div>
    </div>
  );
}
