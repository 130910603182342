import * as React from "react";

import { Col, Row } from "antd";

import { Objective } from "../../models";

export interface Props {
  objective: Objective;
  updateItemHandler: Function;
  onClick: Function;
}

export function ObjectivesTableRow({
  objective,
  updateItemHandler,
  onClick,
  ...restProps
}: Props) {
  return (
    <div
      {...restProps}
      className="tb-table-row"
      onClick={() => onClick(objective)}
      style={{ marginTop: 10 }}
    >
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "3px 25px",
          }}
        >
          <h3 style={{ margin: 0 }}>{objective.name || ""}</h3>
        </Col>
      </Row>
    </div>
  );
}
