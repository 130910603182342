import { Col, Row } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import React from "react";

interface Props {
  onClick: Function;
}

export function AddNewItemRow({ onClick, ...restProps }: Props) {
  return (
    <div
      {...restProps}
      className="tb-table-row"
      style={{ marginTop: 5, border: "1px solid #efefef" }}
      onClick={() => onClick()}
    >
      <Row align="middle" style={{ marginTop: "auto" }}>
        <Col flex="auto" style={{ paddingTop: "5px" }}>
          <h4
            style={{
              margin: 0,
              padding: 0,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            <PlusOutlined />
          </h4>
        </Col>
      </Row>
    </div>
  );
}
