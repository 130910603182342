import { Button, Col, Divider, Dropdown, Menu, Row } from "antd";
import { CloseOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import React, { useRef } from "react";

import { Card } from "../../components/Card";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsInputRow } from "../../components/DetailsInputRow";
import { DetailsRichTextEditorRow } from "../../components/DetailsRichTextEditorRow";
import { DetailsTitleInput } from "../../components/DetailsTitleInput";
import { Hypothesis } from "../../models";

interface Props {
  hypothesis: Hypothesis;
  deleteItemHandler: Function;
  onDone: Function;
}

export function IdeaDetails({ hypothesis, deleteItemHandler, onDone }: Props) {
  const theHypothesis = useRef<Hypothesis | null>(hypothesis);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Hypothesis, hypothesis.id);

    if (original) {
      theHypothesis.current = await DataStore.save(
        Hypothesis.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  function moreMenu() {
    return (
      <Menu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(hypothesis.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Card>
      <Row align="top" justify="space-between" gutter={16}>
        <Col flex="auto" style={{ marginLeft: "-8px" }}>
          <DetailsTitleInput
            placeholder="What's your hypothesis?"
            defaultValue={theHypothesis.current?.name || ""}
            description={""}
            updateItemHandler={async (content: string) => {
              await handleUpdateItem({
                name: content,
              });
            }}
          />
        </Col>
        <Col flex="116px" style={{ paddingTop: 16 }}>
          <Row align="top" justify="end" gutter={16}>
            <Col
              flex="50px"
              onClick={async (event) => {
                event.stopPropagation();
              }}
            >
              <Dropdown
                overlay={moreMenu}
                placement="bottomRight"
                arrow
                trigger={["click"]}
              >
                <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
              </Dropdown>
            </Col>

            <Col
              flex="50px"
              onClick={async (event) => {
                event.stopPropagation();
              }}
            >
              <Button
                style={{ borderWidth: 0 }}
                icon={<CloseOutlined />}
                onClick={async () => {
                  onDone();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <br />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Description */}
        <DetailsRichTextEditorRow
          sectionTitle="Description"
          placeholder=""
          value={theHypothesis.current?.description || ""}
          description={""}
          updateItemHandler={async (content: string) => {
            await handleUpdateItem({
              description: content,
            });
          }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
