import { Button, Col, Divider, Row } from "antd";
import React, { useRef } from "react";

import { Card } from "../../components/Card";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsRichTextEditorRow } from "../../components/DetailsRichTextEditorRow";
import { DetailsTitleHeader } from "../../components/DetailsTitleHeader";
import { Objective } from "../../models";

interface Props {
  objective: Objective;
  onDone: Function;
}

export function ObjectiveDetails({ objective, onDone }: Props) {
  const theObjective = useRef<Objective | null>(objective);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Objective, objective.id);
    if (original) {
      theObjective.current = await DataStore.save(
        Objective.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  return (
    <Card>
      <DetailsTitleHeader
        title={theObjective.current?.name || ""}
        placeholder={"What's your objective?"}
        updateTitleHandler={async (content: string) => {
          await handleUpdateItem({
            name: content,
          });
        }}
      />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Description */}
        <DetailsRichTextEditorRow
          sectionTitle="Description"
          placeholder=""
          value={theObjective.current?.description || ""}
          description={""}
          updateItemHandler={async (content: string) => {
            await handleUpdateItem({
              description: content,
            });
          }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
