import {
  ArrowLeftOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, InputNumber, Row } from "antd";
import React, { useState, useEffect, useRef } from "react";

import { Canvas, CanvasBlock, Category, Project } from "../../models";
import { CanvasEditor } from "../../components/CanvasEditor";
import { DetailsTitleInput } from "../../components/DetailsTitleInput";
import { ManageCanvasesGrid } from "./ManageCanvasesGrid";
import { ampli } from "../../ampli";

import { useMatch, useNavigate } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";

interface Props {
  // canvas: Canvas | null | undefined;
  project: Project;
}

export function ManageCanvasDetails({ project }: Props) {
  const navigate = useNavigate();
  const match = useMatch({
    path: "/manage/canvases/:canvasId",
  });

  const theCanvas = useRef<Canvas | null>(null);
  const [canvasBlocks, setCanvasBlocks] = useState(Array<CanvasBlock>);
  const [categories, setCategories] = useState(Array<Category>);

  const [columns, setColumns] = useState(theCanvas.current?.columns || 10);

  useEffect(() => {
    ampli.viewedEditCanvasScreen();

    const loadData = async () => {
      theCanvas.current = await loadCanvas(match?.params.canvasId);
      setColumns(theCanvas.current?.columns || 10);

      const allCategories = await listCategories();
      setCategories(allCategories);

      const theCanvasBlocks = await loadCanvasBlocks(match?.params.canvasId);
      setCanvasBlocks(theCanvasBlocks);
    };
    loadData();
  }, [match?.params.canvasId]);

  useEffect(() => {
    const canvasSubscription = DataStore.observe(Canvas).subscribe(async () => {
      theCanvas.current = await loadCanvas(match?.params.canvasId);
    });

    const canvasesCategoriesSubscription = DataStore.observe(
      Category
    ).subscribe(async () => {
      const allCategories = await listCategories();
      setCategories(allCategories);
    });

    const canvasesCanvasBlocksSubscription = DataStore.observe(
      CanvasBlock
    ).subscribe(async () => {
      // const theCanvasBlocks = await loadCanvasBlocks(match?.params.canvasId);
      // setCanvasBlocks(theCanvasBlocks);
    });
    return () => {
      canvasSubscription.unsubscribe();
      canvasesCategoriesSubscription.unsubscribe();
      canvasesCanvasBlocksSubscription.unsubscribe();
    };
  }, [match?.params.canvasId]);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        theCanvas.current = await loadCanvas(match?.params.canvasId);

        const allCategories = await listCategories();
        setCategories(allCategories);

        // const theCanvasBlocks = await loadCanvasBlocks(match?.params.canvasId);
        // setCanvasBlocks(theCanvasBlocks);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [match?.params.canvasId]);

  async function loadCanvas(canvasId?: string) {
    if (!canvasId) {
      return null;
    }

    return (await DataStore.query(Canvas, canvasId)) || null;
  }

  async function loadCanvasBlocks(canvasId?: string) {
    if (!canvasId) {
      return [];
    }

    return (await DataStore.query(CanvasBlock)).filter(
      (e) => e.canvas?.id === canvasId
    );
  }

  async function listCategories() {
    const categories = (await DataStore.query(Category)).sort(
      (a, b) => a.name?.localeCompare(b.name || "") || 0
    );

    return categories;
  }

  async function handleCreateBlock() {
    if (!theCanvas.current) {
      return;
    }
    const newItem = await DataStore.save(
      new CanvasBlock({ canvas: theCanvas.current })
    );
    setCanvasBlocks(canvasBlocks.concat([newItem]));
    // selectedCanvas.current = newItem;
  }

  async function handleUpdateCanvas(updatedProperties: { [key: string]: any }) {
    if (!theCanvas.current) {
      return;
    }

    const original = await DataStore.query(Canvas, theCanvas.current.id);

    if (original) {
      theCanvas.current = await DataStore.save(
        Canvas.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteCanvas(id: string) {
    const toDelete = await DataStore.query(Canvas, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  async function handleDeleteCanvasBlock(id: string) {
    const toDelete = await DataStore.query(CanvasBlock, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <>
      <Row align="middle" gutter={16} style={{ paddingBottom: 16 }}>
        <Col style={{ height: "100%" }}>
          <Button
            style={{ borderWidth: 0, height: "54px", width: "54px" }}
            icon={<ArrowLeftOutlined />}
            onClick={async () => {
              navigate("/manage/canvases");
            }}
          />
        </Col>

        <Col flex="auto" style={{ height: "54px" }}>
          {theCanvas.current && (
            <DetailsTitleInput
              placeholder="Canvas name..."
              defaultValue={theCanvas.current?.name || ""}
              description={""}
              updateItemHandler={async (content: string) => {
                await handleUpdateCanvas({ name: content });
              }}
            />
          )}
        </Col>

        <Col flex="0 1 auto">
          <Row align="middle">
            <Button
              style={{ borderWidth: 0, height: "54px", width: "54px" }}
              icon={<MinusOutlined />}
              onClick={async () => {
                if (columns > 1) {
                  setColumns(columns - 1);
                  await handleUpdateCanvas({ columns: columns - 1 });
                }
              }}
            />

            <Col flex="1 0 54px">
              <Row
                align="middle"
                style={{
                  padding: "0 16px",
                  margin: "0 16px",
                  backgroundColor: "white",
                  height: "54px",
                  minWidth: "54px",
                  borderRadius: "8px",
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "2em",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  {`${columns} column${columns === 1 ? "" : "s"}`}
                </p>
              </Row>
            </Col>

            <Button
              style={{ borderWidth: 0, height: "54px", width: "54px" }}
              icon={<PlusOutlined />}
              onClick={async () => {
                if (columns < 12) {
                  setColumns(columns + 1);
                  await handleUpdateCanvas({ columns: columns + 1 });
                }
              }}
            />
          </Row>
        </Col>

        <Col flex="0 1 auto">
          <Button
            type="primary"
            style={{ borderWidth: 0, height: "54px" }}
            onClick={async () => {
              await handleCreateBlock();
            }}
          >
            <span
              style={{
                fontSize: "1.5em",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Add Block
            </span>
          </Button>
        </Col>
      </Row>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 12,
          border: "1px solid #f2f2f2",
          overflow: "hidden",
          padding: 5,
        }}
      >
        {theCanvas.current && (
          <CanvasEditor
            canvas={theCanvas.current}
            blocks={canvasBlocks}
            categories={categories}
            hypotheses={undefined}
            columns={columns}
            staticGrid={false}
            project={project}
            openHypothesisHandler={undefined}
            addNewHypothesisHandler={undefined}
            updateHypothesisHandler={undefined}
            deleteHypothesisHandler={undefined}
          />
        )}
      </div>
    </>
  );
}
