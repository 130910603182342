import { Button, Col, Divider, Modal, Row } from "antd";
import {
  Category,
  Experiment,
  ExperimentStatusEnum,
  Hypothesis,
  HypothesisStatusEnum,
  Objective,
  Project,
} from "../../models";
import React, { useEffect, useRef, useState } from "react";

import { Card } from "../../components/Card";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsRichTextEditorRow } from "../../components/DetailsRichTextEditorRow";
import { DetailsSelect } from "../../components/DetailsSelect";
import { DetailsTitleHeader } from "../../components/DetailsTitleHeader";
import { ExperimentDetails } from "../ExperimentDetails/ExperimentDetails";
import { HypothesisExperimentsTable } from "./HypothesisExperimentsTable";
import { ResponsiveModal } from "../../components/ResponsiveModal";
import { ampli } from "../../ampli";
import { titleCase } from "../../utils/Utils";

interface Props {
  project: Project;
  hypothesis: Hypothesis;
  objectives: Objective[];
  categories: Category[];
  deleteItemHandler: Function;
  onDone: Function;
  setHelpContentPath?: Function;
}

export function HypothesisDetails({
  project,
  hypothesis,
  objectives,
  categories,
  deleteItemHandler,
  onDone,
  setHelpContentPath,
}: Props) {
  const theHypothesis = useRef<Hypothesis | null>(hypothesis);

  const selectedExperiment = useRef<Experiment | null>(null);

  const [showExperimentDetailsModal, setShowExperimentDetailsModal] =
    useState(false);

  useEffect(() => {
    const hypothesisSubscription = DataStore.observe(Hypothesis).subscribe(
      async () => {
        theHypothesis.current =
          (await loadHypothesis(theHypothesis.current!.id)) || null;
      }
    );
    return () => {
      hypothesisSubscription.unsubscribe();
    };
  }, [hypothesis.id]);

  async function loadHypothesis(hypothesisId: string) {
    const hypo = await DataStore.query(Hypothesis, hypothesisId);
    return hypo;
  }

  function getHypothesisStatuses() {
    let statuses = Object.values(HypothesisStatusEnum).map((status) => {
      return {
        label: titleCase(status),
        value: status,
      };
    });
    return statuses;
  }

  function getObjectives() {
    let statuses = objectives.map((objective) => {
      return {
        label: objective.name,
        value: objective.id,
      };
    });
    statuses.unshift({ label: "", value: "" });
    return statuses;
  }

  function getCategories() {
    let statuses = categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
    return statuses;
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Hypothesis, hypothesis.id);

    if (original) {
      theHypothesis.current = await DataStore.save(
        Hypothesis.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleCreateExperiment() {
    const original = await DataStore.query(Hypothesis, hypothesis.id);

    if (original) {
      theHypothesis.current = await DataStore.save(
        Hypothesis.copyOf(original, (updated: { [key: string]: any }) => {
          updated.status = HypothesisStatusEnum.TESTING;
        })
      );
    }

    selectedExperiment.current = await DataStore.save(
      new Experiment({
        name: theHypothesis.current?.name + " Experiment",
        project: project,
        parentHypothesis: theHypothesis.current,
        category: theHypothesis.current?.category,
        status: ExperimentStatusEnum.WAITING,
      })
    );

    ampli.newExperimentCreated();
  }

  return (
    <div>
      <Card>
        <DetailsTitleHeader
          title={theHypothesis.current?.name || ""}
          placeholder={"What's your hypothesis?"}
          updateTitleHandler={async (content: string) => {
            await handleUpdateItem({
              name: content,
            });
          }}
        />

        <Row>
          <Col flex="auto">
            <DetailsSelect
              title={"Status"}
              defaultValue={hypothesis.status}
              options={getHypothesisStatuses()}
              valueChangedHandler={async (value: string) => {
                await handleUpdateItem({
                  status: value,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col flex="auto">
            <DetailsSelect
              title={"Objective"}
              defaultValue={hypothesis.objective?.id || ""}
              options={getObjectives()}
              valueChangedHandler={async (value: string) => {
                const selectedObjective = objectives.find(
                  ({ id }) => id === value
                );

                await handleUpdateItem({
                  objective: selectedObjective || null,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col flex="auto">
            <DetailsSelect
              title={"Category"}
              defaultValue={hypothesis.category?.id || ""}
              options={getCategories()}
              valueChangedHandler={async (value: string) => {
                const selectedCategory = categories.find(
                  ({ id }) => id === value
                );
                await handleUpdateItem({
                  category: selectedCategory,
                });
              }}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 32, marginBottom: 8 }}>
          <HypothesisExperimentsTable
            hypothesis={theHypothesis.current!}
            createNewExperimentHandler={async () => {
              await handleCreateExperiment();
              setShowExperimentDetailsModal(true);
            }}
          />
        </Row>

        <Row>
          <Col flex="auto" style={{ padding: 0 }}>
            {/* Description */}
            <DetailsRichTextEditorRow
              sectionTitle="Description"
              placeholder=""
              value={theHypothesis.current?.description || ""}
              description={""}
              updateItemHandler={async (content: string) => {
                await handleUpdateItem({
                  description: content,
                });
              }}
            />
          </Col>
        </Row>

        <Divider />
      </Card>

      <ResponsiveModal
        title={"Experiment Details"}
        open={showExperimentDetailsModal}
        initialHelpContentPath={
          "/experiment-details/experimentDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowExperimentDetailsModal(false)}
      >
        <ExperimentDetails
          experiment={selectedExperiment.current!}
          setModalVisible={setShowExperimentDetailsModal}
        />
      </ResponsiveModal>
    </div>
  );
}
