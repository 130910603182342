import {
  AppstoreOutlined,
  DeleteOutlined,
  HolderOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Article, Chapter } from "../../../models";
import { Button, Col, Dropdown, Menu, Row, Tooltip } from "antd";

import React from "react";

const { SubMenu } = Menu;

interface Props {
  article: Article;
  chapters: Chapter[];
  updateItemHandler: Function;
  deleteItemHandler: Function;
  onClick: Function;
  dragListeners: any;
  dragAttributes: any;
}

export default function AdminManageFrameworkArticleTableRow({
  article,
  chapters,
  updateItemHandler,
  deleteItemHandler,
  onClick,
  dragListeners,
  dragAttributes,
  ...restProps
}: Props) {
  function moreMenu() {
    return (
      <Menu>
        <SubMenu
          key="move-to-chapter"
          icon={<AppstoreOutlined />}
          title="Move to Chapter"
        >
          {chapters.map((eachChapter) => {
            return (
              <Menu.Item
                key={eachChapter.id}
                onClick={async (value) => {
                  const selectedChapter = chapters.find(
                    ({ id }) => id === value.key
                  );
                  if (selectedChapter) {
                    await updateItemHandler({
                      id: article.id,
                      chapter: selectedChapter,
                    });
                  }
                }}
              >
                {eachChapter.title}
              </Menu.Item>
            );
          })}
        </SubMenu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(article.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div {...restProps} className="tb-table-row" style={{ marginTop: 10 }}>
      <Row align="middle">
        <Col flex="44px" {...dragListeners} {...dragAttributes}>
          <Row justify="center">
            <HolderOutlined />
          </Row>
        </Col>
        <Col
          flex="auto"
          style={{
            padding: "3px 25px",
          }}
          onClick={() => onClick(article)}
        >
          <h3 style={{ margin: 0 }}>{article.title || "Article"}</h3>
        </Col>
        <Col flex="50px">
          <Tooltip title="More Options">
            <Dropdown
              overlay={moreMenu}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
            </Dropdown>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
}
