import * as React from "react";

import { Col, Popover, Row } from "antd";
import {
  Experiment,
  ExperimentStatusEnum,
  HypothesisDecisionEnum,
} from "../../models";

import { ExperimentStatusTags } from "./ExperimentStatusTags";

export interface ExperimentTagProps {
  experiment: Experiment;
  fullTitle: Boolean;
  onClick: Function;
}

export function ExperimentTag({
  experiment,
  fullTitle,
  onClick,
}: ExperimentTagProps) {
  const backgroundColorHex = () => {
    switch (experiment.status) {
      case ExperimentStatusEnum.RUNNING:
        return "#2EC7FF";
      case ExperimentStatusEnum.ON_HOLD:
        return "#BABABA";
      case ExperimentStatusEnum.VALIDATED:
      case ExperimentStatusEnum.INVALIDATED:
      case ExperimentStatusEnum.INCONCLUSIVE:
        switch (experiment.decision) {
          case HypothesisDecisionEnum.PERSEVERE:
            return "#4EB665";
          case HypothesisDecisionEnum.PIVOT:
            return "#DF972B";
          case HypothesisDecisionEnum.ABORT:
            return "#B64E4E";
          default:
            return "#BABABA";
        }
      default:
        return "#BABABA";
    }
  };

  const title = () => {
    switch (experiment.status) {
      case ExperimentStatusEnum.WAITING:
        return "Waiting To Start";
      case ExperimentStatusEnum.RUNNING:
        return "Running";
      case ExperimentStatusEnum.ON_HOLD:
        return "On Hold";
      case ExperimentStatusEnum.VALIDATED:
      case ExperimentStatusEnum.INVALIDATED:
      case ExperimentStatusEnum.INCONCLUSIVE:
        if (!experiment.decision) {
          return "Waiting for decision";
        }

        switch (experiment.decision) {
          case HypothesisDecisionEnum.PERSEVERE:
            return "Persevere";
          case HypothesisDecisionEnum.PIVOT:
            return "Pivot";
          case HypothesisDecisionEnum.ABORT:
            return "Abort";
          default:
            return "";
        }

      default:
        return "#Unknown";
    }
  };

  return (
    <Popover
      trigger="hover"
      placement="bottom"
      style={{ borderRadius: 25 }}
      content={
        <Col style={{ borderRadius: 25, maxWidth: 300 }}>
          <h2>{experiment.type?.name} Experiment</h2>
          <h3>Hypothesis:</h3>
          <p>{experiment.name}</p>
          <ExperimentStatusTags experiment={experiment!} />
        </Col>
      }
    >
      <Row
        align="middle"
        style={{
          minWidth: 30,
          height: 30,
          borderRadius: "15px",
          backgroundColor: backgroundColorHex(),
          paddingLeft: 10,
          paddingRight: 10,
          color: "#fff",
          fontWeight: 700,
          cursor: "pointer",
        }}
        onClick={() => onClick(experiment)}
      >
        {fullTitle && title()}
      </Row>
    </Popover>
  );
}
