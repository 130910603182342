import * as React from "react";

import { Col, Row } from "antd";

import { Category } from "../../models";

interface Props {
  category: Category;
  updateItemHandler: Function;
  onClick: Function;
}

export function ManageCategoriesTableRow({
  category,
  updateItemHandler,
  onClick,
  ...restProps
}: Props) {
  return (
    <div
      {...restProps}
      className="tb-table-row"
      onClick={() => onClick(category)}
      style={{ marginTop: 10 }}
    >
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "3px 25px",
          }}
        >
          <h3 style={{ margin: 0 }}>{category.name || ""}</h3>
        </Col>
      </Row>
    </div>
  );
}
