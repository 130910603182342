import {
  CanvasBlock,
  Category,
  Hypothesis,
  HypothesisStatusEnum,
  Project,
} from "../models";
import { Col, Row } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

interface Props {
  project: Project;
  canvasBlock: CanvasBlock;
  hypotheses: Hypothesis[];
  categories: Category[];
  openItemHandler: Function | null | undefined;
  addNewItemHandler: Function | null | undefined;
}

export function CanvasStaticBlockCell({
  project,
  canvasBlock,
  hypotheses,
  categories,
  openItemHandler,
  addNewItemHandler,
  ...restProps
}: Props) {
  const [hoverState, setHoverState] = useState(false);

  function decisionBackgroundColorHex(hypothesis: Hypothesis) {
    switch (hypothesis.status) {
      case HypothesisStatusEnum.TESTING:
        return "#2EC7FF";
      case HypothesisStatusEnum.VALIDATED:
        return "#4EB665";
      case HypothesisStatusEnum.INVALIDATED:
        return "#B64E4E";
      case HypothesisStatusEnum.INCONCLUSIVE:
        return "#DF972B";
      default:
        return "#2D384E";
    }
  }

  return (
    <div
      style={{
        padding: "16px 16px",
        backgroundColor: "white",
        height: "100%",
        borderRadius: 8,
        overflow: "auto",
      }}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <Row>
        <Col flex="auto">
          <h3>{canvasBlock.name || "Block name..."}</h3>
          {canvasBlock.category?.description && (
            <p style={{ fontSize: 12 }}>{canvasBlock.category?.description}</p>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: 0, padding: 0 }}>
        <Col flex="auto">
          {hypotheses
            .sort((a, b) => a.createdAt?.localeCompare(b.createdAt || "") || 0)
            .map((eachItem: Hypothesis) => (
              <div
                key={eachItem.id}
                className="tb-table-row"
                onClick={async () => {
                  openItemHandler?.(eachItem);
                }}
                style={{
                  margin: "0 -8px",
                  padding: "4px 8px",
                  minHeight: 24,
                }}
              >
                <h4
                  style={{
                    margin: 0,
                    color: decisionBackgroundColorHex(eachItem),
                  }}
                >
                  {eachItem.name || ""}
                </h4>
              </div>
            ))}

          <div
            className="tb-table-row"
            style={{
              margin: "8px -8px",
              padding: "4px 8px",
              minHeight: 24,
              border: "1px solid #efefef",
              opacity: hoverState ? 1 : 0,
              transition: "opacity 0.2s",
            }}
            onClick={() => {
              const category = categories.find(
                ({ id }) => id === canvasBlock.category?.id
              );
              if (category) {
                addNewItemHandler?.(category);
              }
            }}
          >
            <h4
              style={{
                margin: 0,
                padding: 0,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              <PlusOutlined />
            </h4>
          </div>
        </Col>
      </Row>
    </div>
  );
}
