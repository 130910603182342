import { Row, Typography } from "antd";

import { Card } from "../../components/Card";
import { Link } from "react-router-dom";
import React from "react";

const { Title, Paragraph } = Typography;

interface Props {
  setPageTitle: Function;
}

export default function Overview({ setPageTitle }: Props) {
  setPageTitle("The Framework - Overview");

  return (
    <Row justify="center">
      <Typography style={{ width: "100%", maxWidth: "768px" }}>
        <Title>Tiny Bets</Title>
        {/* <div> */}
        <Card>
          <Title level={3}>In a nutshell,</Title>
          <Paragraph>
            <b>Tiny Bets helps you run lean experiments </b>
            in an organized and repeatable manner, so
            <b>
              {" "}
              you can validate ideas and hypothesis, learn more and grow faster.
            </b>
          </Paragraph>

          <Paragraph>
            <b>Entrepreneurs</b> and <b>companies</b> have used this method
            successfully to:
            <ul>
              <li>
                validate and invalidate ideas with minimal time and monetary
                investment
              </li>
              <li>test assumptions quickly and accurately</li>
              <li>
                create an experimentation culture in both small and large teams
              </li>
              <li>make data driven decisions</li>
              <li>collaborate across teams</li>
              <li>keep all their learnings in a single place</li>
            </ul>
          </Paragraph>

          <Link to="/framework/getting-started/why-experiment">
            Next: Why Run Experiments?
          </Link>
        </Card>
      </Typography>
    </Row>
  );
}
