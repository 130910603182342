import { IntercomButton } from "./IntercomButton";
import { IntercomLink } from "./IntercomLink";
import Markdown from "markdown-to-jsx";
import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

interface Props {
  markdown: string;
}

export default function MarkdownPreview({ markdown }: Props) {
  return (
    <Markdown
      children={markdown}
      options={{
        overrides: {
          h1: ({ children, ...props }) => <Title level={1}>{children}</Title>,
          h2: ({ children, ...props }) => <Title level={2}>{children}</Title>,
          h3: ({ children, ...props }) => <Title level={3}>{children}</Title>,
          h4: ({ children, ...props }) => <Title level={4}>{children}</Title>,
          h5: ({ children, ...props }) => <Title level={5}>{children}</Title>,
          p: ({ children, ...props }) => <Paragraph>{children}</Paragraph>,
          IntercomButton: {
            component: IntercomButton,
          },
          IntercomLink: {
            component: IntercomLink,
          },
        },
      }}
    />
  );
}
