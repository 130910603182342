import { CanvasBlock, Category } from "../models";
import { Col, Divider, Input, Row, Select } from "antd";

import { DetailsTitleInput } from "./DetailsTitleInput";
import { HolderOutlined } from "@ant-design/icons";
import { MouseEventHandler } from "react";
import { useDebouncedCallback } from "use-debounce";

const { TextArea } = Input;
interface Props {
  canvasBlock: CanvasBlock;
  categories: Category[];
  updateItemHandler: Function;
  deleteItemHandler: Function;
}

export function CanvasEditableBlockCell({
  canvasBlock,
  categories,
  updateItemHandler,
  deleteItemHandler,
  ...restProps
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      console.log("debounce");
      await updateItemHandler(updatedProperties);
    },
    1000
  );

  function getCategories() {
    const categoryOptions = categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
    return categoryOptions;
  }

  return (
    <div
      style={{
        padding: "8px 8px",
        backgroundColor: "white",
        height: "100%",
        borderRadius: 8,
        border: "1px solid #f2f2f2",
        overflow: "auto",
      }}
    >
      <Row align="top" justify="start">
        <Col
          flex="0 1 30px"
          style={{ padding: "8px 8px 8px 8px", cursor: "pointer" }}
          className="gs-drag-handler"
        >
          <HolderOutlined />
        </Col>
        <Col className="tb-title-input" style={{ width: "calc(100% - 30px)" }}>
          <TextArea
            style={{
              paddingLeft: "8px",
              paddingRight: "8px",
              fontSize: 18,
              fontWeight: 700,
            }}
            autoSize
            placeholder={"Block name..."}
            defaultValue={canvasBlock.name || ""}
            onChange={(e) => {
              debouncedPropertiesUpdate({
                id: canvasBlock.id,
                name: e.target.value,
              });
            }}
            onBlur={async (e) => {
              await updateItemHandler({
                id: canvasBlock.id,
                name: e.target.value,
              });
            }}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20, padding: 0 }}>
        <Col flex="auto">
          <Row>
            <h5 style={{ paddingLeft: 8 }}>Category</h5>
          </Row>

          <Row className="tb-select">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Block's category..."}
              defaultValue={canvasBlock.category?.id}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLowerCase().indexOf(input.toLowerCase()) ||
                  0) >= 0
              }
              options={getCategories()}
              onChange={async (value) => {
                const selectedCategory = categories.find(
                  ({ id }) => id === value
                );
                await updateItemHandler({
                  id: canvasBlock.id,
                  category: selectedCategory,
                });
              }}
            />
          </Row>

          <Row className="tb-title-input">
            <p style={{ padding: 8 }}>{canvasBlock.category?.description}</p>
            {/* <TextArea
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: 14,
              }}
              autoSize
              placeholder={"Category description..."}
              defaultValue={canvasBlock.category?.description || ""}
              onChange={(e) => {
                debouncedPropertiesUpdate({
                  id: canvasBlock.id,
                  description: e.target.value,
                });
              }}
              onBlur={async (e) => {
                await updateItemHandler({
                  id: canvasBlock.id,
                  description: e.target.value,
                });
              }}
            /> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
