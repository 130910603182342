import {
  AppstoreOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Tooltip } from "antd";
import { Collection, Project } from "../../models";

import React from "react";

const { SubMenu } = Menu;

interface Props {
  project: Project;
  collections: Collection[];
  updateItemHandler: Function;
  deleteItemHandler: Function;
  onClick: Function;
  dragListeners: any;
  dragAttributes: any;
}

export default function ManageProjectsProjectTableRow({
  project,
  collections,
  updateItemHandler,
  deleteItemHandler,
  onClick,
  dragListeners,
  dragAttributes,
  ...restProps
}: Props) {
  function moreMenu() {
    return (
      <Menu>
        <SubMenu
          key="move-to-collection"
          icon={<AppstoreOutlined />}
          title="Move to Collection"
        >
          {collections.map((eachCollection) => {
            return (
              <Menu.Item
                key={eachCollection.id}
                onClick={async (value) => {
                  const selectedCollection = collections.find(
                    ({ id }) => id === value.key
                  );
                  if (selectedCollection) {
                    await updateItemHandler({
                      id: project.id,
                      collection: selectedCollection,
                    });
                  }
                }}
              >
                {eachCollection.name}
              </Menu.Item>
            );
          })}
        </SubMenu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(project.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div {...restProps} className="tb-table-row" style={{ marginTop: 10 }}>
      <Row align="middle">
        {/* <Col flex="44px" {...dragListeners} {...dragAttributes}>
          <Row justify="center">
            <HolderOutlined />
          </Row>
        </Col> */}
        <Col
          flex="auto"
          style={{
            padding: "3px 25px 3px 60px",
          }}
          onClick={() => onClick(project)}
        >
          <h3 style={{ margin: 0 }}>{project.name || "Project"}</h3>
        </Col>
        <Col flex="50px">
          <Tooltip title="More Options">
            <Dropdown
              overlay={moreMenu}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
            </Dropdown>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
}
