import { Button, Col, Empty, Modal, Row } from "antd";
import { Objective, Project } from "../../models";
import React, { useRef, useState } from "react";

import { DataStore } from "@aws-amplify/datastore";
import { ObjectiveDetails } from "./ObjectiveDetails";
import { ObjectivesTableRow } from "./ObjectivesTableRow";
import { ResponsiveModal } from "../../components/ResponsiveModal";
import { ampli } from "../../ampli";

interface Props {
  project: Project;
  objectives: Objective[];
}

export function ObjectivesTable({ project, objectives }: Props) {
  const selectedObjective = useRef<Objective | null>(null);
  const [showObjectiveDetailsModal, setShowObjectiveDetailsModal] =
    useState(false);

  async function handleCreateItem() {
    const newItem = await DataStore.save(new Objective({ project: project }));
    selectedObjective.current = newItem;
    setShowObjectiveDetailsModal(true);
    ampli.newObjectiveCreated();
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Objective, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Objective.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Objective, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div>
      {objectives.length > 0 && (
        <Row>
          <Col flex="auto">
            {objectives.map((eachObjective) => (
              <ObjectivesTableRow
                key={eachObjective.id}
                objective={eachObjective}
                updateItemHandler={async (updatedProperties: {
                  [key: string]: any;
                }) => {
                  await handleUpdateItem(updatedProperties);
                }}
                onClick={async (objective: Objective) => {
                  selectedObjective.current = objective;
                  setShowObjectiveDetailsModal(true);
                }}
              />
            ))}
          </Col>
        </Row>
      )}

      {(!objectives || objectives.length === 0) && (
        <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Col flex="auto">
            <Empty description="">
              <Button type="primary" shape="round" onClick={handleCreateItem}>
                Create Your First Objective
              </Button>
            </Empty>
          </Col>
        </Row>
      )}

      <br />

      {objectives.length > 0 && (
        <Row
          align="middle"
          justify="center"
          className="tb-table-row"
          onClick={handleCreateItem}
        >
          <span style={{ margin: 0 }}>New Objective</span>
        </Row>
      )}

      <ResponsiveModal
        title={"Objective Details"}
        open={showObjectiveDetailsModal}
        initialHelpContentPath={
          "/objective-details/objectiveDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowObjectiveDetailsModal(false)}
        deleteItemHandler={async (itemId: string) => {
          await handleDeleteItem(selectedObjective.current!.id);
          setShowObjectiveDetailsModal(false);
        }}
      >
        <ObjectiveDetails
          objective={selectedObjective.current!}
          onDone={() => setShowObjectiveDetailsModal(false)}
        />
      </ResponsiveModal>
    </div>
  );
}
