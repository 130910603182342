import { Col, Empty, Modal, Row } from "antd";
import { Learning, Project } from "../../models";
import React, { useEffect, useRef, useState } from "react";

import { DataStore } from "@aws-amplify/datastore";
import { LearningDetails } from "./LearningDetails";
import { LearningsTableRow } from "./LearningsTableRow";
import { ResponsiveModal } from "../../components/ResponsiveModal";

interface Props {
  learnings: Learning[];
}

export function LearningsTable({ learnings }: Props) {
  const selectedLearning = useRef<Learning | null>(null);
  const [showLearningDetailsModal, setShowLearningDetailsModal] =
    useState(false);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Learning, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Learning.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Learning, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div style={{ paddingBottom: 16 }}>
      {learnings.length === 0 && (
        <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Col flex="auto">
            <Empty description="You don't have any learnings yet. All your learnings from your experiments will be collected here.">
              {/* <Button type="primary" shape="round" onClick={handleCreateItem}>
                Add Your First Idea
              </Button> */}
            </Empty>
          </Col>
        </Row>
      )}

      {learnings.map((eachItem: Learning) => (
        <LearningsTableRow
          learning={eachItem}
          updateItemHandler={async (updatedProperties: {
            [key: string]: any;
          }) => {
            await handleUpdateItem(updatedProperties);
          }}
          onClick={async (learning: Learning) => {
            selectedLearning.current = learning;
            setShowLearningDetailsModal(true);
          }}
          // openLearningHandler={async (learning: Learning) => {
          //   console.log("learning", learning);
          //   selectedLearning.current = learning;
          //   setShowLearningDetailsModal(true);
          // }}
        />
      ))}

      <ResponsiveModal
        title={"Learning Details"}
        open={showLearningDetailsModal}
        initialHelpContentPath={
          "/learning-details/learningDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowLearningDetailsModal(false)}
        deleteItemHandler={async () => {
          await handleDeleteItem(selectedLearning.current!.id);
          setShowLearningDetailsModal(false);
        }}
      >
        <LearningDetails
          learning={selectedLearning.current!}
          onDone={() => setShowLearningDetailsModal(false)}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteItem(itemId);
            setShowLearningDetailsModal(false);
          }}
        />
      </ResponsiveModal>
    </div>
  );
}
