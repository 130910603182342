import * as React from "react";

import { Col, Row } from "antd";

import { Experiment } from "../../models";
import { ExperimentStatusTags } from "../ExperimentDetails/ExperimentStatusTags";

interface Props {
  experiment: Experiment;
  updateItemHandler: Function;
  onClick: Function;
}

export function ExperimentsTableRow({
  experiment,
  updateItemHandler,
  onClick,
  ...restProps
}: Props) {
  return (
    <div
      {...restProps}
      className="tb-table-row"
      onClick={() => onClick(experiment)}
      style={{ marginTop: 10 }}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{
          padding: "8px 25px",
        }}
      >
        <Col>
          <h3 style={{ margin: 0 }}>{experiment.name || ""}</h3>
        </Col>
        <Col>
          <Row>
            <ExperimentStatusTags experiment={experiment} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
