import { Button, Col, Empty, Modal, Row } from "antd";
import {
  Category,
  Experiment,
  Hypothesis,
  HypothesisStatusEnum,
} from "../models";
import React, { useEffect, useRef, useState } from "react";

import { Card } from "./Card";
import { CategoryPicker } from "./CategoryPicker";
import { DataStore } from "@aws-amplify/datastore";
import { ExperimentDetails } from "../screens/ExperimentDetails/ExperimentDetails";
import { ExperimentStatusEnum } from "../models";
import { ExperimentTag } from "../screens/ExperimentDetails/ExperimentTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Project } from "../models";
import { ProjectDetails } from "./ProjectDetails";
import { ResponsiveModal } from "./ResponsiveModal";
import { ampli } from "../ampli";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {
  project: Project;
  categories: Category[];
  experiments: Experiment[];
}

export function ProjectCard({ project, categories, experiments }: Props) {
  const [showCategoryPickerModal, setShowCategoryPickerModal] = useState(false);

  const selectedExperiment = useRef<Experiment | null>(null);
  const [showExperimentDetailsModal, setShowExperimentDetailsModal] =
    useState(false);

  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);

  async function handleDeleteProject(id: string) {
    const toDelete = await DataStore.query(Project, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  async function handleCreateExperiment(category: Category) {
    const newHypothesis = await DataStore.save(
      new Hypothesis({
        status: HypothesisStatusEnum.TESTING,
        project: project,
        category: category,
      })
    );

    selectedExperiment.current = await DataStore.save(
      new Experiment({
        project: project,
        parentHypothesis: newHypothesis,
        category: category,
        status: ExperimentStatusEnum.WAITING,
      })
    );

    console.log(
      "creation selectedExperiment.current",
      selectedExperiment.current
    );

    ampli.newExperimentCreated();
  }

  return (
    <div style={{ marginBottom: `30px` }}>
      <Card>
        <Col>
          <Row align="middle" justify="space-between">
            <h5 style={{ color: "grey" }}>PROJECT OVERVIEW</h5>

            {experiments.length > 0 && (
              <Button
                type="default"
                shape="round"
                onClick={async () => {
                  setShowCategoryPickerModal(true);
                }}
              >
                New Experiment
              </Button>
            )}
          </Row>
          <Row
            align="middle"
            justify="space-between"
            gutter={20}
            style={{ marginBottom: 10 }}
          >
            <Button
              type="text"
              style={{ padding: 0 }}
              onClick={async () => {
                setShowProjectDetailsModal(true);
              }}
            >
              <Col>
                {project.name && project.name?.length && (
                  <h2 style={{ margin: 0 }}>{project.name}</h2>
                )}
                {(!project.name || !project.name?.length) && (
                  <h2 style={{ margin: 0, color: "#A4A4A4" }}>
                    Click to add a project name...
                  </h2>
                )}
              </Col>
            </Button>
          </Row>

          {experiments.length > 0 && (
            <Row align="middle">
              <Row
                align="middle"
                style={{
                  minWidth: 30,
                  height: 30,
                  borderRadius: 15,
                  backgroundColor: "#4E77B6",
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                Started
              </Row>

              {experiments.map((eachExperiment, index) => (
                <Col key={index}>
                  <Row align="middle">
                    <Col style={{ padding: 16 }}>
                      <FontAwesomeIcon icon={faArrowRight} color="#666666" />
                    </Col>

                    <ExperimentTag
                      experiment={eachExperiment}
                      fullTitle={index === experiments.length - 1}
                      onClick={async (exp: Experiment) => {
                        selectedExperiment.current = exp;
                        setShowExperimentDetailsModal(true);
                      }}
                    />
                  </Row>
                </Col>
              ))}
            </Row>
          )}

          {(!experiments || experiments.length === 0) && (
            <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Col flex="auto">
                <Empty description="">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={async () => {
                      setShowCategoryPickerModal(true);
                    }}
                  >
                    Create Your First Experiment
                  </Button>
                </Empty>
              </Col>
            </Row>
          )}
        </Col>
      </Card>

      <ResponsiveModal
        title={"Category Picker"}
        open={showCategoryPickerModal}
        initialHelpContentPath={"/category-picker/categoryPickerScreenHelp.md"}
        closeModalHandler={() => setShowCategoryPickerModal(false)}
      >
        <CategoryPicker
          categories={categories}
          selectedCategoryHandler={async (category: Category) => {
            setShowCategoryPickerModal(false);
            await handleCreateExperiment(category);
            setShowExperimentDetailsModal(true);
          }}
          setModalVisible={setShowCategoryPickerModal}
        />
      </ResponsiveModal>

      <ResponsiveModal
        title={"Experiment Details"}
        open={showExperimentDetailsModal}
        initialHelpContentPath={
          "/experiment-details/experimentDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowExperimentDetailsModal(false)}
      >
        <ExperimentDetails
          experiment={selectedExperiment.current!}
          setModalVisible={setShowExperimentDetailsModal}
        />
      </ResponsiveModal>

      <ResponsiveModal
        title={"Project Details"}
        open={showProjectDetailsModal}
        initialHelpContentPath={"/project-details/projectDetailsScreenHelp.md"}
        closeModalHandler={() => setShowProjectDetailsModal(false)}
        deleteItemHandler={async (itemId: string) => {
          await handleDeleteProject(project.id);
          setShowProjectDetailsModal(false);
        }}
      >
        <ProjectDetails
          project={project}
          onDone={async () => {
            setShowProjectDetailsModal(false);
          }}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteProject(itemId);
            setShowProjectDetailsModal(false);
          }}
        />
      </ResponsiveModal>
    </div>
  );
}
