import * as React from "react";

import { Col, Row } from "antd";

interface Props {
  title: string;
  onClick: Function;
}

export function GenericItemTableRow({ title, onClick, ...restProps }: Props) {
  return (
    <div
      {...restProps}
      className="tb-table-row"
      onClick={() => onClick()}
      style={{ marginTop: 5, width: "100%", border: "1px solid #efefef" }}
    >
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "3px 16px",
          }}
        >
          <h3 style={{ margin: 0 }}>{title}</h3>
        </Col>
      </Row>
    </div>
  );
}
