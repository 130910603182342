import { Article, Chapter } from "../../../models";
import { Col, Row, Modal } from "antd";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import AdminManageFrameworkChapterTableRow from "./AdminManageFrameworkChapterTableRow";
import AdminManageFrameworkArticleTableRow from "./AdminManageFrameworkArticleTableRow";

import React, { useEffect, useState, useRef } from "react";
import { AdminManageFrameworkChapterDetails } from "./AdminManageFrameworkChapterDetails";
import { AdminManageFrameworkArticleDetails } from "./AdminManageFrameworkArticleDetails";
import { ResponsiveModal } from "../../../components/ResponsiveModal";

interface Props {}

export default function AdminManageFrameworkTable({ ...restProps }: Props) {
  const [chapters, setChapters] = useState(Array<Chapter>);
  const selectedChapter = useRef<Chapter | null>(null);
  const [showChapterDetailsModal, setShowChapterDetailsModal] = useState(false);

  const [articlesByChapter, setArticlesByChapter] = useState<
    Map<string, Array<Article>>
  >(new Map());
  const selectedArticle = useRef<Article | null>(null);
  const [showArticleDetailsModal, setShowArticleDetailsModal] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const allChapters = await listChapters();
      setChapters(allChapters);

      const allArticles = await listArticles();
      setArticlesByChapter(allArticles);
    };
    loadData();
  }, []);

  useEffect(() => {
    const subscription = DataStore.observe(Chapter).subscribe(async () => {
      const allCollections = await listChapters();
      setChapters(allCollections);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = DataStore.observe(Article).subscribe(async () => {
      const allCollections = await listArticles();
      setArticlesByChapter(allCollections);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const allCollections = await listChapters();
        setChapters(allCollections);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  async function listChapters() {
    const collections = await DataStore.query(Chapter, Predicates.ALL, {
      sort: (s) => s.rank(SortDirection.ASCENDING),
    });

    return collections;
  }

  async function handleCreateChapter() {
    const newItem = await DataStore.save(new Chapter({ path: "/", rank: "m" }));
    selectedChapter.current = newItem;
    setShowChapterDetailsModal(true);
  }

  async function handleUpdateChapter(updatedProperties: {
    [key: string]: any;
  }) {
    const original = await DataStore.query(Chapter, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Chapter.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function listArticles() {
    const articles = await DataStore.query(Article, Predicates.ALL, {
      sort: (s) => s.rank(SortDirection.ASCENDING),
    });

    let articlesByChapter = new Map<string, Array<Article>>();
    for (const article of articles) {
      if (!article.chapter) {
        continue;
      }

      if (!articlesByChapter.has(article.chapter.id)) {
        articlesByChapter.set(article.chapter.id, []);
      }
      articlesByChapter.get(article.chapter.id)?.push(article);
    }
    return articlesByChapter;
  }

  async function handleCreateArticle(forChapter: Chapter) {
    const newItem = await DataStore.save(
      new Article({
        title: "",
        shortTitle: "",
        text: "",
        path: "",
        rank: "m",
        chapter: forChapter,
      })
    );

    // await DataStore.save(
    //   Chapter.copyOf(forChapter, (updated: { [key: string]: any }) => {
    //     if (!updated.articles) {
    //       updated.articles = [];
    //     }
    //     updated.articles.push(newItem);
    //   })
    // );

    selectedArticle.current = newItem;
    setShowArticleDetailsModal(true);
  }

  async function handleUpdateArticle(updatedProperties: {
    [key: string]: any;
  }) {
    const original = await DataStore.query(Article, updatedProperties.id);
    if (original) {
      await DataStore.save(
        Article.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  async function handleDeleteChapter(id: string) {
    const toDelete = await DataStore.query(Chapter, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  async function handleDeleteArticle(id: string) {
    const toDelete = await DataStore.query(Article, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div>
      <Row justify="center">
        <Col style={{ width: "100%", maxWidth: "768px" }}>
          <h2>Table of Contents</h2>

          {chapters.map((eachChapter) => (
            <div style={{ marginTop: "30px" }}>
              <AdminManageFrameworkChapterTableRow
                key={eachChapter.id}
                chapter={eachChapter}
                updateItemHandler={async (updatedProperties: {
                  [key: string]: any;
                }) => {
                  await handleUpdateChapter(updatedProperties);
                }}
                deleteItemHandler={async (itemId: string) => {
                  await handleDeleteChapter(itemId);
                }}
                onClick={async (chapter: Chapter) => {
                  selectedChapter.current = chapter;
                  setShowChapterDetailsModal(true);
                }}
                dragListeners={undefined}
                dragAttributes={undefined}
              />

              {articlesByChapter.get(eachChapter.id)?.map((eachArticle) => (
                <AdminManageFrameworkArticleTableRow
                  key={eachArticle.id}
                  article={eachArticle!}
                  chapters={chapters}
                  updateItemHandler={async (updatedProperties: {
                    [key: string]: any;
                  }) => {
                    await handleUpdateArticle(updatedProperties);
                  }}
                  deleteItemHandler={async (itemId: string) => {
                    await handleDeleteArticle(itemId);
                  }}
                  onClick={async (article: Article) => {
                    selectedArticle.current = article;
                    setShowArticleDetailsModal(true);
                  }}
                  dragListeners={undefined}
                  dragAttributes={undefined}
                />
              ))}

              <Row
                key={`${eachChapter.id}-new-item`}
                align="middle"
                justify="center"
                className="tb-table-row"
                style={{ marginTop: "10px" }}
                onClick={async () => {
                  await handleCreateArticle(eachChapter);
                }}
              >
                <span style={{ margin: 0 }}>New Article</span>
              </Row>
            </div>
          ))}

          <Row
            align="middle"
            justify="center"
            className="tb-table-row"
            style={{ marginTop: "30px" }}
            onClick={handleCreateChapter}
          >
            <span style={{ margin: 0 }}>New Chapter</span>
          </Row>
        </Col>
      </Row>

      <ResponsiveModal
        title={"Chapter Details"}
        open={showChapterDetailsModal}
        closeModalHandler={() => setShowChapterDetailsModal(false)}
      >
        <AdminManageFrameworkChapterDetails
          chapter={selectedChapter.current!}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteChapter(itemId);
            setShowChapterDetailsModal(false);
          }}
          onDone={() => setShowChapterDetailsModal(false)}
        />
      </ResponsiveModal>

      <ResponsiveModal
        title={"Chapter Details"}
        open={showArticleDetailsModal}
        closeModalHandler={() => setShowArticleDetailsModal(false)}
      >
        <AdminManageFrameworkArticleDetails
          article={selectedArticle.current!}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteChapter(itemId);
            setShowArticleDetailsModal(false);
          }}
          onDone={() => setShowArticleDetailsModal(false)}
        />
      </ResponsiveModal>
    </div>
  );
}
