import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

OtpField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  numInputs: PropTypes.number.isRequired,
  onChangeRegex: PropTypes.instanceOf(RegExp),
  labelText: PropTypes.string,
  classNames: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  separator: PropTypes.node,
  isTypeNumber: PropTypes.bool,
  hasErrored: PropTypes.bool,
  inputProps: PropTypes.object,
};

export default function OtpField({
  value = "",
  onChange,
  numInputs = 6,
  onChangeRegex,
  classNames = "otp-field",
  autoComplete = "off",
  autoFocus = false,
  separator,
  isTypeNumber = false,
  hasErrored = false,
  inputProps,
}) {
  const defaultValues = generateDefaultValues(numInputs, value.split(""));
  const [values, setValues] = useState(defaultValues);
  const [focusInput, setFocusInput] = useState(autoFocus ? 0 : null);
  const inputRefs = useRef([]);

  useEffect(() => {
    setValues(defaultValues);
  }, [value, numInputs]);

  useEffect(() => {
    const input = inputRefs.current[focusInput];
    !!input &&
      input.focus({
        cursor: "end",
      });
  }, [focusInput]);

  const handleChange = (inputValue, index) => {
    if (!!onChangeRegex && !isValid(onChangeRegex, inputValue)) return;

    const newValues = [...values];

    let j = 0;
    values.forEach((element, i) => {
      const isNewValuesAndAnyEmptyInput = !element && !!inputValue;
      const isActionRemoveInputValue = !!element && index === i && !inputValue;

      if (isNewValuesAndAnyEmptyInput) {
        newValues[i] = inputValue.split("")[!values[index] ? j : j + 1] || "";
        j++;
      } else if (isActionRemoveInputValue) {
        newValues[i] = "";
      }
    });

    if (inputValue) {
      focusOnNextInput(newValues, values, setFocusInput);
    }

    onChange(newValues.join(""));
  };

  const onKeyPressed = (key, index) => {
    switch (key) {
      case "Backspace":
      case "ArrowLeft":
        return setFocusInput(index - 1);
      case "ArrowRight":
        return setFocusInput(index + 1);
      default:
        return;
    }
  };

  return (
    <div
      className={`${classNames} ${
        hasErrored ? "ant-form-item-has-error" : ""
      }`.trim()}
    >
      {values.map((element, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            type={isTypeNumber ? "number" : "text"}
            value={element}
            onChange={(e) => handleChange(e.target.value, index)}
            style={{
              marginLeft: index === 0 ? 0 : "5px",
              marginRight: index === values.length - 1 ? 0 : "5px",
              width: "52px",
              height: "52px",
              fontSize: 30,
              fontWeight: 700,
              paddingLeft: 0,
              paddingRight: 0,
              borderRadius: 6,
              textAlign: "center",
            }}
            autoComplete={index === 0 ? autoComplete : "off"}
            onKeyDown={({ key }) => onKeyPressed(key, index)}
            {...inputProps}
          />
          {index === (values.length - 2) / 2 && separator && separator}
        </div>
      ))}
    </div>
  );
}

const generateDefaultValues = (length, inputValues) => {
  if (length < 1) return [];
  return Array.from({ length }, (_, i) => inputValues[i] || "");
};

const isValid = (regex, value) => regex.test(value);

const focusOnNextInput = (newValues, currentValues, setFocusInput) => {
  for (let [i, element] of newValues.entries()) {
    if (!element || i === currentValues.length - 1) {
      setFocusInput(i);
      break;
    }
  }
};
