import { Col, Layout } from "antd";
import React, { useEffect } from "react";

import MarkdownPreview from "./MarkdownPreview";
import generalHelp from "../content/help/generalHelp.md";

const { Sider } = Layout;

interface Props {
  helpOpen: boolean;
  contentPath?: string;
}

export function HelpModalSidebar({ helpOpen, contentPath }: Props) {
  const [helpContent, setHelpContent] = React.useState("Loading...");

  async function loadHelpMarkdown() {
    let path = contentPath;
    if (path?.charAt(0) === "/") {
      path = path.substring(1);
    }

    let fetchResponse;
    try {
      const file = await import(`../content/help/${path}`);
      fetchResponse = await fetch(file.default);
    } catch (error) {
      fetchResponse = await fetch(generalHelp);
    }

    const markdownText = await fetchResponse.text();
    setHelpContent(markdownText);
  }

  useEffect(() => {
    const load = async () => {
      await loadHelpMarkdown();
    };
    load();
  }, [contentPath]);

  function getHelpSidebarWidth() {
    const width = window.innerWidth;
    return Math.min(Math.max(width * 0.25, 200), 300);
  }

  return (
    <Sider
      collapsed={!helpOpen}
      collapsedWidth={0}
      width={getHelpSidebarWidth()}
      style={{
        backgroundColor: "transparent",
        borderLeft: helpOpen ? "1px solid #eeeeee" : "none",
        overflow: "scroll",
      }}
    >
      <Col
        style={{
          backgroundColor: "transparent",
          width: getHelpSidebarWidth(),
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          paddingTop: 20,
          position: "absolute",
        }}
      >
        <div style={{ width: getHelpSidebarWidth() - 40 }}>
          <MarkdownPreview markdown={helpContent || "Loading..."} />
        </div>
      </Col>
    </Sider>
  );
}
