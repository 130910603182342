import { Experiment, Learning } from "../../models";
import { Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { AddNewItemRow } from "../../components/AddNewItemRow";
import { DataStore } from "@aws-amplify/datastore";
import { GenericItemTableRow } from "../../components/GenericItemTableRow";
import { LearningDetails } from "../Learnings/LearningDetails";
import { ResponsiveModal } from "../../components/ResponsiveModal";

export interface ExperimentLearningsTableProps {
  experiment: Experiment;
}

export function ExperimentLearningsTable({
  experiment,
}: ExperimentLearningsTableProps) {
  const [learnings, setLearnings] = useState<Array<Learning>>([]);

  const selectedLearning = useRef<Learning | null>(null);
  const [showLearningDetailsModal, setShowLearningDetailsModal] =
    useState(false);

  useEffect(() => {
    const loadData = async () => {
      const theLearnings = await loadLearnings(experiment.id);
      setLearnings(theLearnings);
    };
    loadData();
  }, [experiment.id]);

  useEffect(() => {
    const projectsExperimentsSubscription = DataStore.observe(
      Learning
    ).subscribe(async () => {
      const theLearnings = await loadLearnings(experiment.id);
      setLearnings(theLearnings);
    });
    return () => {
      projectsExperimentsSubscription.unsubscribe();
    };
  }, [experiment.id]);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const theLearnings = await loadLearnings(experiment.id);
        setLearnings(theLearnings);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [experiment.id]);

  async function loadLearnings(experimentId?: string) {
    if (!experimentId) {
      return [];
    }
    return (await DataStore.query(Learning)).filter(
      (l) => l.experiment?.id === experimentId
    );
  }

  async function handleCreateItem() {
    const timestamp = new Date();

    selectedLearning.current = await DataStore.save(
      new Learning({
        timestamp: timestamp.toISOString(),
        project: experiment.project,
        experiment: experiment,
      })
    );

    setShowLearningDetailsModal(true);
  }

  async function handleDeleteItem(id: string) {
    const toDelete = await DataStore.query(Learning, id);
    if (toDelete) {
      await DataStore.delete(toDelete);
    }
  }

  return (
    <div style={{ paddingBottom: 16 }}>
      <Row>
        <h3>Learnings</h3>
      </Row>

      {learnings.map((eachItem: Learning) => (
        <GenericItemTableRow
          key={eachItem.id}
          title={eachItem.name || ""}
          onClick={async () => {
            selectedLearning.current = eachItem;
            setShowLearningDetailsModal(true);
          }}
        />
      ))}

      <AddNewItemRow
        onClick={() => {
          handleCreateItem();
        }}
      />

      <ResponsiveModal
        title={"Learning Details"}
        open={showLearningDetailsModal}
        initialHelpContentPath={
          "/learning-details/learningDetailsScreenHelp.md"
        }
        closeModalHandler={() => {
          setShowLearningDetailsModal(false);
        }}
        deleteItemHandler={async () => {
          await handleDeleteItem(selectedLearning.current!.id);
          setShowLearningDetailsModal(false);
        }}
      >
        <LearningDetails
          learning={selectedLearning.current!}
          onDone={() => setShowLearningDetailsModal(false)}
          deleteItemHandler={async (itemId: string) => {
            await handleDeleteItem(itemId);
            setShowLearningDetailsModal(false);
          }}
        />
      </ResponsiveModal>
    </div>
  );
}
