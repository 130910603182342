import { Button, Col, Empty, Modal, Row } from "antd";
import {
  Category,
  Hypothesis,
  HypothesisStatusEnum,
  Objective,
  Project,
} from "../../models";

import { AddNewItemCard } from "../../components/AddNewItemCard";
import { HypothesesGridCell } from "./HypothesesGridCell";
import React from "react";

interface Props {
  project: Project;
  hypotheses: Hypothesis[];
  objectives: Objective[];
  categories: Category[];
  openHypothesisHandler: Function;
  addNewHypothesisHandler: Function;
  updateHypothesisHandler: Function;
  deleteHypothesisHandler: Function;
}

export function HypothesesGrid({
  project,
  hypotheses,
  objectives,
  categories,
  openHypothesisHandler,
  addNewHypothesisHandler,
  updateHypothesisHandler,
  deleteHypothesisHandler,
}: Props) {
  return (
    <div
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        // margin: "-20px",
      }}
    >
      {(!hypotheses || hypotheses.length === 0) && (
        <Row style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Col flex="auto">
            <Empty description="">
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  addNewHypothesisHandler();
                }}
              >
                Add Your First Hypothesis
              </Button>
            </Empty>
          </Col>
        </Row>
      )}

      {hypotheses.length > 0 && (
        <Row>
          <AddNewItemCard
            onClick={() => {
              addNewHypothesisHandler();
            }}
          />

          {hypotheses.map((eachHypothesis) => (
            <HypothesesGridCell
              key={eachHypothesis.id}
              hypothesis={eachHypothesis}
              updateItemHandler={async (updatedProperties: {
                [key: string]: any;
              }) => {
                await updateHypothesisHandler(updatedProperties);
              }}
              onClick={async (hypothesis: Hypothesis) => {
                openHypothesisHandler(hypothesis);
              }}
            />
          ))}
        </Row>
      )}
    </div>
  );
}
