import React from "react";
import { Typography } from "antd";

const { Link } = Typography;

interface Props {
  title: string;
  type: "article" | "tour" | "survey";
  id: number;
}

export function IntercomLink({ title, type, id }: Props) {
  return (
    <Link
      onClick={() => {
        switch (type) {
          case "article":
            window.Intercom("showArticle", id);
            break;
          case "tour":
            window.Intercom("startTour", id);
            break;
          case "survey":
            window.Intercom("startSurvey", id);
            break;
        }
      }}
    >
      {title}
    </Link>
  );
}
