import * as React from "react";

import { Col, Row } from "antd";

import RichTextEditor from "./RichTextEditor/RichTextEditor";

export interface Props {
  sectionTitle: string;
  placeholder: string;
  value: string;
  description: string;
  updateItemHandler: Function;
}

export function DetailsRichTextEditorRow({
  sectionTitle,
  placeholder,
  value,
  description,
  updateItemHandler,
  ...restProps
}: Props) {
  return (
    <div {...restProps}>
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "5px 0",
          }}
        >
          {sectionTitle && (
            <Row>
              <h3 style={{ marginTop: 16, marginBottom: 8 }}>{sectionTitle}</h3>
            </Row>
          )}

          <Row>
            <p style={{ marginTop: 0, fontSize: 14 }}>{description}</p>
          </Row>

          <Row>
            <Col
              flex="auto"
              style={{
                border: `1px solid #EAEAEA`,
                borderRadius: "8px",
              }}
            >
              <RichTextEditor
                content={value || ""}
                updateContentHandler={async (content: string) => {
                  await updateItemHandler(content);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
