import { Button, Col, Divider, Dropdown, Menu, Row } from "antd";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import React, { useRef } from "react";

import { Article } from "../../../models";
import { Card } from "./../../../components/Card";
import { DataStore } from "@aws-amplify/datastore";
import { DetailsInputRow } from "../../../components/DetailsInputRow";
import { MarkdownEditorWithPreview } from "../../../components/MarkdownEditorWithPreview";

interface Props {
  article: Article;
  deleteItemHandler: Function;
  onDone: Function;
}

export function AdminManageFrameworkArticleDetails({
  article,
  deleteItemHandler,
  onDone,
}: Props) {
  const theArticle = useRef<Article | null>(article);

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    const original = await DataStore.query(Article, theArticle.current!.id);
    if (original) {
      theArticle.current = await DataStore.save(
        Article.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  function moreMenu() {
    return (
      <Menu>
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(article.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Card>
      <Row align="middle" justify="space-between" gutter={16}>
        <Col>
          <h1 style={{ marginBottom: 0 }}>Manage Article</h1>
        </Col>
        <Col
          flex="50px"
          onClick={async (event) => {
            event.stopPropagation();
          }}
        >
          <Dropdown
            overlay={moreMenu}
            placement="bottomRight"
            arrow
            trigger={["click"]}
          >
            <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
          </Dropdown>
        </Col>
      </Row>
      <br />

      <Col style={{ padding: 0, width: "100%" }}>
        {/* Title */}
        <DetailsInputRow
          sectionTitle="Title"
          placeholder=""
          value={theArticle.current?.title || ""}
          description={""}
          updateItemHandler={async (title: string) => {
            await handleUpdateItem({
              title: title,
            });
          }}
        />

        <Row gutter={16}>
          {/* Short Title */}
          <Col flex="33.34%">
            <DetailsInputRow
              sectionTitle="Short Title"
              placeholder=""
              value={theArticle.current?.shortTitle || ""}
              description={""}
              updateItemHandler={async (shortTitle: string) => {
                await handleUpdateItem({
                  shortTitle: shortTitle,
                });
              }}
            />
          </Col>

          {/* Path */}
          <Col flex="33.34%">
            <DetailsInputRow
              sectionTitle="Path"
              placeholder=""
              value={theArticle.current?.path || ""}
              description={""}
              updateItemHandler={async (path: string) => {
                await handleUpdateItem({
                  path: path,
                });
              }}
            />
          </Col>

          {/* Rank */}
          <Col flex="33.32%">
            <DetailsInputRow
              sectionTitle="Rank"
              placeholder=""
              value={theArticle.current?.rank || ""}
              description={""}
              updateItemHandler={async (rank: string) => {
                await handleUpdateItem({
                  rank: rank,
                });
              }}
            />
          </Col>
        </Row>

        {/* Contents */}
        <MarkdownEditorWithPreview
          sectionTitle="Contents"
          placeholder=""
          value={theArticle.current?.text || ""}
          description={""}
          updateItemHandler={async (text: string) => {
            await handleUpdateItem({
              text: text,
            });
          }}
          autoSize={{ minRows: 2 }}
        />
      </Col>

      <Divider />

      <Row align="middle" justify="end">
        <Button
          type="primary"
          onClick={async () => {
            onDone();
          }}
        >
          Done
        </Button>
      </Row>
    </Card>
  );
}
