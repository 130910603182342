import { Col, Row } from "antd";
import { Learning, Project } from "../../models";
import React, { useEffect } from "react";

import { LearningsTable } from "./LearningsTable";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  learnings: Learning[];
}

export function Learnings({ setPageTitle, learnings }: Props) {
  useEffect(() => {
    setPageTitle("My Learnings");
    ampli.viewedLearningsScreen();
  }, []);

  return (
    <Row justify="center">
      <Col style={{ width: "100%", maxWidth: "768px" }}>
        <LearningsTable learnings={learnings} />
      </Col>
    </Row>
  );
}
