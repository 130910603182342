import {
  AppstoreOutlined,
  DeleteOutlined,
  HolderOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Article, Chapter } from "../../../models";
import { Button, Col, Dropdown, Menu, Row, Tooltip } from "antd";

import React from "react";

const { SubMenu } = Menu;

interface Props {
  chapter: Chapter;
  updateItemHandler: Function;
  deleteItemHandler: Function;
  onClick: Function;
  dragListeners: any;
  dragAttributes: any;
}

export default function AdminManageFrameworkChapterTableRow({
  chapter,
  updateItemHandler,
  deleteItemHandler,
  onClick,
  dragListeners,
  dragAttributes,
  ...restProps
}: Props) {
  function moreMenu() {
    return (
      <Menu>
        <Menu.Item
          key="move-up"
          onClick={async (value) => {
            await updateItemHandler({
              id: chapter.id,
            });
          }}
        >
          Move Up
        </Menu.Item>

        <Menu.Item
          key="move-down"
          onClick={async (value) => {
            await updateItemHandler({
              id: chapter.id,
            });
          }}
        >
          Move Down
        </Menu.Item>

        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onClick={async () => await deleteItemHandler(chapter.id)}
          danger
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div {...restProps} className="tb-table-row" style={{ marginTop: 10 }}>
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "3px 25px",
          }}
          onClick={() => onClick(chapter)}
        >
          <h3 style={{ margin: 0 }}>{chapter.title || "Chapter"}</h3>
        </Col>
        <Col flex="50px">
          <Tooltip title="More Options">
            <Dropdown
              overlay={moreMenu}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button style={{ borderWidth: 0 }} icon={<MoreOutlined />} />
            </Dropdown>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
}
