import * as React from "react";

import { Dropdown, Menu, Row } from "antd";
import {
  Experiment,
  ExperimentStatusEnum,
  HypothesisDecisionEnum,
} from "../../models";

import { DownOutlined } from "@ant-design/icons";

export interface ExperimentStatusTagsProps {
  experiment: Experiment;
  updateStatusHandler?: Function;
  updateDecisionHandler?: Function;
}

export function ExperimentStatusTags({
  experiment,
  updateStatusHandler,
  updateDecisionHandler,
}: ExperimentStatusTagsProps) {
  const statusBackgroundColorHex = () => {
    switch (experiment.status) {
      case ExperimentStatusEnum.WAITING:
        return "#BABABA";
      case ExperimentStatusEnum.RUNNING:
        return "#2EC7FF";
      case ExperimentStatusEnum.ON_HOLD:
        return "#BABABA";
      case ExperimentStatusEnum.VALIDATED:
        return "#4EB665";
      case ExperimentStatusEnum.INVALIDATED:
        return "#B64E4E";
      case ExperimentStatusEnum.INCONCLUSIVE:
        return "#DF972B";
      default:
        return "#BABABA";
    }
  };

  const decisionBackgroundColorHex = () => {
    switch (experiment.decision) {
      case HypothesisDecisionEnum.PERSEVERE:
        return "#4EB665";
      case HypothesisDecisionEnum.PIVOT:
        return "#DF972B";
      case HypothesisDecisionEnum.ABORT:
        return "#B64E4E";
      default:
        return "#BABABA";
    }
  };

  const statusTitle = () => {
    switch (experiment.status) {
      case ExperimentStatusEnum.WAITING:
        return "Waiting";
      case ExperimentStatusEnum.RUNNING:
        return "Running";
      case ExperimentStatusEnum.ON_HOLD:
        return "On Hold";
      case ExperimentStatusEnum.VALIDATED:
        return "Validated";
      case ExperimentStatusEnum.INVALIDATED:
        return "Invalidated";
      case ExperimentStatusEnum.INCONCLUSIVE:
        return "Inconclusive";
      default:
        return "Unknown?!";
    }
  };

  const decisionTitle = () => {
    switch (experiment.decision) {
      case HypothesisDecisionEnum.PERSEVERE:
        return "Persevere";
      case HypothesisDecisionEnum.PIVOT:
        return "Pivot";
      case HypothesisDecisionEnum.ABORT:
        return "Abort";
      default:
        break;
    }
  };

  const statusMenu = (
    <Menu
      items={[
        {
          label: "Validate",
          key: ExperimentStatusEnum.VALIDATED,
        },
        {
          label: "Invalidate",
          key: ExperimentStatusEnum.INVALIDATED,
        },
        {
          label: "Inconclusive",
          key: ExperimentStatusEnum.INCONCLUSIVE,
        },
      ]}
      onClick={({ key }) => {
        if (updateStatusHandler) {
          updateStatusHandler(key);
        }
      }}
    />
  );

  const decisionMenu = (
    <Menu
      items={[
        {
          label: "Persevere",
          key: HypothesisDecisionEnum.PERSEVERE,
        },
        {
          label: "Pivot",
          key: HypothesisDecisionEnum.PIVOT,
        },
        {
          label: "Abort",
          key: HypothesisDecisionEnum.ABORT,
        },
      ]}
      onClick={({ key }) => {
        if (updateDecisionHandler) {
          updateDecisionHandler(key);
        }
      }}
    />
  );

  return (
    <Row align="middle">
      {experiment.status && (
        <Dropdown
          overlay={updateStatusHandler ? statusMenu : <></>}
          trigger={["click"]}
        >
          <Row
            align="middle"
            style={{
              minWidth: 30,
              height: 30,
              borderRadius: "15px",
              backgroundColor: statusBackgroundColorHex(),
              paddingLeft: 10,
              paddingRight: 10,
              color: "#fff",
              fontWeight: 700,
            }}
          >
            {statusTitle()}
            {updateStatusHandler && <DownOutlined style={{ marginLeft: 8 }} />}
          </Row>
        </Dropdown>
      )}

      {experiment.decision && (
        <Dropdown
          overlay={updateDecisionHandler ? decisionMenu : <></>}
          trigger={["click"]}
        >
          <Row
            align="middle"
            style={{
              minWidth: 30,
              height: 30,
              borderRadius: "15px",
              backgroundColor: decisionBackgroundColorHex(),
              marginLeft: 20,
              paddingLeft: 10,
              paddingRight: 10,
              color: "#fff",
              fontWeight: 700,
            }}
          >
            {decisionTitle()}
            {updateDecisionHandler && (
              <DownOutlined style={{ marginLeft: 8 }} />
            )}
          </Row>
        </Dropdown>
      )}
    </Row>
  );
}
