import { Col, Row } from "antd";

import { Card } from "../../components/Card";
import MarkdownPreview from "../../components/MarkdownPreview";
import React from "react";

interface Props {
  setPageTitle: Function;
  pageTitle: string;
  markdown: string;
}

export default function TheFramework({
  setPageTitle,
  pageTitle,
  markdown,
}: Props) {
  setPageTitle(`The Framework - ${pageTitle || "Loading..."}`);

  return (
    <Row justify="center">
      <Col style={{ width: "100%", maxWidth: "768px" }}>
        <Card>
          <MarkdownPreview markdown={markdown || "Loading..."} />
        </Card>
      </Col>
    </Row>
  );
}
