import * as React from "react";

import { Col, Input, Row } from "antd";

import { useDebouncedCallback } from "use-debounce";

export interface DetailsInputRowProps {
  sectionTitle: string;
  placeholder: string;
  value: string | number | readonly string[] | undefined;
  description: string;
  updateItemHandler: Function;
}

export function DetailsInputRow({
  sectionTitle,
  placeholder,
  value,
  description,
  updateItemHandler,
  ...restProps
}: DetailsInputRowProps) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateItemHandler(updatedProperties);
    },
    1000
  );

  return (
    <div {...restProps}>
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "5px 0",
          }}
        >
          {sectionTitle && (
            <Row>
              <h3>{sectionTitle}</h3>
            </Row>
          )}
          <Row>
            <Input
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: 16,
              }}
              placeholder={placeholder}
              defaultValue={value}
              onChange={(e) => debouncedPropertiesUpdate(e.target.value)}
              onBlur={async (e) => {
                await updateItemHandler(e.target.value);
              }}
            />
          </Row>
          <Row>
            <p style={{ marginTop: 8, fontSize: 14 }}>{description}</p>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
