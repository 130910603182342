import { Col, Dropdown, Row } from "antd";
import React, { useState } from "react";

import { PlusOutlined } from "@ant-design/icons";

interface Props {
  dropdownMenu?: JSX.Element;
  onClick: Function;
}

export function AddNewItemCard({ dropdownMenu, onClick, ...restProps }: Props) {
  const [showDropdown, setShowDropdown] = useState(false);

  function handleOnClick(event: any) {
    if (!dropdownMenu) {
      onClick(event);
    } else {
      setShowDropdown(!showDropdown);
    }
  }

  return (
    <div
      style={{ padding: 20 }}
      onClick={(e) => handleOnClick(e)}
      {...restProps}
    >
      <div
        style={{
          cursor: "pointer",
          borderRadius: "25px",
          border: "1px solid #d9d9d9",
          padding: "24px",
        }}
      >
        <Col style={{ width: 300, height: 200 }}>
          <Row align="middle" justify="center" style={{ height: "100%" }}>
            {!dropdownMenu && (
              <PlusOutlined style={{ fontSize: 36, color: "#A1A1A1" }} />
            )}

            {dropdownMenu && (
              <Dropdown
                overlay={dropdownMenu}
                trigger={[]}
                open={showDropdown}
                placement="bottomCenter"
                overlayStyle={{ width: 300, borderRadius: 25 }}
              >
                <PlusOutlined style={{ fontSize: 36, color: "#A1A1A1" }} />
              </Dropdown>
            )}
          </Row>
        </Col>
      </div>
    </div>
  );
}
