import * as React from "react";

import { Col, Input, Row } from "antd";

import MarkdownPreview from "./MarkdownPreview";
import { useDebouncedCallback } from "use-debounce";

const { TextArea } = Input;

interface Props {
  sectionTitle: string;
  placeholder: string;
  value: string;
  description: string;
  updateItemHandler: Function;
  autoSize:
    | boolean
    | {
        minRows?: number;
        maxRows?: number;
      }
    | undefined;
}

export function MarkdownEditorWithPreview({
  sectionTitle,
  placeholder,
  value,
  description,
  updateItemHandler,
  autoSize,
  ...restProps
}: Props) {
  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await updateItemHandler(updatedProperties);
    },
    1000
  );

  return (
    <div {...restProps}>
      <Row align="middle">
        <Col
          flex="auto"
          style={{
            padding: "5px 0",
          }}
        >
          <Row>
            <h2>{sectionTitle}</h2>
          </Row>
          <Row>
            <Col flex="50%">
              <TextArea
                autoSize={autoSize}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  fontSize: 16,
                }}
                placeholder={placeholder}
                defaultValue={value}
                onChange={(e) => debouncedPropertiesUpdate(e.target.value)}
                onBlur={async (e) => {
                  await updateItemHandler(e.target.value);
                }}
              />
            </Col>

            <Col flex="50%" style={{ paddingLeft: "16px" }}>
              <MarkdownPreview markdown={value} />
            </Col>
          </Row>
          <Row>
            <p style={{ marginTop: 8, fontSize: 14 }}>{description}</p>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
