import { Col, Row } from "antd";
import { Collection, Project } from "../../models";
import React, { useEffect } from "react";

import ManageProjectsTable from "./ManageProjectsTable";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  collections: Collection[];
  projectsByCollection: Map<string, Array<Project>>;
}

export default function ManageProjects({
  setPageTitle,
  collections,
  projectsByCollection,
}: Props) {
  useEffect(() => {
    setPageTitle("Manage Projects & Collections");
    ampli.viewedManageProjectsScreen();
  }, []);

  return (
    <Row justify="center">
      <Col style={{ width: "100%", maxWidth: "768px" }}>
        <ManageProjectsTable
          collections={collections}
          projectsByCollection={projectsByCollection}
        />
      </Col>
    </Row>
  );
}
