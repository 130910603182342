import { EditorContent, useEditor } from "@tiptap/react";
import React, { useEffect } from "react";

import Link from "@tiptap/extension-link";
import RichTextEditorMenuBar from "./RichTextEditorMenuBar";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  content: string;
  updateContentHandler: Function;
}

export default function RichTextEditor({
  content,
  updateContentHandler,
}: Props) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
      Underline,
    ],
    content: content || `<p></p>`,
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      debouncedUpdate(content);
    },
    onBlur: async ({ editor }) => {
      const content = editor.getHTML();
      await updateContentHandler(content);
    },
  });

  useEffect(() => {
    const loadData = async () => {};
    loadData();
  }, [content, editor]);

  const debouncedUpdate = useDebouncedCallback(async (content) => {
    await updateContentHandler(content);
  }, 1000);

  return (
    <div>
      <RichTextEditorMenuBar editor={editor} />

      {editor && (
        <EditorContent editor={editor} style={{ padding: "0px 8px" }} />
      )}
    </div>
  );
}
