import { Experiment, Hypothesis } from "../../models";
import { Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { AddNewItemRow } from "../../components/AddNewItemRow";
import { DataStore } from "@aws-amplify/datastore";
import { ExperimentDetails } from "../ExperimentDetails/ExperimentDetails";
import { GenericItemTableRow } from "../../components/GenericItemTableRow";
import { ResponsiveModal } from "../../components/ResponsiveModal";

interface Props {
  hypothesis: Hypothesis;
  createNewExperimentHandler: Function;
}

export function HypothesisExperimentsTable({
  hypothesis,
  createNewExperimentHandler,
}: Props) {
  const [experiments, setExperiments] = useState<Array<Experiment>>([]);

  const selectedExperiment = useRef<Experiment | null>(null);
  const [showLearningDetailsModal, setShowExperimentDetailsModal] =
    useState(false);

  useEffect(() => {
    const loadData = async () => {
      const theExperiments = await loadExperiments(hypothesis.id);
      setExperiments(theExperiments);
    };
    loadData();
  }, [hypothesis.id]);

  useEffect(() => {
    const projectsExperimentsSubscription = DataStore.observe(
      Experiment
    ).subscribe(async () => {
      const theExperiments = await loadExperiments(hypothesis.id);
      setExperiments(theExperiments);
    });
    return () => {
      projectsExperimentsSubscription.unsubscribe();
    };
  }, [hypothesis.id]);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const theExperiments = await loadExperiments(hypothesis.id);
        setExperiments(theExperiments);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [hypothesis.id]);

  async function loadExperiments(hypothesisId?: string) {
    if (!hypothesisId) {
      return [];
    }
    return (await DataStore.query(Experiment)).filter(
      (e) => e.parentHypothesis?.id === hypothesisId
    );
  }

  return (
    <div style={{ paddingBottom: 16, width: "100%" }}>
      <Row>
        <h3>Experiments </h3>
      </Row>

      {experiments.map((eachItem: Experiment) => (
        <GenericItemTableRow
          key={eachItem.id}
          title={eachItem.name || ""}
          onClick={async () => {
            const exp = experiments.find(({ id }) => id === eachItem.id);
            selectedExperiment.current = exp!;
            setShowExperimentDetailsModal(true);
          }}
        />
      ))}

      <AddNewItemRow
        onClick={async () => {
          await createNewExperimentHandler(hypothesis);
          // handleCreateItem();
        }}
      />

      <ResponsiveModal
        title={"Experiment Details"}
        open={showLearningDetailsModal}
        initialHelpContentPath={
          "/experiment-details/experimentDetailsScreenHelp.md"
        }
        closeModalHandler={() => setShowExperimentDetailsModal(false)}
      >
        <ExperimentDetails
          experiment={selectedExperiment.current!}
          setModalVisible={() => setShowExperimentDetailsModal(false)}
        />
      </ResponsiveModal>
    </div>
  );
}
