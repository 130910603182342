import {
  AimOutlined,
  BookOutlined,
  BulbOutlined,
  CoffeeOutlined,
  CaretDownOutlined,
  ExperimentOutlined,
  LineChartOutlined,
  PoweroffOutlined,
  ReadOutlined,
  UserOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Layout, Menu, Row, Space } from "antd";
import {
  Article,
  Canvas,
  CanvasBlock,
  Category,
  Chapter,
  Collection,
  Experiment,
  Hypothesis,
  Learning,
  Objective,
  Organization,
} from "../models";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AdminManageFramework from "../screens/Admin/TheFramework/AdminManageFramework";
import { AuthExportProps } from "./../components/UseAuth";
import { Dashboard } from "../screens/Dashboard/Dashboard";
import { Experiments } from "../screens/Experiments/Experiments";
import { Hub } from "aws-amplify";
import { Hypotheses } from "../screens/Hypotheses/Hypotheses";
import { Ideas } from "../screens/Ideas/Ideas";
import { Learnings } from "../screens/Learnings/Learnings";
import { ManageCanvasDetails } from "../screens/ManageCanvases/ManageCanvasDetails";
import { ManageCanvases } from "../screens/ManageCanvases/ManageCanvases";
import { ManageCategories } from "../screens/ManageExperimentTypes/ManageCategories";
import ManageProjects from "../screens/ManageProjects/ManageProjects";
import type { MenuProps } from "antd";
import { Objectives } from "../screens/Objectives/Objectives";
import Overview from "../screens/TheFramework/Overview";
import Pricing from "../screens/Pricing/Pricing";
import { Project } from "../models";
import { SubscriptionPlanEnum } from "../models";
import TheFramework from "../screens/TheFramework/TheFramework";
import TinyBetsLogo from "./../tiny_bets_logo.svg";
import { UserProfile } from "../screens/UserProfile/UserProfile";
import {
  RadialBackgroundGradient,
  RadialGradients,
} from "../components/Gradients/RadialBackgroundGradient";
import { HelpSidebar } from "../components/HelpSidebar";
import { UpgradePlanCard } from "../components/UpgradePlanCard";

const { Content, Header, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

interface Props {
  auth: AuthExportProps;
}

var datastoreListener: any;

function MainLayout({ auth }: Props) {
  if (!datastoreListener) {
    datastoreListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        console.log("datastore ready");
      }
    });
  }

  const [headerTitle, setHeaderTitle] = useState("Hello");
  const [collapsed, setCollapsed] = useState(false);
  const siderShouldCoverContent = useRef(false);
  const [leftContentPadding, setLeftContentPadding] = useState(200);

  const [helpOpen, setHelpOpen] = useState(false);
  const [helpContentPath, setHelpContentPath] = useState("/generalHelp.md");

  const [userCollections, setUserCollections] = useState<Array<Collection>>([]);

  const [projectsByCollection, setProjectsByCollection] = useState<
    Map<string, Array<Project>>
  >(new Map());

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const selectedProjectRef = useRef<Project | null>(null);

  const [userOrganizations, setUserOrganizations] = useState<
    Array<Organization>
  >([]);
  // const [selectedOrganization, setSelectedOrganization] =
  //   useState<Organization | null>(null);

  const [hypotheses, setHypotheses] = useState(Array<Hypothesis>);
  const [objectives, setObjectives] = useState(Array<Objective>);
  const [categories, setCategories] = useState(Array<Category>);
  const [canvases, setCanvases] = useState(Array<Canvas>);
  const [canvasBlocks, setCanvasBlocks] = useState(Array<CanvasBlock>);
  const [experiments, setExperiments] = useState(Array<Experiment>);
  const [learnings, setLearnings] = useState<Array<Learning>>([]);

  const [learnChapters, setLearnChapters] = useState<Array<Chapter>>([]);
  const [articlesByChapter, setArticlesByChapter] = useState<
    Map<string, Array<Article>>
  >(new Map());

  let location = useLocation();
  const rootKeys = ["/learn", "/manage", "/admin"];
  const [openKeys, setOpenKeys] = useState([getOpenMenuKey(location.pathname)]);
  const navigate = useNavigate();

  useEffect(() => {
    const asyncFunctions = async () => {
      const organizations = await listUserOrganizations();
      setUserOrganizations(organizations);

      const collections = await listUserCollections();
      setUserCollections(collections);

      const allProjectsByCollection = await listUserProjectsByCollection();
      setProjectsByCollection(allProjectsByCollection);

      const allHypotheses = await listHypotheses(
        selectedProjectRef.current?.id
      );
      setHypotheses(allHypotheses);

      const allObjectives = await listObjectives(
        selectedProjectRef.current?.id
      );
      setObjectives(allObjectives);

      const allCategories = await listCategories();
      setCategories(allCategories);

      const allCanvases = await listCanvases();
      setCanvases(allCanvases);

      const allCanvasBlocks = await listCanvasBlocks();
      setCanvasBlocks(allCanvasBlocks);

      const allExperiments = await listExperiments(
        selectedProjectRef.current?.id
      );
      setExperiments(allExperiments);

      const theLearnings = await loadLearnings(selectedProjectRef.current?.id);
      setLearnings(theLearnings);

      const allChapters = await listFrameworkChapters();
      setLearnChapters(allChapters);

      const allArticles = await listFrameworkArticles();
      setArticlesByChapter(allArticles);
    };
    asyncFunctions();
  }, [selectedProjectRef.current?.id]);

  useEffect(() => {
    const organizationsSubscription = DataStore.observe(Organization).subscribe(
      async () => {
        const organizations = await listUserOrganizations();
        setUserOrganizations(organizations);
      }
    );

    const collectionsSubscription = DataStore.observe(Collection).subscribe(
      async () => {
        const collections = await listUserCollections();
        setUserCollections(collections);
      }
    );

    const projectsSubscription = DataStore.observe(Project).subscribe(
      async () => {
        const allProjectsByCollection = await listUserProjectsByCollection();

        setProjectsByCollection(allProjectsByCollection);
      }
    );

    const hypothesesSubscription = DataStore.observe(Hypothesis).subscribe(
      async () => {
        const allHypotheses = await listHypotheses(
          selectedProjectRef.current?.id
        );
        setHypotheses(allHypotheses);
      }
    );

    const objectivesSubscription = DataStore.observe(Objective).subscribe(
      async () => {
        const allObjectives = await listObjectives(
          selectedProjectRef.current?.id
        );
        setObjectives(allObjectives);
      }
    );

    const categoriesSubscription = DataStore.observe(Category).subscribe(
      async () => {
        const allCategories = await listCategories();
        setCategories(allCategories);
      }
    );

    const canvasesSubscription = DataStore.observe(Canvas).subscribe(
      async () => {
        const allCanvases = await listCanvases();
        setCanvases(allCanvases);
      }
    );

    const experimentsSubscription = DataStore.observe(Experiment).subscribe(
      async () => {
        const allExperiments = await listExperiments(
          selectedProjectRef.current?.id
        );
        setExperiments(allExperiments);
      }
    );

    const learningsSubscription = DataStore.observe(Learning).subscribe(
      async () => {
        const theLearnings = await loadLearnings(
          selectedProjectRef.current?.id
        );
        setLearnings(theLearnings);
      }
    );

    const chaptersSubscription = DataStore.observe(Chapter).subscribe(
      async () => {
        const allChapters = await listFrameworkChapters();
        setLearnChapters(allChapters);
      }
    );

    const articlesSubscription = DataStore.observe(Article).subscribe(
      async () => {
        const allArticles = await listFrameworkArticles();
        setArticlesByChapter(allArticles);
      }
    );

    return () => {
      organizationsSubscription.unsubscribe();
      collectionsSubscription.unsubscribe();
      projectsSubscription.unsubscribe();
      hypothesesSubscription.unsubscribe();
      objectivesSubscription.unsubscribe();
      categoriesSubscription.unsubscribe();
      canvasesSubscription.unsubscribe();
      experimentsSubscription.unsubscribe();
      learningsSubscription.unsubscribe();
      chaptersSubscription.unsubscribe();
      articlesSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const organizations = await listUserOrganizations();
        setUserOrganizations(organizations);

        const collections = await listUserCollections();
        setUserCollections(collections);

        const allProjectsByCollection = await listUserProjectsByCollection();
        setProjectsByCollection(allProjectsByCollection);

        const allHypotheses = await listHypotheses(
          selectedProjectRef.current?.id
        );
        setHypotheses(allHypotheses);

        const allObjectives = await listObjectives(
          selectedProjectRef.current?.id
        );
        setObjectives(allObjectives);

        const allCategories = await listCategories();
        setCategories(allCategories);

        const allCanvases = await listCanvases();
        setCanvases(allCanvases);

        const allExperiments = await listExperiments(
          selectedProjectRef.current?.id
        );
        setExperiments(allExperiments);

        const theLearnings = await loadLearnings(
          selectedProjectRef.current?.id
        );
        setLearnings(theLearnings);

        const allChapters = await listFrameworkChapters();
        setLearnChapters(allChapters);

        const allArticles = await listFrameworkArticles();
        setArticlesByChapter(allArticles);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
  }, []);

  useEffect(() => {
    setHelpContentPath(getHelpContentPath());
  }, [location.pathname]);

  function setPageTitle(title: string) {
    setHeaderTitle(title);
    document.title = "Tiny Bets | " + title;
  }

  async function listUserOrganizations() {
    const organizations = await DataStore.query(Organization, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });
    return organizations;
  }

  async function listUserCollections() {
    const collections = await DataStore.query(Collection, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });
    return collections;
  }

  async function listUserProjectsByCollection() {
    const projects = await DataStore.query(Project, Predicates.ALL, {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });

    let projectsByCollection = new Map<string, Array<Project>>();
    let projectWasSelected = false;
    for (const eachProject of projects) {
      if (!eachProject.collection) {
        continue;
      }

      if (projectWasSelected === false && selectedProject == null) {
        setSelectedProject(eachProject);
        selectedProjectRef.current = eachProject;
        projectWasSelected = true;
      }

      if (!projectsByCollection.has(eachProject.collection.id)) {
        projectsByCollection.set(eachProject.collection.id, []);
      }
      projectsByCollection.get(eachProject.collection.id)?.push(eachProject);
    }

    return projectsByCollection;
  }

  async function listHypotheses(projectId: string | undefined) {
    if (!projectId) {
      console.log("listHypotheses no project id");
      return [];
    }

    const hypotheses = (await DataStore.query(Hypothesis))
      .filter((h) => h.project.id === projectId)
      .sort((a, b) => b.createdAt?.localeCompare(a.createdAt || "") || 0);

    return hypotheses;
  }

  async function listObjectives(projectId: string | undefined) {
    if (!projectId) {
      return [];
    }

    const objectives = (await DataStore.query(Objective))
      .filter((o) => o.project.id === projectId)
      .sort((a, b) => a.createdAt?.localeCompare(b.createdAt || "") || 0);

    return objectives;
  }

  async function listCategories() {
    const categories = (await DataStore.query(Category)).sort(
      (a, b) => a.name?.localeCompare(b.name || "") || 0
    );

    return categories;
  }

  async function listCanvases() {
    const canvases = (await DataStore.query(Canvas)).sort(
      (a, b) => a.name?.localeCompare(b.name || "") || 0
    );

    return canvases;
  }

  async function listCanvasBlocks() {
    return await DataStore.query(CanvasBlock);
  }

  async function listExperiments(projectId: string | undefined) {
    if (!projectId) {
      return [];
    }

    const experiments = (await DataStore.query(Experiment))
      .filter((e) => e.project.id === projectId)
      .sort((a, b) => b.createdAt?.localeCompare(a.createdAt || "") || 0);

    return experiments;
  }

  async function loadLearnings(projectId: string | undefined) {
    if (!projectId) {
      return [];
    }

    return (await DataStore.query(Learning)).filter(
      (l) => l.project?.id === projectId
    );
  }

  async function listFrameworkChapters() {
    const chapters = await DataStore.query(Chapter, Predicates.ALL, {
      sort: (s) => s.rank(SortDirection.ASCENDING),
    });
    return chapters;
  }

  async function listFrameworkArticles() {
    const articles = await DataStore.query(Article, Predicates.ALL, {
      sort: (s) => s.rank(SortDirection.ASCENDING),
    });

    let articlesByChapter = new Map<string, Array<Article>>();
    for (const article of articles) {
      if (!article.chapter) {
        continue;
      }

      if (!articlesByChapter.has(article.chapter.id)) {
        articlesByChapter.set(article.chapter.id, []);
      }
      articlesByChapter.get(article.chapter.id)?.push(article);
    }
    return articlesByChapter;
  }

  function onCollapse(collapsed: boolean) {
    setCollapsed(collapsed);
    // setLeftContentPadding(collapsed ? 80 : 200);
    setLeftContentPadding(
      collapsed ? 0 : siderShouldCoverContent.current ? 0 : 200
    );
    if (!collapsed) {
      setTimeout(() => {
        setOpenKeys([getOpenMenuKey(location.pathname)]);
      }, 50);
    } else {
      setOpenKeys([]);
    }
  }

  async function handleSignOut() {
    auth.signOut();
  }

  function getHelpContentPath() {
    const path = location.pathname;

    if (path.startsWith("/dashboard")) {
      return "/dashboard/dashboardScreenHelp.md";
    } else if (path.startsWith("/objectives")) {
      return "/objectives/objectivesScreenHelp.md";
    } else if (path.startsWith("/hypotheses")) {
      return "/hypotheses/hypothesesScreenHelp.md";
    } else if (path.startsWith("/experiments")) {
      return "/experiments/experimentsScreenHelp.md";
    } else if (path.startsWith("/learnings")) {
      return "/learnings/learningsScreenHelp.md";
    } else if (path.startsWith("/manage/projects")) {
      return "/manage-projects/manageProjectsScreenHelp.md";
    } else if (path.startsWith("/manage/categories")) {
      return "/manage-categories/manageCategoriesScreenHelp.md";
    } else if (path.startsWith("/manage/canvases")) {
      return "/manage-canvases/manageCanvasesScreenHelp.md";
    } else {
      return "/generalHelp.md";
    }
  }

  const onSectionOpenChange = (items: string[]) => {
    const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
    if (!latestOpenKey || rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(items);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function getOpenMenuKey(pathname: string) {
    if (pathname.includes("/learn/")) {
      return "/learn";
    } else if (
      pathname.includes("/manage/projects") ||
      pathname.includes("/manage/canvases") ||
      pathname.includes("/manage/categories")
    ) {
      return "/manage";
    } else if (pathname.includes("/admin/")) {
      return "/admin";
    }
  }

  function getProjectDropdownMenuItems() {
    return userCollections.map((eachCollection) => {
      return {
        label: eachCollection.name || "",
        key: eachCollection.id,
        type: "group",
        children: projectsByCollection
          .get(eachCollection.id)
          ?.map((eachProject, index) => {
            return {
              label: eachProject.name || "",
              key: `${eachCollection.id}/${index}`,
            };
          }),
      };
    });
  }

  async function projectMenuOnClick({ key }: any) {
    const [collectionId, projectIndex] = key.split("/");

    setSelectedProject(
      projectsByCollection.get(collectionId)?.[projectIndex] || null
    );
    selectedProjectRef.current =
      projectsByCollection.get(collectionId)?.[projectIndex] || null;
  }

  function getTopMenuItems() {
    let siderTopMenuItems = [
      {
        label: "Dashboard",
        key: "/dashboard",
        icon: <LineChartOutlined />,
      },
    ] as Array<MenuItem>;

    siderTopMenuItems.push({
      label: "Objectives",
      key: "/objectives",
      icon: <AimOutlined />,
    });

    siderTopMenuItems.push({
      label: "Hypotheses",
      key: "/hypotheses",
      icon: <BulbOutlined />,
    });

    // siderTopMenuItems.push({
    //   label: "Canvases",
    //   key: "/cavases",
    //   icon: <DesktopOutlined />,
    //   children: canvases.map((eachCanvas) => {
    //     return {
    //       label: eachCanvas.name || "",
    //       key: `/cavases/${eachCanvas.id}`,
    //     };
    //   }),
    // });

    siderTopMenuItems.push({
      label: "Experiments",
      key: "/experiments",
      icon: <ExperimentOutlined />,
    });

    siderTopMenuItems.push({
      label: "Learnings",
      key: "/learnings",
      icon: <BookOutlined />,
    });

    siderTopMenuItems.push({
      label: "Learn",
      key: "/learn",
      icon: <ReadOutlined />,
      children: [{ label: "Overview", key: "/learn/overview" }].concat(
        learnChapters.map((eachChapter) => {
          return {
            label: eachChapter.title || "",
            key: `/learn/${eachChapter.path}`,
            // type: "group",
            children: articlesByChapter
              .get(eachChapter.id)
              ?.map((eachArticle) => {
                return {
                  label: eachArticle.shortTitle || "",
                  key: `/learn/${eachChapter.path}/${eachArticle.path}`,
                };
              }),
          };
        })
      ),
    });

    siderTopMenuItems.push({
      label: "Manage",
      key: "/manage",
      icon: <CoffeeOutlined />,
      children: [
        { label: "Projects", key: "/manage/projects" },
        { label: "Canvases", key: "/manage/canvases" },
        { label: "Categories", key: "/manage/categories" },
      ],
    });

    return siderTopMenuItems;
  }

  function getBottomMenuItems() {
    let siderBottomMenuItems = [
      {
        label: "My Profile",
        key: "/profile",
        icon: <UserOutlined />,
      },
      {
        label: "Sign Out",
        key: "/logout",
        icon: <PoweroffOutlined />,
      },
    ] as Array<MenuItem>;

    if (auth.isAdmin) {
      siderBottomMenuItems.splice(1, 0, {
        label: "Admin",
        key: "/admin",
        icon: <CoffeeOutlined />,
        children: [
          {
            label: "Learn",
            key: "/admin/learn",
            icon: <UserOutlined />,
          },
        ],
      });
    }

    return siderBottomMenuItems;
  }

  function getAdminMenuItems() {
    let siderBottomMenuItems = [] as Array<MenuItem>;

    if (auth.isAdmin) {
      siderBottomMenuItems.splice(0, 0, {
        label: "Admin",
        key: "/admin",
        icon: <CoffeeOutlined />,
        children: [
          {
            label: "Learn",
            key: "/admin/learn",
            icon: <UserOutlined />,
          },
        ],
      });
    }

    return siderBottomMenuItems;
  }

  async function siderMenuOnClick({ key }: any) {
    if (key === "/logout") {
      await handleSignOut();
    } else {
      navigate(key);
    }
  }

  if (!auth.cognitoUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        theme="light"
        onCollapse={onCollapse}
        onBreakpoint={(broken) => {
          siderShouldCoverContent.current = broken;
          setLeftContentPadding(collapsed ? 0 : broken ? 0 : 200);
        }}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 3,
          width: 200,
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Row justify="center">
              <Space align="center">
                <Col flex="auto">
                  <img src={TinyBetsLogo} className="logo" alt="logo" />
                </Col>
              </Space>
            </Row>

            <Row
              justify="start"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <Col>
                <Row align="middle">
                  <Dropdown
                    overlay={
                      <Menu
                        items={getProjectDropdownMenuItems()}
                        onClick={projectMenuOnClick}
                      />
                    }
                    trigger={["click"]}
                  >
                    <Space data-intercom-target="project-dropdown">
                      <h3 style={{ margin: 0 }}>
                        {selectedProject?.name || "Select your project"}
                      </h3>
                      <CaretDownOutlined
                        style={{
                          paddingTop: "6px",
                          fontSize: "14px",
                          color: "#545f73",
                        }}
                      />
                    </Space>
                  </Dropdown>
                </Row>
              </Col>
            </Row>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <Menu
              style={{ padding: "0 10px" }}
              inlineIndent={10}
              theme="light"
              selectedKeys={[location.pathname]}
              items={getTopMenuItems()}
              mode="inline"
              onOpenChange={onSectionOpenChange}
              onClick={siderMenuOnClick}
            />
          </div>

          <div style={{ padding: 20 }}>
            <UpgradePlanCard
              subscriptionPlan={auth.subscriptionPlan}
              daysLeftInTrial={auth.daysLeftInTrial}
            />
          </div>
        </div>
      </Sider>

      <Layout className="site-layout">
        {/* Background Gradients */}
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#F9FAFD",
          }}
        >
          <RadialBackgroundGradient
            gradient={RadialGradients.Purple}
            left="-100%"
            top="-80%"
            width="200%"
            height="200%"
          />
          <RadialBackgroundGradient
            gradient={RadialGradients.Cyan}
            right="-100%"
            top="-80%"
            width="200%"
            height="200%"
          />
          <RadialBackgroundGradient
            gradient={RadialGradients.Orange}
            bottom="-100%"
            width="200%"
            height="200%"
            left="-100%"
            right="-100%"
            margin="auto"
          />
        </div>

        <Header
          className="site-layout-background shadow-spread"
          style={{
            position: "fixed",
            zIndex: 2,
            width: `calc(100% - ${leftContentPadding}px)`,
            marginLeft: leftContentPadding,
            marginRight: 0,
            paddingLeft: 20,
            paddingRight: 25,
            borderLeft: "1px solid #eeeeee",
          }}
        >
          <Row align="middle" justify="space-between">
            <h1
              style={{
                padding: 0,
                margin: 0,
                marginLeft: collapsed ? 30 : 0,
              }}
            >
              {headerTitle}
            </h1>

            <Col>
              <Row align="middle">
                <Button
                  style={{ border: "none", marginLeft: 25 }}
                  icon={
                    helpOpen ? (
                      <QuestionCircleFilled style={{ fontSize: 20 }} />
                    ) : (
                      <QuestionCircleOutlined style={{ fontSize: 20 }} />
                    )
                  }
                  onClick={async (e) => {
                    if (setHelpOpen) setHelpOpen(!helpOpen);
                  }}
                />

                <Dropdown
                  menu={{
                    items: getBottomMenuItems(),
                    onClick: siderMenuOnClick,
                  }}
                  trigger={["click"]}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <UserOutlined
                    style={{
                      fontSize: 20,
                      marginLeft: 16,
                      backgroundColor: "#E4EFEF",
                      padding: 14,
                      borderRadius: 80,
                    }}
                  />
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            marginTop: 64,
            paddingTop: 24,
            paddingLeft: leftContentPadding + 24,
            paddingRight: 24,
            paddingBottom: 24,
            backgroundColor: "transparent",
            filter: "drop-shadow(0 0 10px rgba(155, 155, 155, 0.2))",
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />

            <Route
              path={`/dashboard`}
              element={
                <Dashboard
                  setPageTitle={setPageTitle}
                  project={selectedProject}
                  categories={categories}
                  experiments={experiments}
                />
              }
            />

            <Route
              path={`/learn/overview`}
              element={<Overview setPageTitle={setPageTitle} />}
            />

            {learnChapters.map((eachChapter) =>
              articlesByChapter
                .get(eachChapter.id)
                ?.map((eachArticle) => (
                  <Route
                    path={`/learn/${eachChapter.path}/${eachArticle.path}`}
                    element={
                      <TheFramework
                        setPageTitle={setPageTitle}
                        pageTitle={eachArticle.title || ""}
                        markdown={eachArticle.text || ""}
                      />
                    }
                  />
                ))
            )}

            <Route
              path="/objectives"
              element={
                selectedProject && (
                  <Objectives
                    setPageTitle={setPageTitle}
                    project={selectedProject}
                    objectives={objectives}
                  />
                )
              }
            />

            <Route
              path="/hypotheses"
              element={
                selectedProject && (
                  <Hypotheses
                    setPageTitle={setPageTitle}
                    project={selectedProject}
                    hypotheses={hypotheses}
                    objectives={objectives}
                    categories={categories}
                    canvases={canvases}
                    allCanvasBlocks={canvasBlocks}
                  />
                )
              }
            />

            {/* <Route
              path="/canvases/:canvasId"
              element={
                selectedProject && (
                  <ManageCanvasDetails project={selectedProject} />
                )
              }
            /> */}

            <Route
              path="/experiments"
              element={
                selectedProject && (
                  <Experiments
                    setPageTitle={setPageTitle}
                    project={selectedProject}
                    categories={categories}
                    experiments={experiments}
                  />
                )
              }
            />

            <Route
              path="/learnings"
              element={
                <Learnings setPageTitle={setPageTitle} learnings={learnings} />
              }
            />

            <Route
              path="/ideas"
              element={
                selectedProject && (
                  <Ideas
                    setPageTitle={setPageTitle}
                    project={selectedProject}
                  />
                )
              }
            />

            <Route
              path="/manage/projects"
              element={
                <ManageProjects
                  setPageTitle={setPageTitle}
                  collections={userCollections}
                  projectsByCollection={projectsByCollection}
                />
              }
            />

            <Route
              path="/manage/canvases"
              element={
                selectedProject && (
                  <ManageCanvases
                    setPageTitle={setPageTitle}
                    canvases={canvases}
                    allCanvasBlocks={canvasBlocks}
                  />
                )
              }
            />

            <Route
              path="/manage/canvases/:canvasId"
              element={
                selectedProject && (
                  <ManageCanvasDetails project={selectedProject} />
                )
              }
            />

            <Route
              path="/manage/categories"
              element={
                <ManageCategories
                  setPageTitle={setPageTitle}
                  categories={categories}
                />
              }
            />

            <Route
              path="/admin/learn"
              element={<AdminManageFramework setPageTitle={setPageTitle} />}
            />

            <Route
              path="/profile"
              element={
                <UserProfile
                  setPageTitle={setPageTitle}
                  userId={auth.userId}
                  currentPlan={auth.subscriptionPlan}
                />
              }
            />

            <Route
              path="/profile/pricing"
              element={
                <Pricing
                  setPageTitle={setPageTitle}
                  currentPlan={auth.subscriptionPlan}
                  organizationId={userOrganizations[0]?.id || ""}
                />
              }
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Content>
      </Layout>

      <HelpSidebar
        setHelpOpen={setHelpOpen}
        helpOpen={helpOpen}
        contentPath={helpContentPath}
      />
    </Layout>
  );
}

export default MainLayout;
