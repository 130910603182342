import { Canvas, CanvasBlock } from "../../models";
import { Col, Row } from "antd";
import React, { useEffect } from "react";

import { ManageCanvasesGrid } from "./ManageCanvasesGrid";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  canvases: Canvas[];
  allCanvasBlocks: CanvasBlock[];
}

export function ManageCanvases({
  setPageTitle,
  canvases,
  allCanvasBlocks,
}: Props) {
  useEffect(() => {
    setPageTitle("Manage Your Canvases");
    ampli.viewedManageCanvasesScreen();
  }, []);

  return (
    <>
      <Row justify="center">
        <Col flex="auto">
          <ManageCanvasesGrid
            canvases={canvases}
            allCanvasBlocks={allCanvasBlocks}
          />
        </Col>
      </Row>
    </>
  );
}
