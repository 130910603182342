import {
  ArrowLeftOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, InputNumber, Row } from "antd";
import React, { useState, useEffect, useRef } from "react";

import {
  Canvas,
  CanvasBlock,
  Category,
  Hypothesis,
  Objective,
  Project,
} from "../../models";
import { CanvasEditor } from "../../components/CanvasEditor";

import { DataStore } from "@aws-amplify/datastore";

interface Props {
  canvasId: string;
  project: Project;
  hypotheses: Hypothesis[];
  objectives: Objective[];
  categories: Category[];
  openHypothesisHandler: Function;
  addNewHypothesisHandler: Function;
  updateHypothesisHandler: Function;
  deleteHypothesisHandler: Function;
}

export function HypothesesCanvas({
  canvasId,
  project,
  hypotheses,
  objectives,
  categories,
  openHypothesisHandler,
  addNewHypothesisHandler,
  updateHypothesisHandler,
  deleteHypothesisHandler,
}: Props) {
  const theCanvas = useRef<Canvas | null>(null);
  const [canvasBlocks, setCanvasBlocks] = useState(Array<CanvasBlock>);

  const [columns, setColumns] = useState(theCanvas.current?.columns || 10);

  useEffect(() => {
    const loadData = async () => {
      theCanvas.current = await loadCanvas(canvasId);
      setColumns(theCanvas.current?.columns || 10);

      const theCanvasBlocks = await loadCanvasBlocks(canvasId);
      setCanvasBlocks(theCanvasBlocks);
    };
    loadData();
  }, [canvasId]);

  useEffect(() => {
    const canvasSubscription = DataStore.observe(Canvas).subscribe(async () => {
      theCanvas.current = await loadCanvas(canvasId);
    });

    const canvasesCanvasBlocksSubscription = DataStore.observe(
      CanvasBlock
    ).subscribe(async () => {
      // const theCanvasBlocks = await loadCanvasBlocks(canvasId);
      // setCanvasBlocks(theCanvasBlocks);
    });
    return () => {
      canvasSubscription.unsubscribe();
      canvasesCanvasBlocksSubscription.unsubscribe();
    };
  }, [canvasId]);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        theCanvas.current = await loadCanvas(canvasId);

        // const theCanvasBlocks = await loadCanvasBlocks(canvasId);
        // setCanvasBlocks(theCanvasBlocks);
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [canvasId]);

  async function loadCanvas(canvasId?: string) {
    if (!canvasId) {
      return null;
    }

    return (await DataStore.query(Canvas, canvasId)) || null;
  }

  async function loadCanvasBlocks(canvasId?: string) {
    if (!canvasId) {
      return [];
    }

    return (await DataStore.query(CanvasBlock)).filter(
      (e) => e.canvas?.id === canvasId
    );
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <div
        style={{
          margin: "-5px",
          overflow: "hidden",
        }}
      >
        {theCanvas.current && (
          <CanvasEditor
            canvas={theCanvas.current}
            blocks={canvasBlocks}
            categories={categories}
            hypotheses={hypotheses}
            columns={columns}
            staticGrid={true}
            project={project}
            openHypothesisHandler={openHypothesisHandler}
            addNewHypothesisHandler={addNewHypothesisHandler}
            updateHypothesisHandler={updateHypothesisHandler}
            deleteHypothesisHandler={deleteHypothesisHandler}
          />
        )}
      </div>
    </div>
  );
}
