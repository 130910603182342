import { API, graphqlOperation } from "aws-amplify";
import { Button, Col, Input, Row } from "antd";
import { Organization, SubscriptionPlanEnum } from "../../models";
import React, { useEffect, useRef, useState } from "react";

import { Card } from "../../components/Card";
import { DataStore } from "@aws-amplify/datastore";
import { GetStripeCustomerPortalUrlQuery } from "../../API";
import { User } from "../../models";
import { ampli } from "../../ampli";
import { getStripeCustomerPortalUrl } from "../../graphql/queries";
import { titleCase } from "../../utils/Utils";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";

interface Props {
  setPageTitle: Function;
  userId: string | undefined;
  currentPlan: SubscriptionPlanEnum;
}

export function UserProfile({
  setPageTitle,
  userId,
  currentPlan,
  ...restProps
}: Props) {
  const navigate = useNavigate();

  const [userOrganizations, setUserOrganizations] = useState<
    Array<Organization>
  >([]);
  const userProfile = useRef<User | undefined>(undefined);
  const [openingStripeCustomerPortal, setOpeningStripeCustomerPortal] =
    useState(false);

  useEffect(() => {
    setPageTitle("My Profile");
    ampli.viewedProfileScreen();

    const loadData = async () => {
      await loadUserProfile();
      await loadUserOrganizations();
    };
    loadData();
  }, []);

  useEffect(() => {
    const userProfileSubscription = DataStore.observe(User).subscribe(
      async () => {
        await loadUserProfile();
      }
    );

    const userOrganizationsSubscription = DataStore.observe(
      Organization
    ).subscribe(async () => {
      await loadUserOrganizations();
    });

    return () => {
      userProfileSubscription.unsubscribe();
      userOrganizationsSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        await loadUserProfile();
        await loadUserOrganizations();
      }
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  async function loadUserProfile() {
    if (!userId) {
      return undefined;
    }

    userProfile.current = await DataStore.query(User, userId);
    return userProfile;
  }

  async function loadUserOrganizations() {
    if (!userId) {
      return undefined;
    }

    setUserOrganizations(await DataStore.query(Organization));
  }

  async function handleUpdateItem(updatedProperties: { [key: string]: any }) {
    if (!userId) {
      return undefined;
    }

    const original = await DataStore.query(User, userId);
    if (original) {
      await DataStore.save(
        User.copyOf(original, (updated: { [key: string]: any }) => {
          for (var key in updatedProperties) {
            if (key !== "id") {
              updated[key] = updatedProperties[key];
            }
          }
        })
      );
    }
  }

  const debouncedPropertiesUpdate = useDebouncedCallback(
    async (updatedProperties) => {
      await handleUpdateItem(updatedProperties);
    },
    1000
  );

  async function openStripeCustomerPortal(organizationId: string) {
    setOpeningStripeCustomerPortal(true);
    const { data } = (await API.graphql(
      graphqlOperation(getStripeCustomerPortalUrl, {
        organizationId: organizationId,
      })
    )) as { data: GetStripeCustomerPortalUrlQuery };

    const { sessionUrl } = JSON.parse(data.getStripeCustomerPortalUrl || "");

    if (sessionUrl) {
      window.location.href = sessionUrl;
    }

    setOpeningStripeCustomerPortal(false);
  }

  return (
    <div {...restProps}>
      <Row justify="center" gutter={[32, 32]}>
        <Col style={{ width: "100%", maxWidth: "600px" }}>
          <Card>
            <Col
              flex="auto"
              style={{
                padding: "5px 0",
              }}
            >
              <Row
                style={{
                  paddingBottom: "16px",
                }}
              >
                <h3>About Me</h3>
              </Row>

              {!userProfile.current && <p>Loading...</p>}

              {userProfile.current && (
                <Row gutter={[16, 16]}>
                  {/* First Name */}
                  <Col flex="50%">
                    <Input
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        fontSize: 16,
                      }}
                      placeholder="First name..."
                      defaultValue={userProfile.current?.firstName || ""}
                      onChange={(e) =>
                        debouncedPropertiesUpdate({
                          firstName: e.target.value,
                        })
                      }
                      onBlur={async (e) => {
                        await handleUpdateItem({
                          firstName: e.target.value,
                        });
                      }}
                    />
                  </Col>

                  {/* Last Name */}
                  <Col flex="50%">
                    <Input
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        fontSize: 16,
                      }}
                      placeholder="Last name..."
                      defaultValue={userProfile.current?.lastName || ""}
                      onChange={(e) =>
                        debouncedPropertiesUpdate({
                          lastName: e.target.value,
                        })
                      }
                      onBlur={async (e) => {
                        await handleUpdateItem({
                          lastName: e.target.value,
                        });
                      }}
                    />
                  </Col>

                  {/* Email Address */}
                  <Col flex="100%">
                    <Input
                      disabled
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        fontSize: 16,
                      }}
                      placeholder="Email address..."
                      defaultValue={userProfile.current?.email || ""}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Card>
        </Col>

        <Col style={{ width: "100%", maxWidth: "600px" }}>
          <Card>
            <Col
              flex="auto"
              style={{
                padding: "5px 0",
              }}
            >
              <Row
              // style={{
              //   paddingBottom: "16px",
              // }}
              >
                <h3>My Organizations</h3>
              </Row>

              {!userOrganizations.length && <p>Loading...</p>}
              <>
                {userOrganizations.map((eachOrganization, index) => (
                  <Row
                    gutter={[16, 16]}
                    justify="space-between"
                    align="middle"
                    style={{ marginTop: 16 }}
                  >
                    <Col flex="auto">
                      <h4 style={{ padding: 0, margin: 0 }}>
                        {`${index + 1}. ${
                          eachOrganization.name || "Your Default Organization"
                        }: ${titleCase(
                          eachOrganization.subscriptionPlan ?? ""
                        )}`}
                      </h4>
                    </Col>

                    {eachOrganization.subscriptionPlan ===
                      SubscriptionPlanEnum.TRIAL && (
                      <Col flex="0 1 auto">
                        <Button
                          type="primary"
                          onClick={async (e) => {
                            navigate("/profile/pricing");
                          }}
                        >
                          <h4 style={{ padding: 0, margin: 0, color: "white" }}>
                            Pick Your Plan
                          </h4>
                        </Button>
                      </Col>
                    )}

                    {eachOrganization.stripeCustomerId &&
                      (eachOrganization.subscriptionPlan ===
                        SubscriptionPlanEnum.MONTHLY ||
                        eachOrganization.subscriptionPlan ===
                          SubscriptionPlanEnum.ANNUAL) && (
                        <Col flex="0 1 auto">
                          <Button
                            type="default"
                            loading={openingStripeCustomerPortal}
                            onClick={async (e) => {
                              e.preventDefault();
                              openStripeCustomerPortal(eachOrganization.id);
                            }}
                          >
                            <h4 style={{ padding: 0, margin: 0 }}>
                              Manage Plan
                            </h4>
                          </Button>
                        </Col>
                      )}
                  </Row>
                ))}
              </>
            </Col>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
