import "./App.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";

import Loading from "./screens/Loading/Loading";
import MainLayout from "./layout/MainLayout";
import SignIn from "./screens/SignIn/SignIn";
import { ampli } from "./ampli";
import dayjs from "dayjs";
import { useAuth } from "./components/UseAuth";

if (process.env.REACT_APP_AMPLITUDE_ENVIRONMENT) {
  ampli.load({
    environment:
      process.env.REACT_APP_AMPLITUDE_ENVIRONMENT === "production"
        ? "production"
        : "development",
    client: {
      configuration: {
        attribution: {
          trackPageViews: false,
        },
      },
    },
  });
}

interface Props {}

function App({}: Props) {
  const auth = useAuth();

  // useEffect(() => {
  //   window.Intercom("boot", {
  //     api_base: process.env.REACT_APP_INTERCOM_API_BASE,
  //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  //     user_id: auth?.userId,
  //   });
  // }, [auth?.userId]);

  useEffect(() => {
    if (!auth?.userProfile) {
      return;
    }

    let userName = auth.userProfile.firstName;
    if (auth.userProfile.lastName) {
      userName += " " + auth.userProfile.lastName;
    }

    window.Intercom("boot", {
      api_base: process.env.REACT_APP_INTERCOM_API_BASE,
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: auth.userProfile.id,
      name: userName ?? "",
      email: auth.userProfile.email ?? "",
      user_hash: auth.userProfile.intercomHMAC ?? "",
      created_at: dayjs(auth.userProfile.createdAt).unix(),
    });
  }, [auth?.userProfile]);

  if (auth?.isAuthenticating) {
    return <Loading />;
  }

  if (
    !auth?.cognitoUser ||
    auth?.cognitoUser.challengeName ||
    auth?.isOnboarding
  ) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        <MainLayout auth={auth!} />
      </BrowserRouter>
    </>
  );
}

export default App;
