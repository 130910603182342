import { Category, Experiment, Project } from "../../models";
import { Col, Divider, Row } from "antd";
import React, { useEffect } from "react";

import { ExperimentsTable } from "./ExperimentsTable";
import { ProjectCard } from "../../components/ProjectCard";
import { ampli } from "../../ampli";

interface Props {
  setPageTitle: Function;
  project: Project;
  categories: Category[];
  experiments: Experiment[];
}

export function Experiments({
  setPageTitle,
  project,
  categories,
  experiments,
}: Props) {
  useEffect(() => {
    setPageTitle("Experiments");
    ampli.viewedExperimentsScreen();
  }, []);

  return (
    <>
      <Row justify="center">
        <Col flex="auto" style={{ maxWidth: 768 }}>
          <ProjectCard
            project={project}
            categories={categories}
            experiments={experiments}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col flex="auto" style={{ maxWidth: 768 }}>
          <Row justify="center" align="middle">
            <Col flex="10px">
              <Divider />
            </Col>
            <Col flex="0 1 auto" style={{ paddingLeft: 10, paddingRight: 10 }}>
              <h5 style={{ padding: 0, margin: 0, color: "grey" }}>
                YOUR EXPERIMENTS
              </h5>
            </Col>
            <Col flex="auto">
              <Divider />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col flex="auto" style={{ maxWidth: 768 }}>
          <ExperimentsTable project={project} experiments={experiments} />
        </Col>
      </Row>
    </>
  );
}
