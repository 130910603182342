import * as React from "react";

import { Col, Row } from "antd";

import { IdeasTable } from "./IdeasTable";
import { Project } from "../../models";

interface Props {
  setPageTitle: Function;
  project: Project;
}

export function Ideas({ setPageTitle, project }: Props) {
  setPageTitle("Ideas");

  return (
    <Row justify="center">
      <Col flex="auto">
        <IdeasTable project={project} />
      </Col>
    </Row>
  );
}
