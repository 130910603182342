import { Col, Row } from "antd";

import { DetailsTitleInput } from "./DetailsTitleInput";
import React from "react";

interface Props {
  title: string;
  placeholder: string;
  updateTitleHandler: Function;
}

export function DetailsTitleHeader({
  title,
  placeholder,
  updateTitleHandler,
}: Props) {
  return (
    <Row align="top" justify="space-between">
      <Col
        flex="auto"
        style={{
          marginLeft: "-8px",
          marginRight: "-8px",
          marginTop: "-16px",
        }}
      >
        <DetailsTitleInput
          placeholder={placeholder}
          defaultValue={title}
          description={""}
          style={{ width: "calc(100% + 16px)" }}
          updateItemHandler={async (content: string) => {
            await updateTitleHandler(content);
          }}
        />
      </Col>
    </Row>
  );
}
