import * as DOMPurify from "dompurify";

import React, { useMemo } from "react";

interface Props {
  htmlString: string;
}

export function SafeHtmlRenderer({ htmlString }: Props) {
  const sanitizedHtml = useMemo(() => {
    return DOMPurify.sanitize(htmlString);
  }, [htmlString]);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
}
