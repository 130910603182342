import * as DOMPurify from "dompurify";

import { Col, Divider } from "antd";
import React, { useMemo } from "react";

import { Card } from "../../components/Card";
import { Hypothesis } from "../../models";

interface Props {
  hypothesis: Hypothesis;
  updateItemHandler: Function;
  onClick: Function;
}

export function HypothesesGridCell({
  hypothesis,
  updateItemHandler,
  onClick,
  ...restProps
}: Props) {
  const sanitizedHtml = useMemo(() => {
    return DOMPurify.sanitize(hypothesis.name || "");
  }, [hypothesis.name]);

  return (
    <div
      style={{ padding: 20 }}
      onClick={() => onClick(hypothesis)}
      {...restProps}
    >
      <div style={{ cursor: "pointer" }}>
        <Card>
          <Col style={{ width: 300, height: 200, overflow: "hidden" }}>
            <h2>{sanitizedHtml}</h2>
            <Divider style={{ marginTop: 0, marginBottom: 16 }} />
            <h4>
              Category:{" "}
              <span style={{ fontWeight: 400, color: "#a1a1a1" }}>
                {hypothesis.category?.name || "No Category"}
              </span>
            </h4>
            <h4>
              Status:{" "}
              <span style={{ fontWeight: 400, color: "#a1a1a1" }}>
                {hypothesis.status}
              </span>
            </h4>
            {hypothesis.decision && (
              <h4>
                Decision:{" "}
                <span style={{ fontWeight: 400, color: "#a1a1a1" }}>
                  {hypothesis.decision}
                </span>
              </h4>
            )}
          </Col>
        </Card>
      </div>
    </div>
  );
}
