/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 5
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/tiny-bets/Tiny%20Bets/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: 'd7064153316b350c0986b37a416b595d',
  production: '3a4eeffc91bcd50776190fe1fb0ed524'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '5',
    branch: 'main',
    source: 'web',
    versionId: '11f3663d-db0d-455b-a0df-6f7ac5d6d804'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptions {
  environment?: Environment;
  disabled?: boolean;
  client?: {
    apiKey?: string;
    configuration?: BrowserOptions;
    instance?: BrowserClient;
  }
}

export interface IdentifyProperties {
  email?: string;
  initial_fbclid?: {
    [k: string]: any;
  };
  initial_gclid?: {
    [k: string]: any;
  };
  initial_referrer?: {
    [k: string]: any;
  };
  initial_referring_domain?: {
    [k: string]: any;
  };
  initial_utm_campaign?: {
    [k: string]: any;
  };
  initial_utm_content?: {
    [k: string]: any;
  };
  initial_utm_medium?: {
    [k: string]: any;
  };
  initial_utm_source?: {
    [k: string]: any;
  };
  initial_utm_term?: {
    [k: string]: any;
  };
}

export interface ExperimentCategoryUpdatedProperties {
  newValue: string;
  oldValue: string;
}

export interface ExperimentDecisionUpdatedProperties {
  newValue: string;
  oldValue: string;
}

export interface ExperimentStatusUpdatedProperties {
  newValue: string;
  oldValue: string;
}

export interface HypothesisCategoryUpdatedProperties {
  newValue: string;
  oldValue: string;
}

export class Identify implements BaseEvent {
  event_type = 'Identify';

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Group implements BaseEvent {
  event_type = 'Group';
}

export class ExperimentCategoryUpdated implements BaseEvent {
  event_type = 'Experiment Category Updated';

  constructor(
    public event_properties: ExperimentCategoryUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentDecisionUpdated implements BaseEvent {
  event_type = 'Experiment Decision Updated';

  constructor(
    public event_properties: ExperimentDecisionUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentStatusUpdated implements BaseEvent {
  event_type = 'Experiment Status Updated';

  constructor(
    public event_properties: ExperimentStatusUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HypothesisCategoryUpdated implements BaseEvent {
  event_type = 'Hypothesis Category Updated';

  constructor(
    public event_properties: HypothesisCategoryUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewCanvasCreated implements BaseEvent {
  event_type = 'New Canvas Created';
}

export class NewCategoryCreated implements BaseEvent {
  event_type = 'New Category Created';
}

export class NewCollectionCreated implements BaseEvent {
  event_type = 'New Collection Created';
}

export class NewExperimentCreated implements BaseEvent {
  event_type = 'New Experiment Created';
}

export class NewHypothesisCreated implements BaseEvent {
  event_type = 'New Hypothesis Created';
}

export class NewLearningCreated implements BaseEvent {
  event_type = 'New Learning Created';
}

export class NewObjectiveCreated implements BaseEvent {
  event_type = 'New Objective Created';
}

export class NewProjectCreated implements BaseEvent {
  event_type = 'New Project Created';
}

export class SubscriptionPlanSelected implements BaseEvent {
  event_type = 'Subscription Plan Selected';
}

export class ViewedCategoryDetailsScreen implements BaseEvent {
  event_type = 'Viewed Category Details Screen';
}

export class ViewedCollectionDetailsScreen implements BaseEvent {
  event_type = 'Viewed Collection Details Screen';
}

export class ViewedCollectionOverviewScreen implements BaseEvent {
  event_type = 'Viewed Collection Overview Screen';
}

export class ViewedDashboardScreen implements BaseEvent {
  event_type = 'Viewed Dashboard Screen';
}

export class ViewedEditCanvasScreen implements BaseEvent {
  event_type = 'Viewed Edit Canvas Screen';
}

export class ViewedExperimentDetailsScreen implements BaseEvent {
  event_type = 'Viewed Experiment Details Screen';
}

export class ViewedExperimentsScreen implements BaseEvent {
  event_type = 'Viewed Experiments Screen';
}

export class ViewedHypothesesScreen implements BaseEvent {
  event_type = 'Viewed Hypotheses Screen';
}

export class ViewedKnowledgeBaseScreen implements BaseEvent {
  event_type = 'Viewed Knowledge Base Screen';
}

export class ViewedLearningDetailsScreen implements BaseEvent {
  event_type = 'Viewed Learning Details Screen';
}

export class ViewedLearningsScreen implements BaseEvent {
  event_type = 'Viewed Learnings Screen';
}

export class ViewedManageCanvasesScreen implements BaseEvent {
  event_type = 'Viewed Manage Canvases Screen';
}

export class ViewedManageCategoriesScreen implements BaseEvent {
  event_type = 'Viewed Manage Categories Screen';
}

export class ViewedManageProjectsScreen implements BaseEvent {
  event_type = 'Viewed Manage Projects Screen';
}

export class ViewedObjectiveDetailsScreen implements BaseEvent {
  event_type = 'Viewed Objective Details Screen';
}

export class ViewedObjectivesScreen implements BaseEvent {
  event_type = 'Viewed Objectives Screen';
}

export class ViewedPricingScreen implements BaseEvent {
  event_type = 'Viewed Pricing Screen';
}

export class ViewedProfileScreen implements BaseEvent {
  event_type = 'Viewed Profile Screen';
}

export class ViewedProjectDetailsScreen implements BaseEvent {
  event_type = 'Viewed Project Details Screen';
}

export class ViewedSignInCompleteScreen implements BaseEvent {
  event_type = 'Viewed Sign In Complete Screen';
}

export class ViewedSignInEnterEmailScreen implements BaseEvent {
  event_type = 'Viewed Sign In Enter Email Screen';
}

export class ViewedSignInEnterOtpScreen implements BaseEvent {
  event_type = 'Viewed Sign In Enter OTP Screen';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options?: LoadOptions): PromiseResult<void> {
    this.disabled = options?.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Set Group for the current user
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  setGroup(groupType: string, groupName: string | string[], options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Experiment Category Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Experiment%20Category%20Updated)
   *
   * Owner: Radu Oprea
   *
   * @param properties The event's properties (e.g. newValue)
   * @param options Amplitude event options.
   */
  experimentCategoryUpdated(
    properties: ExperimentCategoryUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentCategoryUpdated(properties), options);
  }

  /**
   * Experiment Decision Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Experiment%20Decision%20Updated)
   *
   * Owner: Radu Oprea
   *
   * @param properties The event's properties (e.g. newValue)
   * @param options Amplitude event options.
   */
  experimentDecisionUpdated(
    properties: ExperimentDecisionUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentDecisionUpdated(properties), options);
  }

  /**
   * Experiment Status Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Experiment%20Status%20Updated)
   *
   * Owner: Radu Oprea
   *
   * @param properties The event's properties (e.g. newValue)
   * @param options Amplitude event options.
   */
  experimentStatusUpdated(
    properties: ExperimentStatusUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentStatusUpdated(properties), options);
  }

  /**
   * Hypothesis Category Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Hypothesis%20Category%20Updated)
   *
   * Owner: Radu Oprea
   *
   * @param properties The event's properties (e.g. newValue)
   * @param options Amplitude event options.
   */
  hypothesisCategoryUpdated(
    properties: HypothesisCategoryUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HypothesisCategoryUpdated(properties), options);
  }

  /**
   * New Canvas Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Canvas%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newCanvasCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewCanvasCreated(), options);
  }

  /**
   * New Category Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Category%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newCategoryCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewCategoryCreated(), options);
  }

  /**
   * New Collection Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Collection%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newCollectionCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewCollectionCreated(), options);
  }

  /**
   * New Experiment Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Experiment%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newExperimentCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewExperimentCreated(), options);
  }

  /**
   * New Hypothesis Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Hypothesis%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newHypothesisCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewHypothesisCreated(), options);
  }

  /**
   * New Learning Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Learning%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newLearningCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewLearningCreated(), options);
  }

  /**
   * New Objective Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Objective%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newObjectiveCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewObjectiveCreated(), options);
  }

  /**
   * New Project Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/New%20Project%20Created)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  newProjectCreated(
    options?: EventOptions,
  ) {
    return this.track(new NewProjectCreated(), options);
  }

  /**
   * Subscription Plan Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Subscription%20Plan%20Selected)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  subscriptionPlanSelected(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionPlanSelected(), options);
  }

  /**
   * Viewed Category Details Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Category%20Details%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedCategoryDetailsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedCategoryDetailsScreen(), options);
  }

  /**
   * Viewed Collection Details Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Collection%20Details%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedCollectionDetailsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedCollectionDetailsScreen(), options);
  }

  /**
   * Viewed Collection Overview Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Collection%20Overview%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedCollectionOverviewScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedCollectionOverviewScreen(), options);
  }

  /**
   * Viewed Dashboard Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Dashboard%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedDashboardScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedDashboardScreen(), options);
  }

  /**
   * Viewed Edit Canvas Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Edit%20Canvas%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedEditCanvasScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedEditCanvasScreen(), options);
  }

  /**
   * Viewed Experiment Details Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Experiment%20Details%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedExperimentDetailsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedExperimentDetailsScreen(), options);
  }

  /**
   * Viewed Experiments Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Experiments%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedExperimentsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedExperimentsScreen(), options);
  }

  /**
   * Viewed Hypotheses Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Hypotheses%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedHypothesesScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedHypothesesScreen(), options);
  }

  /**
   * Viewed Knowledge Base Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Knowledge%20Base%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedKnowledgeBaseScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedKnowledgeBaseScreen(), options);
  }

  /**
   * Viewed Learning Details Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Learning%20Details%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedLearningDetailsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedLearningDetailsScreen(), options);
  }

  /**
   * Viewed Learnings Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Learnings%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedLearningsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedLearningsScreen(), options);
  }

  /**
   * Viewed Manage Canvases Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Manage%20Canvases%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedManageCanvasesScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedManageCanvasesScreen(), options);
  }

  /**
   * Viewed Manage Categories Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Manage%20Categories%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedManageCategoriesScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedManageCategoriesScreen(), options);
  }

  /**
   * Viewed Manage Projects Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Manage%20Projects%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedManageProjectsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedManageProjectsScreen(), options);
  }

  /**
   * Viewed Objective Details Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Objective%20Details%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedObjectiveDetailsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedObjectiveDetailsScreen(), options);
  }

  /**
   * Viewed Objectives Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Objectives%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedObjectivesScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedObjectivesScreen(), options);
  }

  /**
   * Viewed Pricing Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Pricing%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedPricingScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedPricingScreen(), options);
  }

  /**
   * Viewed Profile Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Profile%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedProfileScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedProfileScreen(), options);
  }

  /**
   * Viewed Project Details Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Project%20Details%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedProjectDetailsScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedProjectDetailsScreen(), options);
  }

  /**
   * Viewed Sign In Complete Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Sign%20In%20Complete%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedSignInCompleteScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedSignInCompleteScreen(), options);
  }

  /**
   * Viewed Sign In Enter Email Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Sign%20In%20Enter%20Email%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedSignInEnterEmailScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedSignInEnterEmailScreen(), options);
  }

  /**
   * Viewed Sign In Enter OTP Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/tiny-bets/Tiny%20Bets/events/main/latest/Viewed%20Sign%20In%20Enter%20OTP%20Screen)
   *
   * Owner: Radu Oprea
   *
   * @param options Amplitude event options.
   */
  viewedSignInEnterOtpScreen(
    options?: EventOptions,
  ) {
    return this.track(new ViewedSignInEnterOtpScreen(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
